import { API } from '../utils';
import { ITax, IResponse, ITaxName } from '../interfaces';

/**
 * fetch taxes
 */
export const fetchTaxes = async (
  offset: number,
  limit: number,
  filter: string,
  sort_column: string,
  sort_direction: string
): Promise<ITax[]> => {
  return API.get<ITax[]>(
    `conf/get-taxes?offset=${offset}&limit=${limit}&filter=${filter}&sort_column=${sort_column}&sort_direction=${sort_direction}`
  );
};

/**
 * fetch taxpayer taxes
 */
export const fetchTaxpayerTaxes = async (uuid: string): Promise<ITax[]> => {
  return API.get<ITax[]>(`conf/get-taxpayer-taxes/${uuid}`);
};

/**
 * fetch one
 */
export const downloadExcel = async (uuid: string): Promise<ITax> => {
  return API.get<ITax>(`conf/get-tax/${uuid}`);
};

/**
 * fetch one
 */
export const fetchTaxByUuid = async (uuid: string): Promise<ITax> => {
  return API.get<ITax>(`conf/get-tax/${uuid}`);
};

/**
 * update one
 */
export const updateTaxName = async (
  uuid: string,
  taxData: ITaxName
): Promise<IResponse> => {
  return API.put(`conf/update-tax-name/${uuid}`, taxData);
};

/**
 * delete one
 */
export const deleteTax = async (uuid: string): Promise<IResponse> => {
  return API.delete(`conf/delete-tax/${uuid}`);
};

/**
 * fetch one
 */
export const fetchUploadedExpiration = async (
  year: string
): Promise<IResponse> => {
  return API.get<IResponse>(`conf/get-uploaded-expiration/${year}`);
};
