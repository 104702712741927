import {
  AppBar,
  IconButton,
  Toolbar,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  lighten,
  darken,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
// global state
import { useAppDispatch } from '../../../app/store';
import { toggleOpenDrawer } from '../../../features/UI/uiSlice';
// const
import { DRAWER_WIDTH } from '../../../constants';
// componentes
import LoginButton from './content/LoginButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    color: {
      backgroundColor: theme.palette.info.main,
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        backgroundColor: lighten(theme.palette.primary.main, 0.0),
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: DRAWER_WIDTH,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  })
);

export default function MenuAppBar() {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const handleDrawerToggle = () => {
    dispatch(toggleOpenDrawer());
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <div className={classes.color}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <LoginButton />
          </Grid>
        </Toolbar>
      </div>
    </AppBar>
  );
}
