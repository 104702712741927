import React from 'react';
// global state
import { useAppDispatch } from '../../../../../../../../../app/store';
import { setCollapsed } from '../../../../../../../../../features/UI/uiSlice';
// interface
import { IColumn, INotification } from '../../../../../../../../../interfaces';
// utils
import { formatNulls } from '../../../../../../../../../utils';
// components
import {
  StyledTableCell,
  TableContent,
} from '../../../../../../common/Table/Body/content/TableContent';

interface NotificationTableContentProps {
  row: INotification;
  labelId: string;
  columns: IColumn<INotification>[];
  isCollapse: boolean;
}

export default function NotificationTableContent({
  row,
  labelId,
  columns,
  isCollapse,
}: NotificationTableContentProps) {
  const dispatch = useAppDispatch();

  const toggleCollapse = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    dispatch(setCollapsed(row.uuid));
  };

  const toNotification = (column: IColumn<INotification>) => {
    return (
      <StyledTableCell
        key={column.id}
        align={column.align}
        style={{ minWidth: column.minWidth }}
      >
        {typeof column?.render === 'function'
          ? column.render(row)
          : formatNulls(row[column.id])}
      </StyledTableCell>
    );
  };

  return (
    <TableContent
      row={row}
      labelId={labelId}
      isCollapse={isCollapse}
      toggleCollapse={toggleCollapse}
      isRadio={false}
      isPointer={false}
    >
      {columns.map(toNotification)}
    </TableContent>
  );
}
