import { useCallback, ChangeEvent } from 'react';
import debounce from 'lodash.debounce';
// global state
import { useAppDispatch } from '../../../../../../../../app/store';
import {
  useTaxesSelector,
  fetchTaxes,
  setFilter,
} from '../../../../../../../../features/taxes/taxesSlice';
// context
import { useSnackbar } from '../../../../../../../../context/SnackbarContext';
// components
import { SearchField } from '../../../../../common/Table/Header';

export default function SearchFieldTax() {
  const dispatch = useAppDispatch();

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const { offset, limit, filter, sort_column, sort_direction } =
    useTaxesSelector((state) => state.taxes);

  const handleFetchTaxes = async (filter: string) => {
    try {
      await dispatch(
        fetchTaxes({
          offset,
          limit,
          filter,
          sort_column,
          sort_direction,
        })
      );
    } catch (err: any) {
      errorSnackbar();
    }
  };

  const debounceTaxes = useCallback(debounce(handleFetchTaxes, 300), []);

  const handleSetFilter = async (filter: string) => {
    dispatch(setFilter(filter));
    debounceTaxes(filter);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filter = e.currentTarget.value;
    handleSetFilter(filter);
  };

  const handleClickClear = () => {
    if (filter) {
      handleSetFilter('');
    }
  };

  return (
    <SearchField
      handleChange={handleChange}
      handleClickClear={handleClickClear}
      filter={filter}
    />
  );
}
