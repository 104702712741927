import React from 'react';
import clsx from 'clsx';
import {
  createStyles,
  makeStyles,
  Theme,
  TableCell,
  TableRow,
  Radio,
  IconButton,
  lighten,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// global context
import { useAppDispatch } from '../../../../../../../../app/store';
import {
  useUISelector,
  setCollapsed,
} from '../../../../../../../../features/UI/uiSlice';
// hook
import { useIsMobile } from '../../../../../../../../hooks/useIsMobile';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: lighten(theme.palette.primary.light, 0.8),
      },
    },
    noCollapse: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: lighten(theme.palette.primary.light, 0.8),
      },
    },
    pointer: {
      cursor: 'pointer',
    },
    notSticky: {
      padding: '0px',
    },
    sticky: {
      position: 'sticky',
      right: '0',
      background: theme.palette.background.default,
      padding: '0px',
    },
    drive: {
      width: '5px',
    },
  })
);

interface TableContentProps {
  row: any;
  labelId: string;
  isCollapse: boolean;
  selectedItem?: any;
  handleClick?: (e: React.MouseEvent<unknown>, row: any) => void;
  toggleCollapse?: (e: React.MouseEvent<unknown>) => void;
  children: React.ReactNode;
  isRadio?: boolean;
  isPointer?: boolean;
}

export default function TableContent({
  row,
  labelId,
  isCollapse,
  selectedItem,
  handleClick = () => {},
  toggleCollapse,
  children,
  isRadio = true,
  isPointer = true,
}: TableContentProps) {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { collapsed } = useUISelector((state) => state.ui);

  const { isMobile } = useIsMobile();

  const isSelected = (row: any) => (selectedItem === row)!!;
  const isItemSelected = isSelected(row);

  const isCollapsed = (row: any) => collapsed === row.uuid;
  const isItemCollapsed = isCollapsed(row);

  const handleCollapse = (e: React.MouseEvent<unknown>, row: any) => {
    e.stopPropagation();
    if (isItemCollapsed) {
      dispatch(setCollapsed(null));
    } else {
      if (toggleCollapse) toggleCollapse(e);
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
      className={clsx([
        isCollapse ? classes.root : classes.noCollapse,
        isPointer ? classes.pointer : null,
      ])}
      onClick={(event) => handleClick(event, row)}
      id={row.id}
    >
      {isRadio ? (
        <TableCell padding="checkbox">
          <Radio
            size="small"
            onClick={
              handleClick ? (event) => handleClick(event, row) : () => {}
            }
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
      ) : (
        <TableCell padding="none" />
      )}

      {children}

      {isCollapse ? (
        <TableCell className={isMobile ? classes.sticky : classes.notSticky}>
          <IconButton
            aria-label="expand-row"
            size="medium"
            onClick={(event) => handleCollapse(event, row)}
          >
            {isItemCollapsed ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
      ) : null}
    </TableRow>
  );
}
