import clsx from 'clsx';
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
  TableContainer,
  Toolbar,
  Typography,
} from '@material-ui/core';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      minHeight: theme.spacing(10),
    },
    highlight: {
      color: theme.palette.primary.main,
      backgroundColor: lighten(theme.palette.primary.light, 0.8),
    },
    title: {
      flex: '1 1 100%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  })
);

interface TableHeaderProps {
  title?: string;
  isItemSelected: boolean;
  children: JSX.Element[];
}

export default function TableHeader({
  title,
  isItemSelected,
  children,
}: TableHeaderProps) {
  const classes = useToolbarStyles();

  return (
    <TableContainer>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: isItemSelected,
        })}
      >
        {isItemSelected ? (
          <>
            <Typography
              className={classes.title}
              color="inherit"
              variant="h6"
              component="div"
            >
              {title}
            </Typography>
          </>
        ) : (
          <>{children[0]}</>
        )}

        {isItemSelected ? <>{children[1]}</> : null}
      </Toolbar>
    </TableContainer>
  );
}
