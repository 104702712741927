import React, { useEffect, useCallback } from 'react';
import { Checkbox, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FormikErrors } from 'formik';
import debounce from 'lodash.debounce';
// global state
import { useUsersSelector } from '../../../../../../features/users/usersSlice';
import { useTaxpayersSelector } from '../../../../../../features/taxpayers/taxpayersSlice';
// api
import {
  fetchTaxpayers,
  fetchClientTaxpayers,
} from '../../../../../../api/taxpayer.services';
// context
import { useSnackbar } from '../../../../../../context/SnackbarContext';
// interface
import { IUserForm, ITaxpayer } from '../../../../../../interfaces';
// hooks
import { useIsMobile } from '../../../../../../hooks/useIsMobile';

interface TaxpayersAutocompleteProps {
  taxpayersUuidErrors: string | string[] | undefined;
  formikSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IUserForm>>;
  handleResetError: () => void;
  setTaxpayers: React.Dispatch<React.SetStateAction<ITaxpayer[]>>;
  taxpayers: ITaxpayer[];
  setSelectedTaxpayers: React.Dispatch<React.SetStateAction<ITaxpayer[]>>;
  selectedTaxpayers: ITaxpayer[];
  openDialog: boolean;
}

export default function TaxpayersAutocomplete({
  taxpayersUuidErrors,
  handleResetError,
  formikSetFieldValue,
  setTaxpayers,
  taxpayers,
  setSelectedTaxpayers,
  selectedTaxpayers,
  openDialog,
}: TaxpayersAutocompleteProps) {
  const { isMobile } = useIsMobile();

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const { user } = useUsersSelector((state) => state.users);

  const { offset, limit, month, showDisabled, sort_column, sort_direction } =
    useTaxpayersSelector((state) => state.taxpayers);

  const handleFetchClientTaxpayers = async () => {
    if (user) {
      try {
        const taxpayers = await fetchClientTaxpayers(user.uuid);
        setTaxpayers(taxpayers);
        setSelectedTaxpayers(taxpayers);
      } catch (err: any) {
        errorSnackbar();
      }
    }
  };

  useEffect(() => {
    if (openDialog && user) {
      handleFetchClientTaxpayers();
    }
  }, [openDialog]);

  const handleFetchTaxpayers = async (filter: string) => {
    if (filter) {
      try {
        const taxpayers = await fetchTaxpayers(
          offset,
          limit,
          month,
          filter,
          showDisabled,
          sort_column,
          sort_direction
        );
        setTaxpayers(taxpayers);
      } catch (err: any) {
        errorSnackbar();
      }
    }
  };

  const debounceTaxpayer = useCallback(debounce(handleFetchTaxpayers, 300), []);

  const handleTaxpayerAutocompleteInputChange = (
    e: React.ChangeEvent<{}>,
    filter: string | null,
    reason: string
  ) => {
    if (reason === 'clear') {
      filter = '';
    }
    if (typeof filter === 'string') {
      handleResetError();
      debounceTaxpayer(filter);
    }
  };

  const handleTaxpayerAutocompleteChange = (
    e: React.ChangeEvent<{}>,
    data: ITaxpayer[],
    reason: string,
    details?: any | undefined
  ) => {
    if (reason === 'select-option') {
      // remove duplicates
      const sentUuid = details.option.uuid;
      if (!!selectedTaxpayers.find((taxpayer) => taxpayer.uuid === sentUuid)) {
        data = data.filter((row) => row.uuid !== sentUuid);
      }
    }
    if (data) {
      // update hook state
      setSelectedTaxpayers(data);
      // update formik state
      const taxpayersUuid = data.map((row) => row.uuid);
      formikSetFieldValue('taxpayers_uuid', taxpayersUuid);
    }
    handleResetError();
  };

  return (
    <Autocomplete
      multiple
      size="small"
      limitTags={8}
      options={taxpayers}
      getOptionLabel={(option) => option.company_name}
      value={selectedTaxpayers}
      onChange={handleTaxpayerAutocompleteChange}
      onInputChange={handleTaxpayerAutocompleteInputChange}
      noOptionsText="Sin resultados"
      renderOption={(option) => (
        <React.Fragment>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={
              !!selectedTaxpayers.find((row) => option.uuid === row.uuid)
            }
          />
          {option.company_name}
        </React.Fragment>
      )}
      style={{ width: isMobile ? 230 : 520 }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Contribuyentes"
          helperText={
            taxpayersUuidErrors
              ? taxpayersUuidErrors
              : 'Escriba para buscar un contribuyente'
          }
          error={!!taxpayersUuidErrors}
        />
      )}
    />
  );
}
