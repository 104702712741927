import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100vh',
    },
    progress: {
      color: theme.palette.primary.light,
    },
  })
);

const Loading = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <CircularProgress disableShrink className={classes.progress} />
      </Grid>
    </Grid>
  );
};

export default Loading;
