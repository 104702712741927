import {
  ListItemIcon,
  makeStyles,
  createStyles,
  Theme,
  lighten,
} from '@material-ui/core';
import FolderRoundedIcon from '@material-ui/icons/FolderRounded';
import InsertDriveFileRoundedIcon from '@material-ui/icons/InsertDriveFileRounded';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import ViewListRoundedIcon from '@material-ui/icons/ViewListRounded';
// interfaces
import { IFile } from '../../../../../../../interfaces';
// constants
import {
  FOLDER,
  PDF,
  DOCUMENT,
  SPREADSHEET,
  PRESENTATION,
  IMAGE,
  TXT,
  RAR,
} from '../../../../../../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemIcon: {
      minWidth: '35px',
      paddingBottom: '3px',
    },
    icon: {
      width: '21px',
      paddingLeft: '3px',
      paddingBlock: '3px',
    },
    folder: {
      color: theme.palette.secondary.light,
    },
    file: {
      color: theme.palette.primary.light,
    },
    txt: {
      color: '#87888a',
    },
    image: {
      color: '#d62f56',
    },
    rar: {
      color: '#a23cb5',
    },
    pdf: {
      color: '#f71f14',
    },
    doc: {
      color: '#2580f4',
    },
    xls: {
      color: '#21a062',
    },
    ppt: {
      color: '#f7b400',
    },
  })
);

interface FileIconProps {
  row: IFile;
}

export default function FileIcon({ row }: FileIconProps) {
  const classes = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
      id={row.id}
    >
      <ListItemIcon className={classes.listItemIcon} id={row.id}>
        {FOLDER.some((el) => row.mimeType.includes(el)) ? (
          <FolderRoundedIcon className={classes.folder} id={row.id} />
        ) : TXT.some((el) => row.mimeType.includes(el)) ? (
          <DescriptionRoundedIcon className={classes.txt} id={row.id} />
        ) : RAR.some((el) => row.mimeType.includes(el)) ? (
          <ViewListRoundedIcon className={classes.rar} id={row.id} />
        ) : IMAGE.some((el) => row.mimeType.includes(el)) ? (
          <ImageRoundedIcon className={classes.image} id={row.id} />
        ) : PDF.some((el) => row.mimeType.includes(el)) ? (
          <PictureAsPdfRoundedIcon className={classes.pdf} id={row.id} />
        ) : DOCUMENT.some((el) => row.mimeType.includes(el)) ? (
          <DescriptionRoundedIcon className={classes.doc} id={row.id} />
        ) : SPREADSHEET.some((el) => row.mimeType.includes(el)) ? (
          <DescriptionRoundedIcon className={classes.xls} id={row.id} />
        ) : PRESENTATION.some((el) => row.mimeType.includes(el)) ? (
          <DescriptionRoundedIcon className={classes.ppt} id={row.id} />
        ) : (
          <InsertDriveFileRoundedIcon className={classes.file} />
        )}
      </ListItemIcon>
      <span id={row.id}>{row.name}</span>
    </div>
  );
}
