import { TableBody } from '@material-ui/core';
// global state
import { useUsersSelector } from '../../../../../../../../features/users/usersSlice';
// interface
import { IColumn, IUser } from '../../../../../../../../interfaces';
// children components
import ClientTableContent from './TableContent/ClientTableContent';

interface ClientTableBodyContainerProps {
  columns: IColumn<IUser>[];
  isCollapse: boolean;
}

export default function ClientTableBodyContainer({
  columns,
  isCollapse,
}: ClientTableBodyContainerProps) {
  const { users } = useUsersSelector((state) => state.users);

  return (
    <TableBody>
      {users.map((row) => {
        return (
          <ClientTableContent
            row={row}
            labelId={row.uuid}
            key={row.uuid}
            columns={columns}
            isCollapse={isCollapse}
          />
        );
      })}
    </TableBody>
  );
}
