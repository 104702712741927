import React from 'react';
import { Button, Typography } from '@material-ui/core';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
// children components
import TaxesUpdateDialog from '../../../../../Dialogs/Taxes/TaxesUpdateDialog';

export default function UpdateTaxesButton() {
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <>
      <Button variant="contained" color="secondary" onClick={handleClick}>
        <PublishRoundedIcon />
        <Typography variant="subtitle2">
          {'Actualizar impuestos y vencimientos'}
        </Typography>
      </Button>
      <TaxesUpdateDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </>
  );
}
