import {
  Typography,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  TableContainer,
} from '@material-ui/core';
// components
import SearchFieldDirectory from './content/SearchFieldDirectory';
import DirectoryBreadcrumbs from './content/DirectoryBreadcrumbs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      minHeight: theme.spacing(10),
    },
    container: {
      paddingInline: theme.spacing(1),
      paddingBlock: theme.spacing(0.25),
    },
    head: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  })
);

export default function DirectoryTableHeader() {
  const classes = useStyles();

  return (
    <TableContainer className={classes.root}>
      <Grid
        container
        className={classes.container}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item xs={12} sm={2} className={classes.container}>
          <Typography variant="h5">{'Diseño de archivo'}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.container}>
          <DirectoryBreadcrumbs />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.container}>
          <SearchFieldDirectory />
        </Grid>
      </Grid>
    </TableContainer>
  );
}
