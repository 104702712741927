import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { INotification, INotifForm, IModelNotif } from '../../interfaces';
import { DEF_LIMIT, DEF_OFFSET } from '../../constants';
import { Order } from '../../types';
import * as notificationSvcs from '../../api/notification.services';

// Interfaces
interface INotificationsState {
  loading: boolean;
  error: boolean | string;
  notifications: INotification[];
  notification: INotification | null;
  modelNotifications: IModelNotif[];
  actionLoading: boolean;
  actionError: boolean | string;
  offset: number;
  limit: number;
  filter: string;
  sort_column: keyof INotification;
  sort_direction: Order;
}

// Initial state
const initialState: INotificationsState = {
  loading: false,
  error: false,
  notifications: [],
  modelNotifications: [],
  notification: null,
  actionLoading: false,
  actionError: false,
  offset: DEF_OFFSET,
  limit: DEF_LIMIT,
  filter: '',
  sort_column: 'created_at',
  sort_direction: 'desc',
};

// Asynchronous thunk actions
export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  ({
    offset = initialState.offset,
    limit = initialState.limit,
    filter = initialState.filter,
    sort_column = initialState.sort_column,
    sort_direction = initialState.sort_direction,
  }: {
    offset?: number;
    limit?: number;
    filter?: string;
    sort_column?: string;
    sort_direction?: string;
  }) => {
    return notificationSvcs.fetchNotifications(
      offset,
      limit,
      filter,
      sort_column,
      sort_direction
    );
  }
);

export const fetchModelNotifications = createAsyncThunk(
  'notifications/fetchModelNotifications',
  () => {
    return notificationSvcs.fetchModelNotifications();
  }
);

export const createNotifications = createAsyncThunk(
  'notifications/createNotifications',
  (notifData: INotifForm) => {
    return notificationSvcs.createNotifications(notifData);
  }
);

// Slice
const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setOffset(state: INotificationsState, { payload }: PayloadAction<number>) {
      state.offset = payload;
    },
    setLimit(state: INotificationsState, { payload }: PayloadAction<number>) {
      state.limit = payload;
    },
    setFilter(state: INotificationsState, { payload }: PayloadAction<string>) {
      state.filter = payload;
    },
    setSortColumn(
      state: INotificationsState,
      { payload }: PayloadAction<keyof INotification>
    ) {
      state.sort_column = payload;
    },
    setSortDirection(
      state: INotificationsState,
      { payload }: PayloadAction<Order>
    ) {
      state.sort_direction = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.notifications = action.payload;
    });
    builder.addCase(fetchNotifications.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(fetchModelNotifications.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchModelNotifications.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.modelNotifications = action.payload;
    });
    builder.addCase(fetchModelNotifications.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(createNotifications.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(createNotifications.fulfilled, (state, action) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(createNotifications.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
  },
});

// Actions generated from the slice
export const {
  setLimit,
  setOffset,
  setFilter,
  setSortColumn,
  setSortDirection,
} = notifications.actions;

// Selector
export const useNotificationsSelector: TypedUseSelectorHook<RootState> =
  useSelector;

// The reducer
export default notifications.reducer;
