import { useState } from 'react';
// interface
import { IUser } from '../../../../../../../interfaces';
// children components
import ClientToggleStatus from '../../../../Dialogs/Clients/ClientToggleStatus';
import { ToggleStatusButton } from '../../../../common/Table/Body/content/TableContent';

interface ToggleClientButtonProps {
  row: IUser;
}

export default function ToggleClientButton({ row }: ToggleClientButtonProps) {
  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = (e: React.MouseEvent<unknown>) => {
    setOpenDialog(!openDialog);
    e.stopPropagation();
  };

  return (
    <ToggleStatusButton enabled={row.enabled} handleClick={handleClick}>
      <ClientToggleStatus
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        userUuid={row.uuid}
        enabled={row.enabled}
        reasonForDisable={row.reason_for_disable}
      />
    </ToggleStatusButton>
  );
}
