import { TableBody } from '@material-ui/core';
// global state
import { useTaxesSelector } from '../../../../../../../../features/taxes/taxesSlice';
// interface
import { IColumn, ITax } from '../../../../../../../../interfaces';
// children components
import TaxTableContent from './TableContent/TaxTableContent';

interface TaxTableBodyContainerProps {
  columns: IColumn<ITax>[];
  isCollapse: boolean;
}

export default function TaxTableBodyContainer({
  columns,
  isCollapse,
}: TaxTableBodyContainerProps) {
  const { taxes } = useTaxesSelector((state) => state.taxes);

  return (
    <TableBody>
      {taxes.map((row) => {
        return (
          <TaxTableContent
            row={row}
            labelId={row.uuid}
            key={row.uuid}
            columns={columns}
            isCollapse={isCollapse}
          />
        );
      })}
    </TableBody>
  );
}
