import { useEffect } from 'react';
// global state
import { useAppDispatch } from '../app/store';
import { checkAuth } from '../features/auth/authSlice';
import { useMyUserSelector } from '../features/auth/myUserSlice';
// children components
import MainLogin from '../components/Login/MainLogin';
// common
import { Loading } from '../common';

export default function Login() {
  const dispatch = useAppDispatch();

  const { loading } = useMyUserSelector((state) => state.myUser);

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  const renderLogin = () => {
    if (loading) return <Loading />;

    return <MainLogin />;
  };

  return <>{renderLogin()}</>;
}
