import React, { useState } from 'react';
import { TableSortLabel, Box } from '@material-ui/core';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
// global state
import { useAppDispatch } from '../../../../../../../../app/store';
import {
  selectTaxpayer,
  useTaxpayersSelector,
} from '../../../../../../../../features/taxpayers/taxpayersSlice';
// interface
import { IColumn, ITaxpayer } from '../../../../../../../../interfaces';
// children components
import {
  StyledTableCellHeader,
  CustomSpan,
  TableBodyHeader,
} from '../../../../../common/Table/Body/content';
import MonthsMenu from './MonthsMenu';

const SORTABLE_COLUMNS = [
  'company_name',
  'cuit',
  'manager',
  'enabled_at',
  'employer',
];

const FILTER_BY_FIELD_COLUMNS = ['financial_year_close'];

interface TaxpayerTableBodyHeaderProps {
  onRequestSort: (
    e: React.MouseEvent<unknown>,
    property: keyof ITaxpayer
  ) => void;
  columns: IColumn<ITaxpayer>[];
  isCollapse: boolean;
}

export default function TaxpayerTableBodyHeader({
  onRequestSort,
  columns,
  isCollapse,
}: TaxpayerTableBodyHeaderProps) {
  const dispatch = useAppDispatch();

  const { taxpayer, sort_column, sort_direction, month } = useTaxpayersSelector(
    (state) => state.taxpayers
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickMonths = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const createSortHandler =
    (property: keyof ITaxpayer) => (e: React.MouseEvent<unknown>) => {
      onRequestSort(e, property);
    };

  const handleDeselect = () => {
    dispatch(selectTaxpayer(null));
  };

  return (
    <>
      <TableBodyHeader
        isCollapse={isCollapse}
        isItemSelected={taxpayer ? true : false}
        handleDeselect={handleDeselect}
      >
        {columns.map((column) => (
          <StyledTableCellHeader
            key={column.id}
            align={column.align}
            sortDirection={sort_column === column.id ? sort_direction : false}
          >
            {SORTABLE_COLUMNS.includes(column.id) ? (
              <TableSortLabel
                active={sort_column === column.id}
                direction={sort_column === column.id ? sort_direction : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                <Box fontWeight="fontWeightBold">
                  {column.label}
                  {sort_column === column.id ? (
                    <CustomSpan>
                      {sort_direction === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </CustomSpan>
                  ) : null}
                </Box>
              </TableSortLabel>
            ) : FILTER_BY_FIELD_COLUMNS.includes(column.id) ? (
              <TableSortLabel
                active={!!month}
                direction={'desc'}
                onClick={handleClickMonths}
                IconComponent={FilterListRoundedIcon}
              >
                <Box fontWeight="fontWeightBold">
                  {column.label}
                  {sort_column === column.id ? (
                    <CustomSpan>
                      {sort_direction === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </CustomSpan>
                  ) : null}
                </Box>
              </TableSortLabel>
            ) : (
              <Box fontWeight="fontWeightBold">{column.label}</Box>
            )}
          </StyledTableCellHeader>
        ))}
      </TableBodyHeader>
      <MonthsMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
}
