import React from 'react';
import { Link } from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../../../app/store';
import {
  useDriveSelector,
  fetchDriveItems,
  goForward,
  setFilter,
  setNextPageToken,
} from '../../../../../../../features/drive/driveSlice';
// interface
import { IFile } from '../../../../../../../interfaces';
// constants
import { FOLDER } from '../../../../../../../constants';
// context
import { useSnackbar } from '../../../../../../../context/SnackbarContext';
// components
import FileIcon from './FileIcon';

interface FileNameAndIconProps {
  row: IFile;
}

export default function FileNameAndIcon({ row }: FileNameAndIconProps) {
  const dispatch = useAppDispatch();

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const { limit, sort_column, sort_direction } = useDriveSelector(
    (state) => state.drive
  );

  const handleClickOnFile = () => {
    if (!FOLDER.some((el) => row.mimeType.includes(el))) return;
    // if click was in a folder...
    try {
      const folder_id = row.id;
      const filter = '';
      const nextPageToken = '';
      dispatch(
        fetchDriveItems({
          limit,
          filter,
          folder_id,
          sort_column,
          sort_direction,
          nextPageToken,
        })
      );
      dispatch(
        goForward({
          folderId: row.id,
          folderName: row.name,
        })
      );
      dispatch(setNextPageToken([nextPageToken]));
      dispatch(setFilter(filter));
    } catch (err) {
      errorSnackbar();
    }
  };

  return (
    <Link
      href={
        FOLDER.some((el) => row.mimeType.includes(el)) ? '#' : row.webViewLink
      }
      target={FOLDER.some((el) => row.mimeType.includes(el)) ? '' : '_blank'}
      variant="body2"
      color="inherit"
      onClick={handleClickOnFile}
      id={row.id}
    >
      <FileIcon row={row} />
    </Link>
  );
}
