import { useEffect } from 'react';
import { Paper } from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  fetchNotifications,
  useNotificationsSelector,
} from '../../../../../features/notifications/notificationsSlice';
import { setCollapsed } from '../../../../../features/UI/uiSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// common
import { Error } from '../../../../../common';
// children components
import NotificationTableHeader from './content/Header/NotificationTableHeader';
import NotificationTableBody from './content/Body/NotificationTableBody';
import NotificationTableFooter from './content/Footer/NotificationTableFooter';

export default function NotificationsView() {
  const dispatch = useAppDispatch();

  const { error, offset, limit, filter, sort_column, sort_direction } =
    useNotificationsSelector((state) => state.notifications);

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  useEffect(() => {
    dispatch(setCollapsed(null));
  }, [offset, limit]);

  useEffect(() => {
    try {
      dispatch(
        fetchNotifications({
          offset,
          limit,
          filter,
          sort_column,
          sort_direction,
        })
      );
    } catch (err) {
      errorSnackbar();
    }
    // filter won't goes here cause it's handled by a debounce function in SearchField component
  }, [offset, limit, sort_column, sort_direction]);

  if (error) return <Error />;

  return (
    <Paper>
      <NotificationTableHeader />
      <NotificationTableBody />
      <NotificationTableFooter />
    </Paper>
  );
}
