import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
// interfaces
import { IResponse } from '../../../../../../interfaces';

interface UploadWarningDialogProps {
  expirationInfo: IResponse;
  openWarningDialog: boolean;
  setOpenWarningDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirmUploadTaxes: () => void;
}

export default function UploadWarningDialog({
  expirationInfo,
  openWarningDialog,
  setOpenWarningDialog,
  handleConfirmUploadTaxes,
}: UploadWarningDialogProps) {
  const handleClose = () => {
    setOpenWarningDialog(false);
  };

  const handleConfirm = () => {
    handleClose();
    handleConfirmUploadTaxes();
  };

  return (
    <Dialog
      open={openWarningDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Vencimientos ya cargados ⚠️'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`${expirationInfo.message}\n`}
          {
            'Si confirma, se sobreescribiran las fechas de vencimiento de los impuestos.'
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {'cancelar'}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          autoFocus
        >
          {'confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
