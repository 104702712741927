import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
// interfaces
import { IResponse } from '../../../../../../interfaces';

interface LinkWarningDialogProps {
  folderInfo: IResponse;
  openWarningDialog: boolean;
  setOpenWarningDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectFolder: () => void;
}

export default function LinkWarningDialog({
  folderInfo,
  openWarningDialog,
  setOpenWarningDialog,
  handleSelectFolder,
}: LinkWarningDialogProps) {
  const handleClose = () => {
    setOpenWarningDialog(false);
  };

  const handleConfirm = () => {
    handleClose();
    handleSelectFolder();
  };

  return (
    <Dialog
      open={openWarningDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Directorio ya vinculado ⚠️'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`${folderInfo.message}\n`}
          {
            'Si confirma, una vez guardados los cambios, el vinculo se sobrescribirá.'
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {'cancelar'}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {'entiendo'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
