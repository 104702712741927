import { useEffect } from 'react';
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
} from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../../../../../../app/store';
import {
  useUsersSelector,
  resetUsers,
  fetchTaxpayerClients,
} from '../../../../../../../../../../features/users/usersSlice';
import { useUISelector } from '../../../../../../../../../../features/UI/uiSlice';
// context
import { useSnackbar } from '../../../../../../../../../../context/SnackbarContext';
// interface
import {
  IColumn,
  ITaxpayer,
  IUser,
} from '../../../../../../../../../../interfaces';
// utils
import { formatDate, formatNulls } from '../../../../../../../../../../utils';
// components
import { LoadingCollapseTable } from '../../../../../../../common/CollapseTable';
import { ErrorCollapseTable } from '../../../../../../../common/CollapseTable';

const columns: IColumn<IUser>[] = [
  { id: 'name', label: 'Nombre', minWidth: 100, align: 'left' },
  { id: 'surname', label: 'Apellido', minWidth: 100, align: 'left' },
  { id: 'email', label: 'Email', minWidth: 100, align: 'right' },
  {
    id: 'birthday',
    label: 'Fecha de nacimiento',
    minWidth: 100,
    align: 'right',
    render: (value) => formatDate(value.birthday),
  },
  { id: 'phone', label: 'Tel.', minWidth: 100, align: 'right' },
  { id: 'address', label: 'Dirección', minWidth: 100, align: 'right' },
];

interface UsersContentProps {
  taxpayerUuid: string;
  isItemCollapsed: boolean;
}

export default function UsersContent({
  taxpayerUuid,
  isItemCollapsed,
}: UsersContentProps) {
  const dispatch = useAppDispatch();

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const { collapsed } = useUISelector((state) => state.ui);

  const { users, loading, error } = useUsersSelector((state) => state.users);

  const loadUsersClient = async () => {
    try {
      await dispatch(fetchTaxpayerClients(taxpayerUuid));
    } catch (err: any) {
      errorSnackbar();
    }
  };

  useEffect(() => {
    if (isItemCollapsed) {
      dispatch(resetUsers());
      loadUsersClient();
    }
  }, [collapsed]);

  const toClients = (column: IColumn<IUser>, user: IUser) => {
    return (
      <TableCell
        key={column.id}
        align={column.align}
        style={{
          minWidth: column.minWidth,
        }}
      >
        {typeof column?.render === 'function'
          ? column.render(user)
          : formatNulls(user[column.id])}
      </TableCell>
    );
  };

  return (
    <Box pt={2}>
      {loading ? (
        <Table size="small">
          <LoadingCollapseTable isItemCollapsed={isItemCollapsed} />
        </Table>
      ) : (
        <>
          <Typography variant="subtitle1">
            <Box pb={2} fontWeight="fontWeightBold">
              {'Usuarios:'}
            </Box>
          </Typography>
          {error ? (
            <ErrorCollapseTable />
          ) : users.length === 0 && !loading ? (
            <Typography variant="subtitle1">
              <Box pt={1} pb={0}>
                {
                  '- El contribuyente no se encuentra asignado a ningun usuario hasta el momento.'
                }
              </Box>
            </Typography>
          ) : (
            <>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        <Box fontWeight="fontWeightBold">{column.label}</Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((taxpayer: ITaxpayer | any) => (
                    <TableRow key={taxpayer.uuid} style={{ height: '5vh' }}>
                      {columns.map((column) => toClients(column, taxpayer))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </>
      )}
    </Box>
  );
}
