import {
  Typography,
  Box,
  Grid,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
// global state
import { useNotificationsSelector } from '../../../../../../../features/notifications/notificationsSlice';
// components
import { TableHeader } from '../../../../common/Table/Header';
// components
import AddNotificationButton from './content/AddNotificationButton';
import SearchFieldNotification from './content/SearchFieldNotification';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingInline: theme.spacing(1),
    },
    head: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  })
);

export default function NotificationTableHeader() {
  const classes = useStyles();

  const { notification } = useNotificationsSelector(
    (state) => state.notifications
  );

  return (
    <TableHeader isItemSelected={!!notification}>
      <>
        <Grid
          container
          className={classes.container}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12} sm={5} className={classes.container}>
            <Typography variant="h5" style={{ display: 'inline-block' }}>
              {'Notificaciones'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.container}>
            <Box display="flex" justifyContent="flex-end">
              <AddNotificationButton />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.container}>
            <SearchFieldNotification />
          </Grid>
        </Grid>
      </>
      <></>
    </TableHeader>
  );
}
