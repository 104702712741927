import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  useUsersSelector,
  selectUser,
  resetUserPsswd,
} from '../../../../../features/users/usersSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// components
import { AlertDialog } from '../../common/Dialog';

interface ClientResetPsswdDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ClientResetPsswdDialog({
  openDialog,
  setOpenDialog,
}: ClientResetPsswdDialogProps) {
  const dispatch = useAppDispatch();

  const { user } = useUsersSelector((state) => state.users);

  const [confirMessage, setConfirMessage] = useState('');

  const [alertMessage, setAlertMessage] = useState('');

  const {
    dispatch: { setSnackbar, resetSnackbar, errorSnackbar },
  } = useSnackbar();

  useEffect(() => {
    setConfirMessage('¿Restablecer contraseña?');
    setAlertMessage('');
  }, [openDialog]);

  const handleResetPassword = async (userUuid: string) => {
    try {
      const resultAction = await dispatch(resetUserPsswd(userUuid));
      const res = unwrapResult(resultAction);
      setSnackbar(true, res.message);
    } catch (err: any) {
      errorSnackbar();
    }
  };

  const handleSubmit = () => {
    if (user) {
      resetSnackbar();
      handleResetPassword(user.uuid);
      setOpenDialog(false);
      dispatch(selectUser(null));
    }
  };

  return (
    <AlertDialog
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      handleSubmit={handleSubmit}
      confirMessage={confirMessage}
      alertMessage={alertMessage}
    />
  );
}
