// components
import { MainView } from '../common';
import NotificationsTable from '../components/Dashboard/MainViews/Tables/Notifications/Notifications';

export default function Notifications() {
  return (
    <MainView>
      <NotificationsTable />
    </MainView>
  );
}
