import React from 'react';
// components
import { EmailButton } from '../../../../../common/Table/Header/Buttons';
// children components
import ClientSendEmailDialog from '../../../../../Dialogs/Clients/ClientSendEmailDialog';

export default function SendMailClientButton() {
  const [openDialog, setOpenDialog] = React.useState(false);

  return (
    <EmailButton setOpenDialog={setOpenDialog}>
      <ClientSendEmailDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </EmailButton>
  );
}
