import { useEffect } from 'react';
import { Paper } from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  fetchTaxes,
  useTaxesSelector,
  selectTax,
} from '../../../../../features/taxes/taxesSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// common
import { Error } from '../../../../../common';
// children components
import TaxTableHeader from './content/Header/TaxTableHeader';
import TaxTableBody from './content/Body/TaxTableBody';
import TaxTableFooter from './content/Footer/TaxTableFooter';

export default function TaxesView() {
  const dispatch = useAppDispatch();

  const { error, offset, limit, filter, sort_column, sort_direction } =
    useTaxesSelector((state) => state.taxes);

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  useEffect(() => {
    dispatch(selectTax(null));
  }, [offset, limit, sort_column, sort_direction]);

  useEffect(() => {
    try {
      dispatch(fetchTaxes({ offset, limit, filter }));
      dispatch(selectTax(null));
    } catch (err) {
      errorSnackbar();
    }
    // filter won't goes here cause it's handled by a debounce function in SearchField component
  }, [offset, limit, sort_column, sort_direction]);

  if (error) return <Error />;

  return (
    <Paper>
      <TaxTableHeader />
      <TaxTableBody />
      <TaxTableFooter />
    </Paper>
  );
}
