// global state
import { useAppDispatch } from '../../../../../../../app/store';
import {
  useUsersSelector,
  setOffset,
  setLimit,
  selectUser,
} from '../../../../../../../features/users/usersSlice';
// components
import { TableFooter } from '../../../../common/Table/Footer';

export default function ClientTableFooter() {
  const dispatch = useAppDispatch();

  const { users, offset, limit } = useUsersSelector((state) => state.users);

  const handleSetOffset = async (offset: number) => {
    dispatch(setOffset(offset));
    dispatch(selectUser(null));
  };

  const handleSetLimit = async (limit: number) => {
    dispatch(setLimit(limit));
    handleSetOffset(1);
  };

  return (
    <TableFooter
      rows={users}
      handleSetOffset={handleSetOffset}
      handleSetLimit={handleSetLimit}
      offset={offset}
      limit={limit}
    />
  );
}
