import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Typography,
  Box,
  Link,
  makeStyles,
  Container,
  CssBaseline,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
// global state
import { useAppDispatch } from '../app/store';
import {
  useAuthSelector,
  confirmForgotPassword,
} from '../features/auth/authSlice';
// interface
import { IConfirmForgotPsswdForm } from '../interfaces';
// components
import CircularProgress from '../common/CircularProgress';
// image
import logo from '../images/logoTrim.png';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
    height: '100vh',
  },
  image: {
    filter:
      'invert(80%) sepia(0%) saturate(3261%) hue-rotate(257deg) brightness(91%) contrast(92%)',
  },
}));

export default function NotFound(): JSX.Element {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { loading } = useAuthSelector((state) => state.auth);

  const { email, oneTimeToken } = useParams<IConfirmForgotPsswdForm>();

  const [helperText, setHelperText] = useState<string | null>(null);

  const handleConfirmForgotPassword = async () => {
    try {
      const resultAction = await dispatch(
        confirmForgotPassword({ email, oneTimeToken })
      );
      const res = unwrapResult(resultAction);
      setHelperText(`✔️ ${res.message}`);
    } catch (err: any) {
      setHelperText(`❌ ${err.message}`);
    }
  };

  useEffect(() => {
    handleConfirmForgotPassword();
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      {loading ? (
        <div className={classes.paper}>
          <Box component="span" my={8} pt={25}>
            <Typography variant="h3">Cargando...</Typography>
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <div className={classes.paper}>
          <Box component="span" my={8} pt={20}>
            <Typography variant="h3">{helperText}</Typography>
          </Box>
          <Box mx={4} my={4} pt={10}>
            {/* <Link color="inherit" href="/">
              Ir a login
            </Link> */}
            <img alt="logo" src={logo} className={classes.image} />
          </Box>
        </div>
      )}
    </Container>
  );
}
