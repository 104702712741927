export const formatDate = (date: string | null) => {
  if (date) {
    const dateObject = new Date(date);
    const formatedDate = dateObject.toLocaleDateString('en-UK');
    return formatedDate;
  }
  return '-';
};

export const formatDateAndTime = (date: string | null) => {
  if (date) {
    const dateObject = new Date(date);
    const formatedDate = dateObject.toLocaleString('en-UK', {
      timeZone: 'America/Buenos_Aires',
    });
    return formatedDate;
  }
  return '-';
};
