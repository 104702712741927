import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#bb7969',
    },
    secondary: {
      //main: '#525356',
      main: '#3a3b3f',
    },
    info: {
      main: '#f2f3ef',
    },
    warning: {
      main: '#5C8378',
    },
    error: {
      main: '#f53d49',
      // main: '#ff1f2e',
    },
    success: {
      main: '#5ebd5e',
      // main: '#3da13d',
    },
    text: {
      primary: '#3A3B3E',
      secondary: '#696969',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: ['Barlow', 'sans-serif'].join(','),
    /* fontFamily: 'Roboto', */
    fontSize: 14,
  },
});

export default theme;
