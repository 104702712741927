import React from 'react';
// components
import { EditButton } from '../../../../../common/Table/Header/Buttons';
// children components
import TaxpayerCreateOrUpdateDialog from '../../../../../Dialogs/Taxpayers/TaxpayerCreateOrUpdateDialog';

export default function EditTaxpayerButton() {
  const [openDialog, setOpenDialog] = React.useState(false);

  return (
    <EditButton setOpenDialog={setOpenDialog}>
      <TaxpayerCreateOrUpdateDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </EditButton>
  );
}
