import { API } from '../utils';
import {
  IAuthUser,
  IRole,
  ILoginForm,
  IChangePsswdForm,
  IChangeEmailForm,
  IForgotPsswdForm,
  IConfirmForgotPsswdForm,
  IResponse,
} from '../interfaces';

/**
 * signin
 */
export const signIn = async (authData: ILoginForm): Promise<IAuthUser> => {
  return API.post<IAuthUser>(`auth/signin`, authData);
};

/**
 * change password
 */
export const changePsswd = async (
  changePsswdData: IChangePsswdForm
): Promise<string> => {
  return API.put<string>(`auth/change-password`, changePsswdData);
};

/**
 * change password
 */
export const changeEmail = async (
  changeEmailData: IChangeEmailForm
): Promise<string> => {
  return API.put<string>(`auth/change-email`, changeEmailData);
};

/**
 * forgot password
 */
export const forgotPassword = async (
  forgotPsswdData: IForgotPsswdForm
): Promise<string> => {
  return API.post<string>(`auth/forgot-password`, forgotPsswdData);
};

/**
 * confirm forgot password
 */
export const confirmForgotPassword = async (
  confirmForgotPsswdData: IConfirmForgotPsswdForm
): Promise<IResponse> => {
  const { email, oneTimeToken } = confirmForgotPsswdData;
  return API.get<IResponse>(
    `auth/confirm-forgot-password/${email}/${oneTimeToken}`
  );
};

/**
 * fetch all roloes
 */
export const fetchRoles = async (): Promise<IRole[]> => {
  return API.get<IRole[]>(`auth/get-roles`);
};
