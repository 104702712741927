import {
  makeStyles,
  Theme,
  createStyles,
  CssBaseline,
} from '@material-ui/core';
// components
import MenuAppBar from '../components/Dashboard/AppBar/MenuAppBar';
import ResponsiveDrawer from '../components/Dashboard/Drawer/ResponsiveDrawer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      height: '100vh',
      padding: theme.spacing(3),
    },
  })
);

interface MainViewProps {
  children: JSX.Element;
}

export default function MainView({ children }: MainViewProps) {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div style={{ backgroundColor: '#fdfcff' }}>
        <CssBaseline />
        <MenuAppBar />
        <ResponsiveDrawer>{children}</ResponsiveDrawer>
      </div>
    </main>
  );
}
