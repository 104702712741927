import React from 'react';
// global state
import { useAppDispatch } from '../../../../../../../../../app/store';
import {
  selectTaxpayer,
  useTaxpayersSelector,
} from '../../../../../../../../../features/taxpayers/taxpayersSlice';
import {
  useUISelector,
  setCollapsed,
} from '../../../../../../../../../features/UI/uiSlice';
// interfaces
import { IColumn, ITaxpayer } from '../../../../../../../../../interfaces';
// utils
import { formatNulls } from '../../../../../../../../../utils';
// components
import {
  StyledAvatarTableCell,
  TableContent,
} from '../../../../../../common/Table/Body/content/TableContent';
import TaxpayerCollapseContent from './Collapse/TaxpayerCollapseContent';

interface TaxpayerTableContentProps {
  row: ITaxpayer;
  labelId: string;
  columns: IColumn<ITaxpayer>[];
  isCollapse: boolean;
}

export default function TaxpayerTableContent({
  row,
  labelId,
  columns,
  isCollapse,
}: TaxpayerTableContentProps) {
  const dispatch = useAppDispatch();

  const { taxpayer } = useTaxpayersSelector((state) => state.taxpayers);

  const { collapsed } = useUISelector((state) => state.ui);

  const handleClick = (e: React.MouseEvent<unknown>, row: any) => {
    let newSelected: ITaxpayer | null = null;
    if (taxpayer === row) {
      // close collapse
      dispatch(setCollapsed(null));
    } else {
      newSelected = row;
      // close collapse
      if (collapsed !== row.uuid) {
        dispatch(setCollapsed(null));
      }
    }
    dispatch(selectTaxpayer(newSelected));
  };

  const toggleCollapse = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    dispatch(setCollapsed(row.uuid));
    // deselect item if needed
    if (taxpayer) {
      if (taxpayer.uuid !== row.uuid) {
        dispatch(selectTaxpayer(null));
      }
    }
  };

  const toTaxpayer = (column: IColumn<ITaxpayer>) => {
    const value = formatNulls(row[column.id]);
    return (
      <StyledAvatarTableCell
        key={column.id}
        align={column.align}
        style={{ minWidth: column.minWidth }}
      >
        {typeof column?.render === 'function'
          ? column.render(row)
          : formatNulls(row[column.id])}
      </StyledAvatarTableCell>
    );
  };

  return (
    <>
      <TableContent
        row={row}
        labelId={labelId}
        isCollapse={isCollapse}
        selectedItem={taxpayer}
        handleClick={handleClick}
        toggleCollapse={toggleCollapse}
      >
        {columns.map(toTaxpayer)}
      </TableContent>

      <TaxpayerCollapseContent row={row} />
    </>
  );
}
