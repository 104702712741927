// global state
import { useAppDispatch } from '../../../../../../../app/store';
import {
  useTaxpayersSelector,
  setOffset,
  setLimit,
  selectTaxpayer,
} from '../../../../../../../features/taxpayers/taxpayersSlice';
// components
import { TableFooter } from '../../../../common/Table/Footer';

export default function TaxpayerTableFooter() {
  const dispatch = useAppDispatch();

  const { taxpayers, offset, limit } = useTaxpayersSelector(
    (state) => state.taxpayers
  );

  const handleSetOffset = async (offset: number) => {
    dispatch(setOffset(offset));
    dispatch(selectTaxpayer(null));
  };

  const handleSetLimit = async (limit: number) => {
    dispatch(setLimit(limit));
    handleSetOffset(1);
  };

  return (
    <TableFooter
      rows={taxpayers}
      handleSetOffset={handleSetOffset}
      handleSetLimit={handleSetLimit}
      offset={offset}
      limit={limit}
    />
  );
}
