import React, { useState, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Dialog,
  FormControl,
  TextField,
  Button,
  makeStyles,
  createStyles,
  Theme,
  Box,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Grid,
  Avatar,
  Typography,
  Tooltip,
  MenuItem,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  updateTaxpayer,
  fetchTaxpayers,
  useTaxpayersSelector,
  selectTaxpayer,
  createTaxpayer,
  fetchTaxpayerById,
} from '../../../../../features/taxpayers/taxpayersSlice';
import { useTaxpayerTypesSelector } from '../../../../../features/taxpayers/taxpayerTypesSlice';
// api
import { fetchDriveItemById } from '../../../../../api/drive.services';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// interface
import {
  ITaxpayer,
  ITaxpayerForm,
  IUser,
  ITax,
  IFile,
} from '../../../../../interfaces';
// constants
import {
  MONOTRIBUTISTA,
  PERSONA_FISICA,
  PERSONA_JURIDICA,
  MIN_LEN_COMP_NAME,
  MAX_LEN_COMP_NAME,
  MIN_LEN_CUIT,
  MAX_LEN_CUIT,
  IMAGE_FORMAT,
} from '../../../../../constants';
// utils
import {
  capitalize,
  removeExtraSpace,
  removeSpace,
  NumberFormatCUIT,
  compressFile,
  createFileReader,
} from '../../../../../utils';
// hooks
import { useTaxpayerTypes } from '../../../../../hooks/useTaxpayerTypes';
// components
import {
  FormHeader,
  FormBody,
  FormFooter,
  StyledGrid,
} from '../../common/Forms';
import DriveFolderList from './CreateOrUpdateContent/DriveFolderList';
import ClientAutocomplete from './CreateOrUpdateContent/ClientAutocomplete';
import ManagerAutocomplete from './CreateOrUpdateContent/ManagerAutocomplete';
import TaxesAutocomplete from './CreateOrUpdateContent/TaxesAutocomplete';
import MonthAutocomplete from './CreateOrUpdateContent/MonthAutocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.secondary.light,
    },
    listItemIcon: {
      minWidth: '20px',
    },
    logo: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      backgroundColor: theme.palette.secondary.light,
    },
    closeButton: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      color: theme.palette.grey[500],
    },
  })
);

interface TaxpayerCreateOrUpdateDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TaxpayerCreateOrUpdateDialog({
  openDialog,
  setOpenDialog,
}: TaxpayerCreateOrUpdateDialogProps) {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const {
    dispatch: { setSnackbar, errorSnackbar },
  } = useSnackbar();

  const { getTaxpayerTypeID } = useTaxpayerTypes();

  const [openDriveDialog, setOpenDriveDialog] = useState(false);

  const handleClickDrive = () => {
    setOpenDriveDialog(!openDriveDialog);
  };

  const {
    taxpayer,
    actionLoading,
    offset,
    limit,
    filter,
    showDisabled,
    sort_column,
    sort_direction,
  } = useTaxpayersSelector((state) => state.taxpayers);

  const { taxpayerTypes } = useTaxpayerTypesSelector(
    (state) => state.taxpayerTypes
  );

  const [users, setUsers] = useState<IUser[]>([]);

  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);

  const [selectedTaxes, setSelectedTaxes] = useState<ITax[]>([]);

  const [removeLogo, setRemoveLogo] = useState<boolean>(false);

  const [linkedFolder, setLinkedFolder] = useState<IFile | null>(null);

  const [helperText, setHelperText] = useState('');

  const validationSchema = Yup.object({
    company_name: Yup.string()
      .required(`Requerido!`)
      .min(MIN_LEN_COMP_NAME, `Al menos ${MIN_LEN_COMP_NAME} caracteres.`)
      .max(MAX_LEN_COMP_NAME, `Maximo ${MAX_LEN_COMP_NAME} caracteres.`),
    cuit: Yup.string()
      .required(`Requerido!`)
      .min(MIN_LEN_CUIT, `Al menos ${MIN_LEN_CUIT} caracteres.`)
      .max(MAX_LEN_CUIT, `Maximo ${MAX_LEN_CUIT} caracteres.`),
    manager: Yup.string()
      .required(`Requerido!`)
      .min(MIN_LEN_COMP_NAME, `Al menos ${MIN_LEN_COMP_NAME} caracteres.`)
      .max(MAX_LEN_COMP_NAME, `Maximo ${MAX_LEN_COMP_NAME} caracteres.`),
    folder_id: Yup.string(),
    employer: Yup.boolean(),
  });

  const handleFetchTaxpayerById = async (taxpayer: ITaxpayer) => {
    const taxpayerUuid: string = taxpayer.uuid;
    try {
      const resultAction = await dispatch(fetchTaxpayerById(taxpayerUuid));
      const payload = unwrapResult(resultAction);
      formik.setValues({
        users_uuid: payload.users_uuid,
        taxpayer_type_id: payload.taxpayer_type_id,
        cuit: payload.cuit,
        company_name: payload.company_name,
        manager: payload.manager,
        employer: payload.employer,
        logo: `data:image/jpeg;base64,${payload.logo}`,
        logoBlob: null,
        folder_id: payload.folder_id,
        financial_year_close: payload.financial_year_close,
        taxes_uuid: payload.taxes_uuid,
      });
    } catch (err: any) {
      errorSnackbar();
    }
  };

  const handleFetchLinkedFolder = async () => {
    if (taxpayer && taxpayer.folder_id) {
      try {
        const linkedFolder = await fetchDriveItemById(taxpayer.folder_id);
        setLinkedFolder(linkedFolder);
      } catch (err: any) {
        errorSnackbar();
      }
    }
  };

  useEffect(() => {
    if (taxpayer && openDialog) {
      handleFetchTaxpayerById(taxpayer);
      handleFetchLinkedFolder();
    }
  }, [openDialog]);

  const handleSubmit = async (taxpayerData: ITaxpayerForm) => {
    const formData = new FormData();
    formData.append('users_uuid', JSON.stringify(taxpayerData.users_uuid));
    formData.append('cuit', taxpayerData.cuit);
    formData.append('company_name', taxpayerData.company_name);
    formData.append('manager', taxpayerData.manager);
    formData.append('folder_id', taxpayerData.folder_id);
    formData.append('employer', taxpayerData.employer.toString());
    formData.append(
      'taxpayer_type_id',
      taxpayerData.taxpayer_type_id.toString()
    );
    if (taxpayerData.financial_year_close) {
      formData.append(
        'financial_year_close',
        taxpayerData.financial_year_close
      );
    }
    formData.append('taxes_uuid', JSON.stringify(taxpayerData.taxes_uuid));
    if (taxpayerData.logoBlob) {
      formData.append('logo', taxpayerData.logoBlob);
    }
    if (taxpayer) {
      handleUpdateTaxpayer(taxpayer, formData, removeLogo);
    } else {
      handleCreateTaxpayer(formData);
    }
  };

  const initialValues: ITaxpayerForm = {
    users_uuid: [],
    taxpayer_type_id: getTaxpayerTypeID(PERSONA_JURIDICA),
    cuit: '',
    company_name: '',
    manager: '',
    employer: false,
    logo: null,
    logoBlob: null,
    folder_id: '',
    financial_year_close: 'december',
    taxes_uuid: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const handleResetError = () => {
    setHelperText('');
    formik.setErrors({});
  };

  const handleClose = () => {
    formik.setValues(formik.initialValues);
    setUsers([]);
    setOpenDialog(false);
    handleResetError();
    setLinkedFolder(null);
  };

  const handleCreateTaxpayer = async (taxpayerData: FormData) => {
    try {
      const resultAction = await dispatch(createTaxpayer(taxpayerData));
      const res = unwrapResult(resultAction);
      setSnackbar(true, res.message);
      dispatch(selectTaxpayer(null));
      dispatch(
        fetchTaxpayers({
          offset,
          limit,
          filter,
          showDisabled,
          sort_column,
          sort_direction,
        })
      );
      handleClose();
    } catch (err: any) {
      setHelperText(`❌ ${err.message}`);
      errorSnackbar();
    }
  };

  const handleUpdateTaxpayer = async (
    selectedTaxpayer: ITaxpayer,
    taxpayerData: FormData,
    removeLogo: boolean
  ) => {
    const taxpayerUuid: string = selectedTaxpayer.uuid;
    try {
      const resultAction = await dispatch(
        updateTaxpayer({ taxpayerUuid, taxpayerData, removeLogo })
      );
      const res = unwrapResult(resultAction);
      setSnackbar(true, res.message);
      dispatch(selectTaxpayer(null));
      dispatch(
        fetchTaxpayers({
          offset,
          limit,
          filter,
          showDisabled,
          sort_column,
          sort_direction,
        })
      );
    } catch (err: any) {
      setHelperText(`❌ ${err.message}`);
      errorSnackbar();
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0].name.match(IMAGE_FORMAT)) {
      try {
        // compressing image
        const compressedFile = await compressFile(e.target.files[0]);
        // converting to base64 and saving
        const fileReader = createFileReader(compressedFile);
        fileReader.onloadend = () => {
          formik.setFieldValue('logo', fileReader.result);
        };
        // saving blob
        formik.setFieldValue('logoBlob', compressedFile);
        // setting removeLogo to false
        setRemoveLogo(false);
      } catch (err) {
        errorSnackbar();
      }
    }
    handleResetError();
    // reset the input field so if file is removed it can re-add the same file.
    e.target.value = '';
  };

  const handleChangeTaxpayerTypeId = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (Number(e.target.value) === getTaxpayerTypeID(MONOTRIBUTISTA)) {
      formik.setFieldValue('financial_year_close', null);
    }
    if (Number(e.target.value) === getTaxpayerTypeID(PERSONA_FISICA)) {
      formik.setFieldValue('financial_year_close', 'december');
    }
    if (Number(e.target.value) === getTaxpayerTypeID(PERSONA_JURIDICA)) {
      formik.setFieldValue('financial_year_close', 'december');
    }
    formik.setFieldValue('taxpayer_type_id', e.target.value);
    handleResetError();
  };

  const handleRemoveLogo = () => {
    setRemoveLogo(true);
    formik.setFieldValue('logoBlob', null);
    formik.setFieldValue('logo', null);
  };

  const onDirectoryChange = (folder: IFile) => {
    setLinkedFolder(folder);
    formik.setFieldValue('folder_id', folder.id);
    handleResetError();
  };

  return (
    <Dialog
      open={openDialog}
      keepMounted
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="simple-dialog"
    >
      <FormControl component="fieldset" error={!!helperText}>
        <form onSubmit={formik.handleSubmit}>
          <FormHeader handleClose={handleClose}>
            {`${taxpayer ? 'Actualizar' : 'Crear'} contribuyente 💼`}
          </FormHeader>
          <FormBody loading={actionLoading} helperText={helperText}>
            <>
              <StyledGrid item xs={12} sm={6}>
                <TextField
                  id="company_name"
                  name="company_name"
                  label="Razón Social *"
                  variant="outlined"
                  size="small"
                  value={formik.values.company_name}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'company_name',
                      removeExtraSpace(capitalize(e.target.value))
                    );
                    handleResetError();
                  }}
                  type="text"
                  autoComplete="off"
                  style={{ width: 230 }}
                  inputProps={{
                    maxLength: MAX_LEN_COMP_NAME,
                  }}
                  helperText={
                    formik.errors.company_name
                      ? formik.errors.company_name
                      : ' '
                  }
                  error={!!formik.errors.company_name}
                />
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <TextField
                  id="cuit"
                  name="cuit"
                  label="CUIT *"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  style={{ width: 230 }}
                  value={formik.values.cuit}
                  onChange={(e) => {
                    formik.setFieldValue('cuit', removeSpace(e.target.value));
                    handleResetError();
                  }}
                  inputProps={{
                    maxLength: MAX_LEN_CUIT,
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCUIT as any,
                  }}
                  helperText={formik.errors.cuit ? formik.errors.cuit : ' '}
                  error={!!formik.errors.cuit}
                />
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <TextField
                  select
                  label="Tipo de contribuyente *"
                  variant="outlined"
                  size="small"
                  style={{ width: 225 }}
                  value={formik.values.taxpayer_type_id}
                  onChange={handleChangeTaxpayerTypeId}
                  helperText={
                    formik.errors.taxpayer_type_id
                      ? formik.errors.taxpayer_type_id
                      : ' '
                  }
                >
                  {taxpayerTypes.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </TextField>
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <MonthAutocomplete
                  financialYearClose={formik.values.financial_year_close}
                  financialYearCloseErrors={formik.errors.financial_year_close}
                  handleResetError={handleResetError}
                  formikSetFieldValue={formik.setFieldValue}
                  taxpayerTypeId={formik.values.taxpayer_type_id}
                />
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="employer"
                      checked={formik.values.employer}
                      onChange={formik.handleChange}
                      name="employer"
                      color="primary"
                    />
                  }
                  label="Empleador"
                />
                <FormHelperText>
                  {formik.errors.employer ? formik.errors.employer : ' '}
                </FormHelperText>
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <ManagerAutocomplete
                  manager={formik.values.manager}
                  managerErrors={formik.errors.manager}
                  formikSetFieldValue={formik.setFieldValue}
                  handleResetError={handleResetError}
                />
              </StyledGrid>
              <StyledGrid item xs={12} sm={12}>
                <ClientAutocomplete
                  usersUuidErrors={formik.errors.users_uuid}
                  handleResetError={handleResetError}
                  formikSetFieldValue={formik.setFieldValue}
                  users={users}
                  setUsers={setUsers}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  openDialog={openDialog}
                />
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <Box pb={3} pt={3}>
                  <Button
                    variant="outlined"
                    component="label"
                    color="primary"
                    style={{ width: 230 }}
                  >
                    {`${taxpayer ? 'Actualizar' : 'Cargar'} logotipo`}
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={handleImageChange}
                    />
                  </Button>
                </Box>
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={12} sm={8}>
                    <Box display="flex" justifyContent="flex-end">
                      <Avatar
                        className={classes.logo}
                        src={formik.values.logo ? formik.values.logo : ''}
                      >
                        {formik.values.company_name ? (
                          <Typography variant="h4">
                            {formik.values.company_name.charAt(0)}
                          </Typography>
                        ) : null}
                      </Avatar>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box display="flex" justifyContent="flex-end">
                      <Tooltip arrow title={'Quitar logo'}>
                        <IconButton
                          aria-label="close"
                          className={classes.closeButton}
                          onClick={handleRemoveLogo}
                        >
                          <ClearRoundedIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <Box pb={3} pt={3}>
                  <Button
                    variant="outlined"
                    component="label"
                    color="primary"
                    style={{ width: 230 }}
                    onClick={handleClickDrive}
                  >
                    {`Vincular con directorio`}
                  </Button>
                </Box>
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <Box pt={1.5}>
                  <TextField
                    id="directory"
                    name="directory"
                    label="Directorio"
                    size="small"
                    style={{ width: 225 }}
                    value={linkedFolder ? linkedFolder.name : ''}
                    inputProps={{ readOnly: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              formik.setFieldValue('folder_id', '');
                              setLinkedFolder(null);
                            }}
                            edge="end"
                          >
                            <ListItemIcon className={classes.listItemIcon}>
                              <ClearRoundedIcon
                                fontSize="default"
                                className={classes.icon}
                              />
                            </ListItemIcon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      formik.errors.folder_id ? formik.errors.folder_id : ' '
                    }
                    error={!!formik.errors.folder_id}
                  />
                </Box>
              </StyledGrid>
              <StyledGrid item xs={12} sm={12}>
                <TaxesAutocomplete
                  taxesUuidErrors={formik.errors.taxes_uuid}
                  handleResetError={handleResetError}
                  formikSetFieldValue={formik.setFieldValue}
                  setSelectedTaxes={setSelectedTaxes}
                  selectedTaxes={selectedTaxes}
                  openDialog={openDialog}
                />
              </StyledGrid>
            </>
          </FormBody>
          <FormFooter>
            <Button autoFocus type="submit" color="primary" variant="contained">
              {taxpayer ? 'Confirmar' : 'Añadir'}
            </Button>
          </FormFooter>
        </form>
      </FormControl>
      <DriveFolderList
        openDriveDialog={openDriveDialog}
        openDialog={openDialog}
        setOpenDriveDialog={setOpenDriveDialog}
        onDirectoryChange={onDirectoryChange}
      />
    </Dialog>
  );
}
