import { API } from '../utils';
import { IUser, IUserForm, IResponse } from '../interfaces';

/**
 * get my user
 */
export const fetchMyUser = async (): Promise<IUser> => {
  return API.get<IUser>(`conf/get-my-user`);
};

/**
 * fetch admins
 */
export const fetchUsersAdmin = async (
  offset: number,
  limit: number,
  filter: string,
  showDisabled: boolean,
  sort_column: string,
  sort_direction: string
): Promise<IUser[]> => {
  return API.get<IUser[]>(
    `conf/get-users-admin?offset=${offset}&limit=${limit}&filter=${filter}&showDisabled=${showDisabled}&sort_column=${sort_column}&sort_direction=${sort_direction}`
  );
};

/**
 * fetch clients
 */
export const fetchUsersClient = async (
  offset: number,
  limit: number,
  filter: string,
  showDisabled: boolean,
  sort_column: string,
  sort_direction: string
): Promise<IUser[]> => {
  return API.get<IUser[]>(
    `conf/get-users-client?offset=${offset}&limit=${limit}&filter=${filter}&showDisabled=${showDisabled}&sort_column=${sort_column}&sort_direction=${sort_direction}`
  );
};

/**
 * fetch taxpayer clients
 */
export const fetchTaxpayerClients = async (uuid: string): Promise<IUser[]> => {
  return API.get<IUser[]>(`conf/get-taxpayer-clients/${uuid}`);
};

/**
 * fetch one
 */
export const fetchUserByUuid = async (uuid: string): Promise<IUser> => {
  return API.get<IUser>(`conf/get-user/${uuid}`);
};

/**
 * create one
 */
export const createUser = async (userData: IUserForm): Promise<IResponse> => {
  return API.post(`conf/create-user`, userData);
};

/**
 * update one
 */
export const updateUser = async (
  uuid: string,
  userData: IUserForm
): Promise<IResponse> => {
  return API.put(`conf/update-user/${uuid}`, userData);
};

/**
 * toggle status
 */
export const toggleUserStatus = async (
  uuid: string,
  reason: string
): Promise<IResponse> => {
  return API.get(`conf/toggle-user-status/${uuid}?reason=${reason}`);
};

/**
 * delete one
 */
export const deleteUser = async (uuid: string): Promise<IResponse> => {
  return API.delete(`conf/delete-user/${uuid}`);
};

/**
 * reset user password
 */
export const resetUserPsswd = async (uuid: string): Promise<IResponse> => {
  return API.get(`conf/reset-user-psswd/${uuid}`);
};

/**
 * send activation email
 */
export const sendActivationEmail = async (
  email: string
): Promise<IResponse> => {
  return API.get(`conf/send-activation-email/${email}`);
};

/**
 * check if email sent
 */
export const fetchIsEmailSent = async (uuid: string): Promise<IResponse> => {
  return API.get(`conf/is-email-sent/${uuid}`);
};
