import React from 'react';
import { Button, Typography } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
// children components
import TaxpayerCreateOrUpdateDialog from '../../../../../Dialogs/Taxpayers/TaxpayerCreateOrUpdateDialog';

export default function AddTaxpayerButton() {
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <>
      <Button variant="contained" color="secondary" onClick={handleClick}>
        <AddRoundedIcon />
        <Typography variant="subtitle2">{'Añadir Contribuyente'}</Typography>
      </Button>
      <TaxpayerCreateOrUpdateDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </>
  );
}
