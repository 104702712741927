import { useEffect } from 'react';
import { useAppDispatch } from '../app/store';
// global state
import { fetchMyUser, useMyUserSelector } from '../features/auth/myUserSlice';
// children components
import MainProfile from '../components/Profile/MainProfile';
// common
import { Loading } from '../common';

export default function Profile() {
  const dispatch = useAppDispatch();

  const { loading } = useMyUserSelector((state) => state.myUser);

  useEffect(() => {
    dispatch(fetchMyUser());
  }, [dispatch]);

  const renderProfile = () => {
    if (loading) return <Loading />;

    return <MainProfile />;
  };

  return <>{renderProfile()}</>;
}
