import { Middleware } from 'redux';
import { RootState } from '../app/rootReducer';
import { AppDispatch } from '../app/store';
import { signOut } from '../features/auth/authSlice';
import { getAccessToken, isAuthenticated, isTokenExpired } from '../utils';

/**
 * Check if token was altered or expired
 */
export const checkToken: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    // if it's a auth action returns
    if (action.type.includes('auth/')) return next(action);

    // if it's an already started action returns
    if (!action.type.includes('Start') && !action.type.includes('/pending'))
      return next(action);

    if (store.getState().auth.loggedIn) {
      // logout in token expired
      if (isTokenExpired()) {
        return (store.dispatch as AppDispatch)(signOut());
      }
      // logout in token altered
      const browserToken = getAccessToken();
      const stateToken = store.getState().auth.authUser.accessToken!;
      if (isAuthenticated()) {
        if (browserToken !== stateToken)
          return (store.dispatch as AppDispatch)(signOut());
      } else {
        return (store.dispatch as AppDispatch)(signOut());
      }
    }

    return next(action);
  };
