import { useEffect } from 'react';
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
} from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../../../../../../app/store';
import {
  useTaxpayersSelector,
  resetTaxpayers,
  fetchClientTaxpayers,
} from '../../../../../../../../../../features/taxpayers/taxpayersSlice';
import { useUISelector } from '../../../../../../../../../../features/UI/uiSlice';
// context
import { useSnackbar } from '../../../../../../../../../../context/SnackbarContext';
// interface
import {
  IColumn,
  ITaxpayer,
  IUser,
} from '../../../../../../../../../../interfaces';
// utils
import {
  formatDate,
  formatCuit,
  formatNulls,
} from '../../../../../../../../../../utils';
// components
import { CollapseContent } from '../../../../../../../common/Table/Body/content/TableContent/Collapse';
import { LoadingCollapseTable } from '../../../../../../../common/CollapseTable';
import { ErrorCollapseTable } from '../../../../../../../common/CollapseTable';
import { AvatarAndName } from '../../../../../../../common/Utils';

const columns: IColumn<ITaxpayer>[] = [
  {
    id: 'company_name',
    label: 'Razón Social',
    minWidth: 100,
    align: 'left',
    render: (row) => <AvatarAndName row={row} />,
  },
  {
    id: 'cuit',
    label: 'CUIT',
    minWidth: 100,
    align: 'left',
    render: (row) => formatCuit(row.cuit),
  },
  { id: 'manager', label: 'Asesor', minWidth: 100, align: 'left' },
  {
    id: 'enabled_at',
    label: 'Fecha de alta',
    minWidth: 100,
    align: 'left',
    render: (row) => formatDate(row.enabled_at),
  },
  // THIS FEATURE BELLOW WON'T BE USED FOR NOW. MAYBE LATER.
  // {
  //   id: 'disabled_at',
  //   label: 'Fecha de baja',
  //   minWidth: 100,
  //   align: 'left',
  // },
  // {
  //   id: 'enabled',
  //   label: 'Situación',
  //   minWidth: 100,
  //   align: 'right',
  // },
];

interface CliCollapseContentProps {
  row: IUser;
}

export default function CliCollapseContent({ row }: CliCollapseContentProps) {
  const dispatch = useAppDispatch();

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const { collapsed } = useUISelector((state) => state.ui);

  const { taxpayers, loading, error } = useTaxpayersSelector(
    (state) => state.taxpayers
  );

  const isCollapsed = (row: IUser) => collapsed === row.uuid;
  const isItemCollapsed = isCollapsed(row);

  const loadTaxpayers = async (user: IUser) => {
    const userUuid: string = user.uuid;
    try {
      await dispatch(fetchClientTaxpayers(userUuid));
    } catch (err: any) {
      errorSnackbar();
    }
  };

  useEffect(() => {
    if (isItemCollapsed) {
      dispatch(resetTaxpayers());
      loadTaxpayers(row);
    }
  }, [collapsed]);

  const toTaxpayer = (column: IColumn<ITaxpayer>, taxpayer: ITaxpayer) => {
    return (
      <TableCell
        key={column.id}
        align={column.align}
        style={{
          minWidth: column.minWidth,
        }}
      >
        {typeof column?.render === 'function'
          ? column.render(taxpayer)
          : formatNulls(taxpayer[column.id])}
      </TableCell>
    );
  };

  return (
    <CollapseContent isItemCollapsed={isItemCollapsed}>
      <Box pt={2} pb={5}>
        {loading ? (
          <Table size="small">
            <LoadingCollapseTable isItemCollapsed={isItemCollapsed} />
          </Table>
        ) : (
          <>
            <Typography variant="subtitle1">
              <Box pb={2} fontWeight="fontWeightBold">
                {'Contribuyentes:'}
              </Box>
            </Typography>
            {error ? (
              <ErrorCollapseTable />
            ) : taxpayers.length === 0 && !loading ? (
              <Typography variant="subtitle1">
                <Box pt={1} pb={0}>
                  {'- Sin contribuyentes asignados hasta el momento.'}
                </Box>
              </Typography>
            ) : (
              <>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id} align={column.align}>
                          <Box fontWeight="fontWeightBold">{column.label}</Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taxpayers.map((taxpayer: ITaxpayer | any) => (
                      <TableRow key={taxpayer.uuid}>
                        {columns.map((column) => toTaxpayer(column, taxpayer))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </>
        )}
      </Box>
    </CollapseContent>
  );
}
