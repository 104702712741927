import { TableBody } from '@material-ui/core';
// global state
import { useNotificationsSelector } from '../../../../../../../../features/notifications/notificationsSlice';
// interface
import { IColumn, INotification } from '../../../../../../../../interfaces';
// children components
import NotificationTableContent from './TableContent/NotificationTableContent';

interface NotificationTableBodyContainerProps {
  columns: IColumn<INotification>[];
  isCollapse: boolean;
}

export default function NotificationTableBodyContainer({
  columns,
  isCollapse,
}: NotificationTableBodyContainerProps) {
  const { notifications } = useNotificationsSelector(
    (state) => state.notifications
  );

  return (
    <TableBody>
      {notifications.map((row) => {
        return (
          <NotificationTableContent
            row={row}
            labelId={row.uuid}
            key={row.uuid}
            columns={columns}
            isCollapse={isCollapse}
          />
        );
      })}
    </TableBody>
  );
}
