import { combineReducers } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
// reducers
import { uiReducer } from '../features/UI';
import { authReducer, myUserReducer, rolesReducer } from '../features/auth';
import { usersReducer } from '../features/users';
import { taxpayersReducer, taxpayerTypesReducer } from '../features/taxpayers';
import { driveReducer } from '../features/drive';
import { taxesReducer } from '../features/taxes';
import { notificationsReducer } from '../features/notifications';

const appReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  users: usersReducer,
  taxpayers: taxpayersReducer,
  taxpayerTypes: taxpayerTypesReducer,
  roles: rolesReducer,
  myUser: myUserReducer,
  drive: driveReducer,
  taxes: taxesReducer,
  notifications: notificationsReducer,
});

const rootReducer = (
  state: RootState | undefined,
  action: PayloadAction<any>
) => {
  // reset state after signout
  if (action.type.includes('auth/signOut/fulfilled')) {
    state = undefined;
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
