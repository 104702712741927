import { useEffect } from 'react';
import { Paper } from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  fetchUsersClient,
  useUsersSelector,
  selectUser,
} from '../../../../../features/users/usersSlice';
import {
  fetchRoles,
  useRolesSelector,
} from '../../../../../features/auth/rolesSlice';
import { setCollapsed } from '../../../../../features/UI/uiSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// common
import { Error } from '../../../../../common';
// children components
import ClientTableHeader from './content/Header/ClientTableHeader';
import ClientTableBody from './content/Body/ClientTableBody';
import ClientTableFooter from './content/Footer/ClientTableFooter';

export default function ClientsView() {
  const dispatch = useAppDispatch();

  const {
    error,
    offset,
    limit,
    filter,
    showDisabled,
    sort_column,
    sort_direction,
  } = useUsersSelector((state) => state.users);

  const { roles } = useRolesSelector((state) => state.roles);

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  useEffect(() => {
    dispatch(selectUser(null));
    dispatch(setCollapsed(null));
  }, [showDisabled, offset, limit]);

  useEffect(() => {
    if (roles.length === 0)
      try {
        dispatch(fetchRoles());
      } catch (err) {
        errorSnackbar();
      }
  }, []);

  useEffect(() => {
    try {
      dispatch(
        fetchUsersClient({
          offset,
          limit,
          filter,
          showDisabled,
          sort_column,
          sort_direction,
        })
      );
      dispatch(selectUser(null));
    } catch (err) {
      errorSnackbar();
    }
    // filter won't goes here cause it's handled by a debounce function in SearchField component
  }, [showDisabled, offset, limit, sort_column, sort_direction]);

  if (error) return <Error />;

  return (
    <Paper>
      <ClientTableHeader />
      <ClientTableBody />
      <ClientTableFooter />
    </Paper>
  );
}
