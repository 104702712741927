import React from 'react';
import { TablePagination } from '@material-ui/core';

interface TableFooterProps {
  rows: any[];
  handleSetOffset: (page: number) => void;
  handleSetLimit: (rows: number) => void;
  offset: number;
  limit: number;
  isTotalRowsUndefined?: boolean;
  nextPageToken?: string | null;
}

export default function TableFooter({
  rows,
  handleSetOffset,
  handleSetLimit,
  offset,
  limit,
  isTotalRowsUndefined = false,
  nextPageToken,
}: TableFooterProps) {
  const handleChangePage = (
    e: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    handleSetOffset(page + 1);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSetLimit(Number(e.target.value));
  };

  return (
    <TablePagination
      rowsPerPageOptions={[50, 100, 150, 200]}
      colSpan={3}
      component="div"
      labelRowsPerPage={'Filas por página:'}
      labelDisplayedRows={(from) =>
        isTotalRowsUndefined
          ? null
          : `${from.from}-${from.to === -1 ? from.count : from.to} ${'de'} ${
              from.count
            }`
      }
      count={
        isTotalRowsUndefined
          ? nextPageToken
            ? nextPageToken
            : null
          : rows[0] && rows[0].meta_total_rows
          ? rows[0].meta_total_rows
          : ''
      }
      rowsPerPage={limit}
      page={offset - 1}
      SelectProps={{
        inputProps: { 'aria-label': 'Filas por página' },
        // native: true,
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
