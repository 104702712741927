import { useEffect } from 'react';
import {
  Box,
  Table,
  Typography,
  Chip,
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../../../../../../app/store';
import {
  useTaxesSelector,
  resetTaxpayerTaxes,
  fetchTaxpayerTaxes,
} from '../../../../../../../../../../features/taxes/taxesSlice';
import { useUISelector } from '../../../../../../../../../../features/UI/uiSlice';
// context
import { useSnackbar } from '../../../../../../../../../../context/SnackbarContext';
// interface
import { ITax } from '../../../../../../../../../../interfaces';
// components
import { LoadingCollapseTable } from '../../../../../../../common/CollapseTable';
import { ErrorCollapseTable } from '../../../../../../../common/CollapseTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      //justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
);

interface TaxesContentProps {
  taxpayerUuid: string;
  isItemCollapsed: boolean;
}

export default function TaxesContent({
  taxpayerUuid,
  isItemCollapsed,
}: TaxesContentProps) {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const { collapsed } = useUISelector((state) => state.ui);

  const { taxpayerTaxes, loading, error } = useTaxesSelector(
    (state) => state.taxes
  );

  const loadTaxpayerTaxes = async () => {
    try {
      await dispatch(fetchTaxpayerTaxes(taxpayerUuid));
    } catch (err: any) {
      errorSnackbar();
    }
  };

  useEffect(() => {
    if (isItemCollapsed) {
      dispatch(resetTaxpayerTaxes());
      loadTaxpayerTaxes();
    }
  }, [collapsed]);

  return (
    <Box pt={6} pb={5}>
      {loading ? (
        <Table size="small">
          <LoadingCollapseTable isItemCollapsed={isItemCollapsed} />
        </Table>
      ) : (
        <>
          <Typography variant="subtitle1">
            <Box pb={2} fontWeight="fontWeightBold">
              {'Impuestos:'}
            </Box>
          </Typography>
          {error ? (
            <ErrorCollapseTable />
          ) : taxpayerTaxes.length === 0 && !loading ? (
            <Typography variant="subtitle1">
              <Box pt={1} pb={0}>
                {
                  '- El contribuyente no tiene impuestos asignados hasta el momento.'
                }
              </Box>
            </Typography>
          ) : (
            <div className={classes.root}>
              {taxpayerTaxes.map((taxe: ITax | any) => (
                <Chip label={taxe.name} />
              ))}
            </div>
          )}
        </>
      )}
    </Box>
  );
}
