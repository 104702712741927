import React, { useState, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { Dialog, FormControl, TextField, Button, Box } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  fetchTaxes,
  useTaxesSelector,
  updateTaxName,
  fetchTaxById,
} from '../../../../../features/taxes/taxesSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// interface
import { ITax, ITaxName } from '../../../../../interfaces';
// constants
import { MAX_LEN_EXCEL, MIN_LEN_EXCEL } from '../../../../../constants';
// utils
import { capitalize, removeExtraSpace } from '../../../../../utils';
// hooks
import { useIsMobile } from '../../../../../hooks/useIsMobile';
// components
import {
  FormHeader,
  FormBody,
  FormFooter,
  StyledGrid,
} from '../../common/Forms';

interface TaxNameUpdateDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TaxNameUpdateDialog({
  openDialog,
  setOpenDialog,
}: TaxNameUpdateDialogProps) {
  const { isMobile } = useIsMobile();

  const dispatch = useAppDispatch();

  const {
    dispatch: { setSnackbar, errorSnackbar },
  } = useSnackbar();

  const {
    tax,
    actionLoading,
    offset,
    limit,
    filter,
    sort_column,
    sort_direction,
  } = useTaxesSelector((state) => state.taxes);

  const [helperText, setHelperText] = useState('');

  const validationSchema = Yup.object().shape({
    tax_name: Yup.string()
      .required(`Requerido!`)
      .min(MIN_LEN_EXCEL, `Al menos ${MIN_LEN_EXCEL} caracteres.`)
      .max(MAX_LEN_EXCEL, `Maximo ${MAX_LEN_EXCEL} caracteres.`),
  });

  const handleFetchTaxById = async (tax: ITax) => {
    try {
      const resultAction = await dispatch(fetchTaxById(tax.uuid));
      const payload = unwrapResult(resultAction);
      formik.setValues({
        tax_name: payload.name,
      });
    } catch (err: any) {
      errorSnackbar();
    }
  };

  useEffect(() => {
    if (tax && openDialog) {
      handleFetchTaxById(tax);
    }
  }, [openDialog]);

  const handleSubmit = async (taxData: ITaxName) => {
    if (tax) {
      const taxUuid = tax.uuid;
      try {
        const resultAction = await dispatch(
          updateTaxName({ taxUuid, taxData })
        );
        const res = unwrapResult(resultAction);
        setSnackbar(true, res.message);
        dispatch(
          fetchTaxes({
            offset,
            limit,
            filter,
            sort_column,
            sort_direction,
          })
        );
        handleClose();
      } catch (err: any) {
        setHelperText(`❌ ${err.message}`);
        errorSnackbar();
      }
    }
  };

  const initialValues: ITaxName = {
    tax_name: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const handleResetError = () => {
    setHelperText('');
    formik.setErrors({});
  };

  const handleClose = () => {
    formik.setValues(formik.initialValues);
    setOpenDialog(false);
    handleResetError();
  };

  return (
    <Dialog
      open={openDialog}
      keepMounted
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="simple-dialog"
    >
      <FormControl component="fieldset" error={!!helperText}>
        <form onSubmit={formik.handleSubmit}>
          <FormHeader handleClose={handleClose}>
            {`Actualizar nombre del impuesto 🧾`}
          </FormHeader>
          <FormBody loading={actionLoading} helperText={helperText}>
            <StyledGrid item xs={12} sm={12}>
              <Box pt={5} px={4}>
                <TextField
                  id="name"
                  name="name"
                  label="Nombre *"
                  variant="outlined"
                  size="small"
                  value={formik.values.tax_name}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'tax_name',
                      removeExtraSpace(capitalize(e.target.value))
                    );
                    handleResetError();
                  }}
                  autoComplete="off"
                  style={{ width: isMobile ? 230 : 400 }}
                  inputProps={{
                    maxLength: MAX_LEN_EXCEL,
                  }}
                  helperText={
                    formik.errors.tax_name
                      ? formik.errors.tax_name
                      : 'Máximo 31 caracteres.'
                  }
                  error={!!formik.errors.tax_name}
                />
              </Box>
            </StyledGrid>
          </FormBody>
          <FormFooter>
            <Button autoFocus type="submit" color="primary" variant="contained">
              {'Confirmar'}
            </Button>
          </FormFooter>
        </form>
      </FormControl>
    </Dialog>
  );
}
