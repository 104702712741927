import React from 'react';
import { TableSortLabel, Box } from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../../../../app/store';
import {
  selectTax,
  useTaxesSelector,
} from '../../../../../../../../features/taxes/taxesSlice';
// interface
import { IColumn, ITax } from '../../../../../../../../interfaces';
// children components
import {
  StyledTableCellHeader,
  CustomSpan,
  TableBodyHeader,
} from '../../../../../common/Table/Body/content';

const SORTABLE_COLUMNS = ['name'];

interface TaxTableBodyHeaderProps {
  onRequestSort: (e: React.MouseEvent<unknown>, property: keyof ITax) => void;
  columns: IColumn<ITax>[];
  isCollapse: boolean;
}

export default function TaxTableBodyHeader({
  onRequestSort,
  columns,
  isCollapse,
}: TaxTableBodyHeaderProps) {
  const dispatch = useAppDispatch();

  const { tax, sort_column, sort_direction } = useTaxesSelector(
    (state) => state.taxes
  );

  const createSortHandler =
    (property: keyof ITax) => (e: React.MouseEvent<unknown>) => {
      onRequestSort(e, property);
    };

  const handleDeselect = () => {
    dispatch(selectTax(null));
  };

  return (
    <TableBodyHeader
      isCollapse={isCollapse}
      isItemSelected={tax ? true : false}
      handleDeselect={handleDeselect}
    >
      {columns.map((column) => (
        <StyledTableCellHeader
          key={column.id}
          align={column.align}
          sortDirection={sort_column === column.id ? sort_direction : false}
        >
          {SORTABLE_COLUMNS.includes(column.id) ? (
            <TableSortLabel
              active={sort_column === column.id}
              direction={sort_column === column.id ? sort_direction : 'asc'}
              onClick={createSortHandler(column.id)}
            >
              <Box fontWeight="fontWeightBold">
                {column.label}
                {sort_column === column.id ? (
                  <CustomSpan>
                    {sort_direction === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </CustomSpan>
                ) : null}
              </Box>
            </TableSortLabel>
          ) : (
            <Box fontWeight="fontWeightBold">{column.label}</Box>
          )}
        </StyledTableCellHeader>
      ))}
    </TableBodyHeader>
  );
}
