// components
import { MainView } from '../common';
import ClientsTable from '../components/Dashboard/MainViews/Tables/Clients/Clients';

export default function Clients() {
  return (
    <MainView>
      <ClientsTable />
    </MainView>
  );
}
