export const formatSize = (value: string | number) => {
  if (value && typeof value === 'string') {
    let formatedValue: number;
    const numberValue = parseFloat(value);
    if (numberValue > 1000000000) {
      formatedValue = parseFloat(value) / 1024 / 1024 / 1024;
      value = `${formatedValue.toFixed(2)} GB`;
    } else if (numberValue > 1000000) {
      formatedValue = parseFloat(value) / 1024 / 1024;
      value = `${formatedValue.toFixed(2)} MB`;
    } else {
      formatedValue = parseFloat(value) / 1024;
      value = `${formatedValue.toFixed(2)} kB`;
    }
  } else {
    value = '-';
  }
  return value;
};
