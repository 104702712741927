import { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Grid,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
// global state
import { useTaxpayersSelector } from '../../../../../../../features/taxpayers/taxpayersSlice';
// components
import { TableHeader } from '../../../../common/Table/Header';
import AddTaxpayerButton from './content/AddTaxpayerButton';
import EditTaxpayerButton from './content/EditTaxpayerButton';
import DeleteTaxpayerButton from './content/DeleteTaxpayerButton';
import ShowDisableTaxpayersButton from './content/ShowDisableTaxpayersButton';
import SearchFieldTaxpayer from './content/SearchFieldTaxpayer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingInline: theme.spacing(1),
    },
    head: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  })
);

export default function TaxpayerTableHeader() {
  const classes = useStyles();

  const { taxpayer } = useTaxpayersSelector((state) => state.taxpayers);

  const [title, setTitle] = useState('');

  useEffect(() => {
    if (taxpayer) {
      setTitle(`${taxpayer.company_name}`);
    }
  }, [taxpayer]);

  return (
    <TableHeader title={title} isItemSelected={!!taxpayer}>
      <>
        <Grid
          container
          className={classes.container}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12} sm={2} className={classes.container}>
            <Typography variant="h5"> {'Contribuyentes'}</Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.container}>
            {/* <ShowDisableTaxpayersButton /> */}
          </Grid>
          <Grid item xs={12} sm={3} className={classes.container}>
            <Box display="flex" justifyContent="flex-end">
              <AddTaxpayerButton />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.container}>
            <SearchFieldTaxpayer />
          </Grid>
        </Grid>
      </>

      <>
        <Grid
          container
          className={classes.container}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box className={classes.head}>
            <EditTaxpayerButton />
            <DeleteTaxpayerButton />
          </Box>
        </Grid>
      </>
    </TableHeader>
  );
}
