import { useEffect } from 'react';
import { Paper } from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  fetchTaxpayers,
  useTaxpayersSelector,
  selectTaxpayer,
} from '../../../../../features/taxpayers/taxpayersSlice';
import {
  fetchTaxpayerTypes,
  useTaxpayerTypesSelector,
} from '../../../../../features/taxpayers/taxpayerTypesSlice';
import { fetchTaxes } from '../../../../../features/taxes/taxesSlice';
import { setCollapsed } from '../../../../../features/UI/uiSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// common
import { Error } from '../../../../../common';
// children components
import TaxpayerTableHeader from './content/Header/TaxpayerTableHeader';
import TaxpayerTableBody from './content/Body/TaxpayerTableBody';
import TaxpayerTableFooter from './content/Footer/TaxpayerTableFooter';

export default function ClientsView() {
  const dispatch = useAppDispatch();

  const {
    error,
    offset,
    limit,
    month,
    filter,
    showDisabled,
    sort_column,
    sort_direction,
  } = useTaxpayersSelector((state) => state.taxpayers);

  const { taxpayerTypes } = useTaxpayerTypesSelector(
    (state) => state.taxpayerTypes
  );

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  useEffect(() => {
    dispatch(selectTaxpayer(null));
    dispatch(setCollapsed(null));
  }, [showDisabled, offset, limit, month]);

  useEffect(() => {
    try {
      dispatch(
        fetchTaxpayers({
          offset,
          limit,
          month,
          filter,
          showDisabled,
          sort_column,
          sort_direction,
        })
      );
      dispatch(selectTaxpayer(null));
      dispatch(fetchTaxes({}));
      if (taxpayerTypes.length === 0) {
        dispatch(fetchTaxpayerTypes());
      }
    } catch (err) {
      errorSnackbar();
    }
    // filter won't goes here cause it's handled by a debounce function in SearchField component
    // month won't goes here either, cause search it's also handled in it's own component MonthsMenu
  }, [showDisabled, offset, limit, sort_column, sort_direction]);

  if (error) return <Error />;

  return (
    <Paper>
      <TaxpayerTableHeader />
      <TaxpayerTableBody />
      <TaxpayerTableFooter />
    </Paper>
  );
}
