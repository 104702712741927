import React, { useEffect } from 'react';
import { Checkbox, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FormikErrors } from 'formik';
// global state
import { useTaxesSelector } from '../../../../../../features/taxes/taxesSlice';
import { useTaxpayersSelector } from '../../../../../../features/taxpayers/taxpayersSlice';
// api
import { fetchTaxpayerTaxes } from '../../../../../../api/tax.services';
// context
import { useSnackbar } from '../../../../../../context/SnackbarContext';
// interface
import { ITaxpayerForm, ITax } from '../../../../../../interfaces';
// hooks
import { useIsMobile } from '../../../../../../hooks/useIsMobile';

interface ClientAutocompleteProps {
  taxesUuidErrors: string | string[] | undefined;
  formikSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<ITaxpayerForm>>;
  handleResetError: () => void;
  setSelectedTaxes: React.Dispatch<React.SetStateAction<ITax[]>>;
  selectedTaxes: ITax[];
  openDialog: boolean;
}

export default function ClientAutocomplete({
  taxesUuidErrors,
  handleResetError,
  formikSetFieldValue,
  setSelectedTaxes,
  selectedTaxes,
  openDialog,
}: ClientAutocompleteProps) {
  const { isMobile } = useIsMobile();

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const { taxes } = useTaxesSelector((state) => state.taxes);

  const { taxpayer } = useTaxpayersSelector((state) => state.taxpayers);

  const handleFetchTaxpayerTaxes = async () => {
    if (taxpayer) {
      try {
        const taxes = await fetchTaxpayerTaxes(taxpayer.uuid);
        setSelectedTaxes(taxes);
      } catch (err: any) {
        errorSnackbar();
      }
    }
  };

  useEffect(() => {
    if (openDialog && taxpayer) {
      handleFetchTaxpayerTaxes();
    }
  }, [openDialog]);

  const handleTaxesAutocompleteChange = (
    e: React.ChangeEvent<{}>,
    data: ITax[],
    reason: string,
    details?: any | undefined
  ) => {
    if (reason === 'select-option') {
      // remove duplicates
      const sentUuid = details.option.uuid;
      if (!!selectedTaxes.find((user) => user.uuid === sentUuid)) {
        data = data.filter((row) => row.uuid !== sentUuid);
      }
    }
    if (data) {
      // update hook state
      setSelectedTaxes(data);
      // update formik state
      const taxesUuid = data.map((row) => row.uuid);
      formikSetFieldValue('taxes_uuid', taxesUuid);
    }
    handleResetError();
  };

  return (
    <Autocomplete
      multiple
      size="small"
      limitTags={8}
      options={taxes}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      value={selectedTaxes}
      onChange={handleTaxesAutocompleteChange}
      noOptionsText="Sin resultados"
      renderOption={(option) => (
        <React.Fragment>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={!!selectedTaxes.find((row) => option.uuid === row.uuid)}
          />
          {option.name}
        </React.Fragment>
      )}
      style={{ width: isMobile ? 230 : 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Impuestos"
          helperText={taxesUuidErrors ? taxesUuidErrors : ' '}
          error={!!taxesUuidErrors}
        />
      )}
    />
  );
}
