import { makeStyles, Theme, Box } from '@material-ui/core';
// global state
import { useMyUserSelector } from '../../../../../features/auth/myUserSlice';
// common
import { Error } from '../../../../../common';
// components
import { SimpleTable } from '../../common/SimpleTable';
// children components
import Title from './content/Title';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  typography: {
    display: 'inline-block',
    paddingLeft: '10px',
  },
  image: {
    filter:
      'invert(80%) sepia(0%) saturate(3261%) hue-rotate(257deg) brightness(91%) contrast(92%)',
  },
}));

export default function Home() {
  const classes = useStyles();

  const { error } = useMyUserSelector((state) => state.myUser);

  if (error) return <Error />;

  return (
    <SimpleTable>
      <Box m={2}>
        <Title classes={classes} />
      </Box>
    </SimpleTable>
  );
}
