// global state
import { useRolesSelector } from '../features/auth/rolesSlice';
// constants
import { ADMIN_ROLE, CLIENT_ROLE } from '../constants';

export const useRoles = () => {
  const { roles } = useRolesSelector((state) => state.roles);

  /**
   * @returns index roles name
   */
  const indexRoleName = (id: number) => {
    if (roles) {
      const obj = roles.find((role) => role.id === id)!;
      if (obj) return obj.name;
    }
    return '';
  };

  /**
   * @returns translate roles name
   */
  const translateRoleName = (name: string) => {
    switch (name) {
      case ADMIN_ROLE:
        return 'Admin';
      case CLIENT_ROLE:
        return 'Cliente';
      default:
        return '';
    }
  };

  /**
   * @returns roles id
   */
  const getRoleID = (name: string) => {
    if (roles) {
      const obj = roles.find((role) => role.name === name);
      if (obj) return obj.id;
    }
    return -1;
  };

  return {
    indexRoleName,
    translateRoleName,
    getRoleID,
  };
};
