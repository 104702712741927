import { API, API_BUFFER } from '../utils';
import { IDrive, IFile } from '../interfaces';

/**
 * list all google drive items
 */
export const fetchDriveItems = async (
  nextPageToken: string | null,
  limit: number,
  filter: string,
  folder_id: string,
  sort_column: string,
  sort_direction: string
): Promise<IDrive> => {
  return API.get<IDrive>(
    `conf/list-items?nextPageToken=${nextPageToken}&limit=${limit}&filter=${filter}&folder_id=${folder_id}&sort_column=${sort_column}&sort_direction=${sort_direction}`
  );
};

/**
 * get google drive item by id
 */
export const fetchDriveItemById = async (fileId: string): Promise<IFile> => {
  return API.get<IFile>(`conf/get-item?fileId=${fileId}`);
};

/**
 * list google drive folders
 */
export const fetchDriveFolders = async (
  nextPageToken: string | null,
  filter: string,
  folder_id: string
): Promise<IDrive> => {
  return API.get<IDrive>(
    `conf/list-folders?nextPageToken=${nextPageToken}&filter=${filter}&folder_id=${folder_id}`
  );
};

/**
 * get google drive file
 */
export const getFile = async (
  fileId: string,
  mimeType: string,
  name: string
): Promise<ArrayBuffer> => {
  return API_BUFFER.get<ArrayBuffer>(
    `conf/get-file?fileId=${fileId}&mimeType=${mimeType}&fileName=${name}`
  );
};
