import React, { useState } from 'react';
// global state
import { useAppDispatch } from '../../../../../../../app/store';
import {
  useTaxpayersSelector,
  setSortColumn,
  setSortDirection,
} from '../../../../../../../features/taxpayers/taxpayersSlice';
// interface
import { IColumn, ITaxpayer } from '../../../../../../../interfaces';
// utils
import {
  formatDate,
  formatCuit,
  getMonthName,
  isEmployer,
} from '../../../../../../../utils';
// hooks
import { useTaxpayerTypes } from '../../../../../../../hooks/useTaxpayerTypes';
// components
import { TableBody } from '../../../../common/Table/Body';
// children components
import TaxpayerTableBodyHeader from './content/TaxpayerTableBodyHeader';
import TaxpayerTableBodyContainer from './content/TaxpayerTableBodyContainer';
import { AvatarAndName } from '../../../../common/Utils';
import EmployerCheckMark from './EmployerCheckMark';

export default function TaxpayerTableBody() {
  // this array is declared inside the component because we are using hooks there
  const columns: IColumn<ITaxpayer>[] = [
    {
      id: 'company_name',
      label: 'Razón Social',
      minWidth: 200,
      align: 'left',
      render: (row) => <AvatarAndName row={row} />,
    },
    {
      id: 'cuit',
      label: 'CUIT',
      minWidth: 150,
      align: 'right',
      render: (row) => formatCuit(row.cuit),
    },
    {
      id: 'taxpayer_type_id',
      label: 'Tipo',
      minWidth: 100,
      align: 'right',
      render: (row) => indexTaxpayerType(row.taxpayer_type_id),
    },
    {
      id: 'employer',
      label: 'Empleador',
      minWidth: 100,
      align: 'right',
      render: (row) => <EmployerCheckMark row={row} />,
      //render: (row) => isEmployer(row.employer),
    },
    {
      id: 'financial_year_close',
      label: 'Mes cierre ejer.',
      minWidth: 100,
      align: 'right',
      render: (row) => getMonthName(row.financial_year_close),
    },
    {
      id: 'manager',
      label: 'Asesor',
      minWidth: 100,
      align: 'right',
    },
    {
      id: 'enabled_at',
      label: 'Fecha de alta',
      minWidth: 100,
      align: 'right',
      render: (row) => formatDate(row.enabled_at),
    },
    // THIS FEATURE BELLOW WON'T BE USED FOR NOW. MAYBE LATER.
    // { id: 'disabled_at', label: 'Fecha de baja', minWidth: 100, align: 'right' },
    // { id: 'enabled', label: 'Situación', minWidth: 100, align: 'right' },
  ];

  const dispatch = useAppDispatch();

  const { indexTaxpayerType } = useTaxpayerTypes();

  const { loading, sort_column, sort_direction } = useTaxpayersSelector(
    (state) => state.taxpayers
  );

  const [isCollapse] = useState<boolean>(true);

  const handleRequestSort = (
    e: React.MouseEvent<unknown>,
    property: keyof ITaxpayer
  ) => {
    // setting sort direction
    const isAsc = sort_column === property && sort_direction === 'asc';
    dispatch(setSortDirection(isAsc ? 'desc' : 'asc'));
    // setting sort column
    dispatch(setSortColumn(property));
  };

  return (
    <TableBody loading={loading}>
      <TaxpayerTableBodyHeader
        onRequestSort={handleRequestSort}
        columns={columns}
        isCollapse={isCollapse}
      />
      <TaxpayerTableBodyContainer columns={columns} isCollapse={isCollapse} />
    </TableBody>
  );
}
