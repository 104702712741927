import React from 'react';
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Paper,
} from '@material-ui/core';
// themes
import lightTheme from '../themes/light';

export default function Layout(props: any): JSX.Element {
  return (
    <ThemeProvider theme={createTheme(lightTheme)}>
      <Paper style={{ height: '100vh' /* max-content */ }}>
        <CssBaseline />
        <main>{props.children}</main>
      </Paper>
    </ThemeProvider>
  );
}
