import React from 'react';
// global state
import { useAppDispatch } from '../../../../../../../../../app/store';
import {
  selectTax,
  useTaxesSelector,
} from '../../../../../../../../../features/taxes/taxesSlice';
import {
  useUISelector,
  setCollapsed,
} from '../../../../../../../../../features/UI/uiSlice';
// interface
import { IColumn, ITax } from '../../../../../../../../../interfaces';
// utils
import { formatNulls } from '../../../../../../../../../utils';
// components
import {
  StyledTableCell,
  TableContent,
} from '../../../../../../common/Table/Body/content/TableContent';

interface TaxTableContentProps {
  row: ITax;
  labelId: string;
  columns: IColumn<ITax>[];
  isCollapse: boolean;
}

export default function TaxTableContent({
  row,
  labelId,
  columns,
  isCollapse,
}: TaxTableContentProps) {
  const dispatch = useAppDispatch();

  const { tax } = useTaxesSelector((state) => state.taxes);

  const { collapsed } = useUISelector((state) => state.ui);

  const handleClick = (e: React.MouseEvent<unknown>, row: any) => {
    let newSelected: ITax | null = null;
    if (tax === row) {
      // close collapse
      dispatch(setCollapsed(null));
    } else {
      newSelected = row;
      // close collapse
      if (collapsed !== row.uuid) {
        dispatch(setCollapsed(null));
      }
    }
    dispatch(selectTax(newSelected));
  };

  const toggleCollapse = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    dispatch(setCollapsed(row.uuid));
    // deselect item if needed
    if (tax) {
      if (tax.uuid !== row.uuid) {
        dispatch(selectTax(null));
      }
    }
  };

  const toTax = (column: IColumn<ITax>) => {
    return (
      <StyledTableCell
        key={column.id}
        align={column.align}
        style={{ minWidth: column.minWidth }}
      >
        {typeof column?.render === 'function'
          ? column.render(row)
          : formatNulls(row[column.id])}
      </StyledTableCell>
    );
  };

  return (
    <>
      <TableContent
        row={row}
        labelId={labelId}
        isCollapse={isCollapse}
        selectedItem={tax}
        handleClick={handleClick}
        toggleCollapse={toggleCollapse}
      >
        {columns.map(toTax)}
      </TableContent>
    </>
  );
}
