import {
  createStyles,
  Theme,
  withStyles,
  TableCell,
  lighten,
} from '@material-ui/core';

const StyledTableCellHeader = withStyles((theme: Theme) =>
  createStyles({
    head: {
      /* backgroundColor: lighten(theme.palette.primary.light, 0.8), */
      color: theme.palette.text.primary,
      paddingLeft: '7px',
      padding: '14px',
    },
  })
)(TableCell);

export default StyledTableCellHeader;
