import {
  makeStyles,
  Theme,
  Paper,
  Table,
  TableContainer,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    // height: '81.4vh',
    [theme.breakpoints.up('xs')]: {
      height: '75vh',
    },
    [theme.breakpoints.up('sm')]: {
      height: '75vh',
    },
    [theme.breakpoints.up('md')]: {
      height: '75vh',
    },
    [theme.breakpoints.up('lg')]: {
      height: '82vh',
    },
    [theme.breakpoints.up('xl')]: {
      height: '88vh',
    },
  },
}));

interface SimpleTableProps {
  children: React.ReactNode;
}

export default function SimpleTable({ children }: SimpleTableProps) {
  const classes = useStyles();

  return (
    <Paper>
      <TableContainer className={classes.tableContainer}>
        <Table>{children}</Table>
      </TableContainer>
    </Paper>
  );
}
