import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { IRole } from '../../interfaces';
import * as authSvcs from '../../api/auth.services';

// Interfaces
interface IRolesState {
  loading: boolean;
  error: boolean | string;
  roles: IRole[];
}

// Initial state
const initialState: IRolesState = {
  loading: false,
  error: false,
  roles: [],
};

// Asynchronous thunk actions
export const fetchRoles = createAsyncThunk('roles/fetchRoles', () => {
  return authSvcs.fetchRoles();
});

// A slice for roles with our three reducers
const roles = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRoles.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.roles = action.payload;
    });
    builder.addCase(fetchRoles.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
  },
});

// A selector
export const useRolesSelector: TypedUseSelectorHook<RootState> = useSelector;

// The reducer
export default roles.reducer;
