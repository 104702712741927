import React from 'react';
// components
import { DeleteButton } from '../../../../../common/Table/Header/Buttons';
// children components
import TaxDeleteDialog from '../../../../../Dialogs/Taxes/TaxDeleteDialog';

export default function DeleteTaxButton() {
  const [openDialog, setOpenDialog] = React.useState(false);

  return (
    <DeleteButton setOpenDialog={setOpenDialog}>
      <TaxDeleteDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
    </DeleteButton>
  );
}
