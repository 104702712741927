import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      backgroundColor: theme.palette.error.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.error.light,
      },
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

interface ToggleStatusButtonProps {
  enabled: boolean;
  children: React.ReactNode;
  handleClick: (e: React.MouseEvent<unknown>) => void;
}

export default function ToggleStatusButton({
  enabled,
  children,
  handleClick,
}: ToggleStatusButtonProps) {
  const classes = useStyles();

  return (
    <>
      <Button
        size="small"
        onClick={handleClick}
        className={enabled ? classes.success : classes.error}
      >
        {enabled ? 'Activo' : 'Inactivo'}
      </Button>
      {children}
    </>
  );
}
