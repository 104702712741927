import { useCallback, ChangeEvent } from 'react';
import debounce from 'lodash.debounce';
// global state
import { useAppDispatch } from '../../../../../../../../app/store';
import {
  useTaxpayersSelector,
  fetchTaxpayers,
  setFilter,
  setMonth,
} from '../../../../../../../../features/taxpayers/taxpayersSlice';
import { setCollapsed } from '../../../../../../../../features/UI/uiSlice';
// types
import { Month } from '../../../../../../../../types';
// context
import { useSnackbar } from '../../../../../../../../context/SnackbarContext';
// components
import { SearchField } from '../../../../../common/Table/Header';

export default function TaxpayerTableHeader() {
  const dispatch = useAppDispatch();

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const {
    offset,
    limit,
    month,
    filter,
    showDisabled,
    sort_column,
    sort_direction,
  } = useTaxpayersSelector((state) => state.taxpayers);

  const handleFetchTaxpayers = async (filter: string, month: Month) => {
    try {
      await dispatch(
        fetchTaxpayers({
          offset,
          limit,
          month,
          filter,
          showDisabled,
          sort_column,
          sort_direction,
        })
      );
    } catch (err: any) {
      errorSnackbar();
    }
  };

  const debounceTaxpayers = useCallback(
    debounce(handleFetchTaxpayers, 300),
    []
  );

  const handleSetFilter = async (filter: string, month: Month) => {
    dispatch(setFilter(filter));
    debounceTaxpayers(filter, month);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filter = e.currentTarget.value;
    handleSetFilter(filter, month);
  };

  const handleClickClear = () => {
    if (filter) {
      handleSetFilter('', null);
      dispatch(setCollapsed(null));
    }
    if (month) {
      dispatch(setMonth(null));
    }
  };

  return (
    <SearchField
      handleChange={handleChange}
      handleClickClear={handleClickClear}
      filter={filter}
    />
  );
}
