import React from 'react';
import { Checkbox, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// global state
import { useTaxpayersSelector } from '../../../../../../features/taxpayers/taxpayersSlice';
// interface
import { ITaxpayer } from '../../../../../../interfaces';
// hooks
import { useIsMobile } from '../../../../../../hooks/useIsMobile';

interface TaxpayersAutocompleteProps {
  handleResetError: () => void;
  setSelectedTaxpayers: React.Dispatch<React.SetStateAction<ITaxpayer[]>>;
  selectedTaxpayers: ITaxpayer[];
  isAllUsers: boolean;
  usersUuid: string[];
}

export default function TaxpayersAutocomplete({
  handleResetError,
  setSelectedTaxpayers,
  selectedTaxpayers,
  isAllUsers,
  usersUuid,
}: TaxpayersAutocompleteProps) {
  const { isMobile } = useIsMobile();

  const { taxpayers } = useTaxpayersSelector((state) => state.taxpayers);

  const handleTaxpayerAutocompleteInputChange = (
    e: React.ChangeEvent<{}>,
    filter: string | null,
    reason: string
  ) => {
    if (reason === 'clear') {
      filter = '';
    }
    if (typeof filter === 'string') {
      handleResetError();
    }
  };

  const handleTaxpayerAutocompleteChange = (
    e: React.ChangeEvent<{}>,
    data: ITaxpayer[],
    reason: string,
    details?: any | undefined
  ) => {
    if (reason === 'select-option') {
      // remove duplicates
      const sentUuid = details.option.uuid;
      if (!!selectedTaxpayers.find((taxpayer) => taxpayer.uuid === sentUuid)) {
        data = data.filter((row) => row.uuid !== sentUuid);
      }
    }
    if (data && usersUuid.length === 1) {
      // update hook state
      setSelectedTaxpayers(data);
    }
    handleResetError();
  };

  return (
    <Autocomplete
      multiple
      size="small"
      limitTags={8}
      disabled={isAllUsers || usersUuid.length !== 1}
      options={taxpayers}
      getOptionLabel={(option) => option.company_name}
      value={selectedTaxpayers}
      onChange={handleTaxpayerAutocompleteChange}
      onInputChange={handleTaxpayerAutocompleteInputChange}
      noOptionsText="Sin resultados"
      renderOption={(option) => (
        <React.Fragment>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={
              !!selectedTaxpayers.find((row) => option.uuid === row.uuid)
            }
          />
          {option.company_name}
        </React.Fragment>
      )}
      style={{ width: isMobile ? 230 : 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Contribuyentes referidos"
          helperText={
            usersUuid.length !== 1
              ? 'Solo puede referir contribuyentes cuando la notificación va dirigida a un solo usuario'
              : 'Seleccione contribuyentes a referir'
          }
        />
      )}
    />
  );
}
