import { API, API_BUFFER } from '../utils';
import { IResponse } from '../interfaces';

/**
 * upload excel
 */
export const uploadExcel = async (
  excelData: FormData,
  year: string
): Promise<IResponse> => {
  return API.post(`conf/upload-excel?year=${year}`, excelData);
};

/**
 * get google drive file
 */
export const downloadExcel = async (): Promise<ArrayBuffer> => {
  return API_BUFFER.get<ArrayBuffer>(`conf/download-excel`);
};
