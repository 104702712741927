// global state
import { useUISelector } from '../../../../../../../../../../features/UI/uiSlice';
// interface
import { ITaxpayer } from '../../../../../../../../../../interfaces';
// components
import { CollapseContent } from '../../../../../../../common/Table/Body/content/TableContent/Collapse';
import UsersContent from './UsersContent';
import TaxesContent from './TaxesContent';

interface TaxpayerCollapseContentProps {
  row: ITaxpayer;
}

export default function TaxpayerCollapseContent({
  row,
}: TaxpayerCollapseContentProps) {
  const { collapsed } = useUISelector((state) => state.ui);

  const isCollapsed = (row: ITaxpayer) => collapsed === row.uuid;
  const isItemCollapsed = isCollapsed(row);

  return (
    <CollapseContent isItemCollapsed={isItemCollapsed}>
      <UsersContent taxpayerUuid={row.uuid} isItemCollapsed={isItemCollapsed} />
      <TaxesContent taxpayerUuid={row.uuid} isItemCollapsed={isItemCollapsed} />
    </CollapseContent>
  );
}
