import { MenuOptions, DriveMenuOptions } from '../types';

/**
 * route names
 */
export const LOGIN_ROUTE = '/login';

export const PROFILE_ROUTE = '/profile';

export const HOME_ROUTE = '/home';

export const CLIENTS_ROUTE = '/clients';

export const TAXPAYERS_ROUTE = '/taxpayers';

export const DIRECTORY_ROUTE = '/directory';

export const TAXES_ROUTE = '/taxes';

export const NOTIFICATIONS_ROUTE = '/notifications';

export const RESET_PSSWD_ROUTE = '/reset-password/:email/:oneTimeToken';

/**
 * UI
 */
export const DRAWER_WIDTH = 260;

export const DEF_LIMIT = 150;

export const DEF_OFFSET = 1;

/**
 * roles name
 */
export const ADMIN_ROLE = 'admin';

export const CLIENT_ROLE = 'client';

/**
 * taxpayer types name
 */
export const PERSONA_JURIDICA = 'Persona Jurídica';

export const PERSONA_FISICA = 'Persona Física';

export const MONOTRIBUTISTA = 'Monotributista';

/**
 * google drive api
 */
export const ROOT_FOLDER_NAME = 'Liber';

/**
 * validation lengths
 */
export const MIN_LEN_PSSWD = 5;

export const MAX_LEN_PSSWD = 50;

export const MIN_LEN_EMAIL = 10;

export const MAX_LEN_EMAIL = 50;

export const MIN_LEN_NAME = 2;

export const MAX_LEN_NAME = 50;

export const MIN_LEN_ADDRESS = 2;

export const MAX_LEN_ADDRESS = 50;

export const MIN_LEN_CUIT = 11;

export const MAX_LEN_CUIT = 14;

export const MIN_LEN_PHONE = 10;

export const MAX_LEN_PHONE = 12;

export const MIN_LEN_COMP_NAME = 2;

export const MAX_LEN_COMP_NAME = 80;

export const YEAR_LENGTH = 4;

export const MAX_LEN_EXCEL = 31;

export const MIN_LEN_EXCEL = 2;

export const MAX_LEN_REASON = 100;

export const MIN_LEN_NOTIFICATION = 5;

export const MAX_LEN_NOTIFICATION = 250;

export const MIN_LEN_TITLE = 3;

export const MAX_LEN_TITLE = 50;

/**
 * reasons for disable
 */
export const REASONS_FOR_DISABLE = ['Baja cliente', 'Liquidación cliente'];

/**
 * formats
 */
export const IMAGE_FORMAT = /\.(jpg|jpeg|png|gif)$/;

export const EXCEL_FORMAT = /\.(xls|xlsx)$/;

/**
 * mimeTypes
 */
export const FOLDER = ['folder'];

export const IMAGE = ['image'];

export const RAR = ['rar', 'zip', 'gzip'];

export const TXT = ['text/plain'];

export const PDF = ['pdf'];

export const GOOGLE_WORKSPACE = 'application/vnd.google-apps';

export const DOCUMENT = [
  'officedocument.wordprocessingml.document',
  'google-apps.document',
  'msword',
];

export const SPREADSHEET = [
  'officedocument.spreadsheetml.sheet',
  'google-apps.spreadsheet',
  'ms-excel',
];

export const PRESENTATION = [
  'officedocument.presentationml.presentation',
  'google-apps.presentation',
  'ms-powerpoint',
];

/**
 *  menu options
 */
export const UPDATE: MenuOptions = 'update';

export const DELETE: MenuOptions = 'delete';

export const RESTORE_PSSWD: MenuOptions = 'restorePassword';

/**
 * drive menu options
 */
export const LINK_FOLDER: DriveMenuOptions = 'linkFolder';

export const DOWNLOAD_FILE: DriveMenuOptions = 'downloadFile';

/**
 * excel
 */
export const EXCEL_TITLE = 'LiberApp_calendario-vencimientos.xlsx';
