import { TableBody } from '@material-ui/core';
// global state
import { useTaxpayersSelector } from '../../../../../../../../features/taxpayers/taxpayersSlice';
// interfaces
import { IColumn, ITaxpayer } from '../../../../../../../../interfaces';
// children components
import TaxpayerTableContent from './TableContent/TaxpayerTableContent';

interface TaxpayerTableBodyContainerProps {
  columns: IColumn<ITaxpayer>[];
  isCollapse: boolean;
}

export default function TaxpayerTableBodyContainer({
  columns,
  isCollapse,
}: TaxpayerTableBodyContainerProps) {
  const { taxpayers } = useTaxpayersSelector((state) => state.taxpayers);

  return (
    <TableBody>
      {taxpayers.map((row) => {
        return (
          <TaxpayerTableContent
            row={row}
            labelId={row.uuid}
            key={row.uuid}
            columns={columns}
            isCollapse={isCollapse}
          />
        );
      })}
    </TableBody>
  );
}
