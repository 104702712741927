import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
  Hidden,
  Drawer,
} from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../app/store';
import { useUISelector, toggleOpenDrawer } from '../../../features/UI/uiSlice';
// const
import { DRAWER_WIDTH } from '../../../constants';
// components
import DrawerMenu from './content/DrawerMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
      // background: theme.palette.primary.light,
    },
    content: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
      },
    },
  })
);

export default function ResponsiveDrawer(props: any) {
  const classes = useStyles();

  const theme = useTheme();

  const dispatch = useAppDispatch();

  const { openDrawer } = useUISelector((state) => state.ui);

  const handleDrawerToggle = () => {
    dispatch(toggleOpenDrawer());
  };

  // drawer menu
  const drawer = DrawerMenu();

  return (
    <div>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={openDrawer}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>{props.children}</main>
    </div>
  );
}
