import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  fetchTaxpayers,
  useTaxpayersSelector,
  selectTaxpayer,
  deleteTaxpayer,
} from '../../../../../features/taxpayers/taxpayersSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// components
import { DeleteDialog } from '../../common/Dialog';

interface TaxpayerDeleteDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TaxpayerDeleteDialog({
  openDialog,
  setOpenDialog,
}: TaxpayerDeleteDialogProps) {
  const dispatch = useAppDispatch();

  const {
    taxpayer,
    offset,
    limit,
    filter,
    showDisabled,
    sort_column,
    sort_direction,
  } = useTaxpayersSelector((state) => state.taxpayers);

  const [confirMessage, setConfirMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    setConfirMessage('¿Eliminar este usuario cliente?');
    setAlertMessage('Ya no lo verá entre sus clientes.');
  }, [openDialog]);

  const {
    dispatch: { setSnackbar, resetSnackbar, errorSnackbar },
  } = useSnackbar();

  const handleDeleteTaxpayer = async (taxpayerUuid: string) => {
    try {
      const resultAction = await dispatch(deleteTaxpayer(taxpayerUuid));
      const res = unwrapResult(resultAction);
      setSnackbar(true, res.message);
      dispatch(
        fetchTaxpayers({
          offset,
          limit,
          filter,
          showDisabled,
          sort_column,
          sort_direction,
        })
      );
    } catch (err: any) {
      errorSnackbar();
    }
  };

  const handleSubmit = () => {
    if (taxpayer) {
      resetSnackbar();
      handleDeleteTaxpayer(taxpayer.uuid);
      setOpenDialog(false);
      dispatch(selectTaxpayer(null));
    }
  };

  return (
    <DeleteDialog
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      handleSubmit={handleSubmit}
      confirMessage={confirMessage}
      alertMessage={alertMessage}
    />
  );
}
