import React from 'react';
import {
  Menu,
  List,
  ListItem,
  Radio,
  ListItemText,
  makeStyles,
  createStyles,
  lighten,
  Theme,
} from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../../../../app/store';
import { useUISelector } from '../../../../../../../../features/UI/uiSlice';
import {
  useTaxpayersSelector,
  fetchTaxpayers,
  selectTaxpayer,
  setMonth,
} from '../../../../../../../../features/taxpayers/taxpayersSlice';
// context
import { useSnackbar } from '../../../../../../../../context/SnackbarContext';
// types
import { Month } from '../../../../../../../../types';
// utils
import { getMonthName } from '../../../../../../../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      '&.Mui-selected': {
        borderRadius: 5,
        backgroundColor: lighten(theme.palette.primary.light, 0.7),
      },
      '&:hover': {
        borderRadius: 5,
        backgroundColor: lighten(theme.palette.primary.light, 0.9),
      },
      '&.Mui-selected:hover': {
        borderRadius: 5,
        backgroundColor: lighten(theme.palette.primary.light, 0.7),
      },
    },
  })
);

interface MonthsMenuProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

export default function MonthsMenu({ anchorEl, setAnchorEl }: MonthsMenuProps) {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const { months } = useUISelector((state) => state.ui);

  const {
    offset,
    limit,
    month,
    filter,
    showDisabled,
    sort_column,
    sort_direction,
  } = useTaxpayersSelector((state) => state.taxpayers);

  const handleSelectMonth = async (sentMonth: Month) => {
    let selectedMonth = sentMonth;
    if (month === sentMonth) {
      selectedMonth = null;
    }
    dispatch(selectTaxpayer(null));
    dispatch(setMonth(selectedMonth));
    // fetch from api
    try {
      await dispatch(
        fetchTaxpayers({
          offset,
          limit,
          month: selectedMonth,
          filter,
          showDisabled,
          sort_column,
          sort_direction,
        })
      );
    } catch (err: any) {
      errorSnackbar();
    }
    // close menu
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <List>
        {months.map((value) => {
          return (
            <ListItem
              key={value}
              dense
              button
              onClick={() => handleSelectMonth(value)}
              className={classes.listItem}
            >
              <Radio
                size="small"
                color="primary"
                edge="start"
                checked={value === month}
              />
              <ListItemText id={`${value}`} primary={getMonthName(value)} />
            </ListItem>
          );
        })}
      </List>
    </Menu>
  );
}
