import React, { useState } from 'react';
// global state
import { useAppDispatch } from '../../../../../../../app/store';
import {
  useDriveSelector,
  setSortColumn,
  setSortDirection,
} from '../../../../../../../features/drive/driveSlice';
// interface
import { IColumn, IFile } from '../../../../../../../interfaces';
// utils
import { formatSize, formatDateAndTime } from '../../../../../../../utils';
// hooks
import { useDrive } from '../../../../../../../hooks/useDrive';
// components
import { TableBody } from '../../../../common/Table/Body';
import DirectoryTableBodyHeader from './content/DirectoryTableBodyHeader';
import DirectoryTableBodyContainer from './content/DirectoryTableBodyContainer';
import LinkFolderWithTaxpayerDialog from '../../../../Dialogs/Directory/LinkFolderWithTaxpayerDialog';
import DirectoryContextMenu from './DirectoryContextMenu';
import FileNameAndIcon from './FileNameAndIcon';

const columns: IColumn<IFile>[] = [
  {
    id: 'name',
    label: 'Nombre',
    minWidth: 100,
    align: 'left',
    render: (row) => <FileNameAndIcon row={row} />,
  },
  {
    id: 'modifiedTime',
    label: 'Última modificación',
    minWidth: 100,
    align: 'right',
    render: (row) => formatDateAndTime(row.modifiedTime),
  },
  {
    id: 'size',
    label: 'Tamaño archivo',
    minWidth: 100,
    align: 'right',
    render: (row) => formatSize(row.size),
  },
];

export default function DirectoryTableBody() {
  const dispatch = useAppDispatch();

  const { loading, sort_column, sort_direction } = useDriveSelector(
    (state) => state.drive
  );

  const { indexFile } = useDrive();

  const [isCollapse] = useState<boolean>(false);

  const handleRequestSort = (
    e: React.MouseEvent<unknown>,
    property: keyof IFile
  ) => {
    // setting sort direction
    const isAsc = sort_column === property && sort_direction === 'asc';
    dispatch(setSortDirection(isAsc ? 'desc' : 'asc'));
    // setting sort column
    dispatch(setSortColumn(property));
  };

  const [openDialog, setOpenDialog] = useState(false);

  const [selectedRow, setSelectedRow] = useState<IFile | null>(null);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    if ((e.target as HTMLTextAreaElement).id) {
      setSelectedRow(indexFile((e.target as HTMLTextAreaElement).id));
    }
    setContextMenu(
      contextMenu === null
        ? { mouseX: e.clientX - 2, mouseY: e.clientY - 4 }
        : null
    );
  };

  return (
    <>
      <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
        <TableBody loading={loading}>
          <DirectoryTableBodyHeader
            onRequestSort={handleRequestSort}
            columns={columns}
            isCollapse={isCollapse}
          />
          <DirectoryTableBodyContainer
            columns={columns}
            isCollapse={isCollapse}
          />
          <DirectoryContextMenu
            handleContextMenu={handleContextMenu}
            selectedRow={selectedRow}
            setContextMenu={setContextMenu}
            contextMenu={contextMenu}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
          />
        </TableBody>
      </div>
      {selectedRow ? (
        <LinkFolderWithTaxpayerDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          row={selectedRow}
        />
      ) : null}
    </>
  );
}
