import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { Month } from '../../types';

// Interfaces
interface IUIState {
  openDrawer: boolean;
  collapsed: string | null;
  months: Month[];
}

// Initial state
const initialState: IUIState = {
  openDrawer: false,
  collapsed: null,
  months: [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ],
};

// Slice
const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleOpenDrawer(state: IUIState) {
      state.openDrawer = !state.openDrawer;
    },
    setCollapsed(state: IUIState, { payload }: PayloadAction<string | null>) {
      state.collapsed = payload;
    },
  },
});

// Actions generated from the slice
export const { toggleOpenDrawer, setCollapsed } = ui.actions;

// Selector
export const useUISelector: TypedUseSelectorHook<RootState> = useSelector;

// Reducer
export default ui.reducer;
