import { useCallback, ChangeEvent } from 'react';
import debounce from 'lodash.debounce';
// global state
import { useAppDispatch } from '../../../../../../../../app/store';
import {
  useDriveSelector,
  fetchDriveItems,
  setFilter,
  setNextPageToken,
} from '../../../../../../../../features/drive/driveSlice';
// context
import { useSnackbar } from '../../../../../../../../context/SnackbarContext';
// interface
import { IBreadcrumb } from '../../../../../../../../interfaces';
// components
import { SearchField } from '../../../../../common/Table/Header';

export default function SearchFieldDirectory() {
  const dispatch = useAppDispatch();

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const { limit, filter, breadcrumbs, sort_column, sort_direction } =
    useDriveSelector((state) => state.drive);

  const handleFetchItems = async (
    filter: string,
    breadcrumbs: IBreadcrumb[]
  ) => {
    dispatch(setNextPageToken(['']));
    try {
      const nextPageToken = '';
      await dispatch(
        fetchDriveItems({
          limit,
          filter,
          folder_id: breadcrumbs[breadcrumbs.length - 1].folderId,
          sort_column,
          sort_direction,
          nextPageToken,
        })
      );
    } catch (err: any) {
      errorSnackbar();
    }
  };

  const debounceItems = useCallback(debounce(handleFetchItems, 300), []);

  const handleSetFilter = async (filter: string) => {
    dispatch(setFilter(filter));
    debounceItems(filter, breadcrumbs);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filter = e.currentTarget.value;
    handleSetFilter(filter);
  };

  const handleClickClear = () => {
    if (filter) {
      handleSetFilter('');
    }
  };

  return (
    <SearchField
      handleChange={handleChange}
      handleClickClear={handleClickClear}
      filter={filter}
    />
  );
}
