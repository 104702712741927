import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

interface DeleteDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
  confirMessage: string;
  alertMessage: string;
}

export default function DeleteDialog({
  openDialog,
  setOpenDialog,
  handleSubmit,
  confirMessage,
  alertMessage,
}: DeleteDialogProps) {
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      keepMounted
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="simple-dialog"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {`${confirMessage} 🗑️`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {`${alertMessage}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          {'Cancelar'}
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          {'Eliminar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
