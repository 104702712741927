import { makeStyles, createStyles, Theme, Avatar } from '@material-ui/core';
// interface
import { ITaxpayer } from '../../../../../interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.secondary.light,
    },
    name: {
      paddingLeft: '15px',
    },
  })
);

interface AvatarAndNameProps {
  row: ITaxpayer;
}

export default function AvatarAndName({ row }: AvatarAndNameProps) {
  const classes = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Avatar
        className={classes.avatar}
        src={`data:image/jpeg;base64,${row.logo}`}
      >
        {row.company_name ? row.company_name.charAt(0) : null}
      </Avatar>
      <div className={classes.name}>
        <span>{row.company_name}</span>
      </div>
    </div>
  );
}
