// components
import { MainView } from '../common';
import TaxpayersTable from '../components/Dashboard/MainViews/Tables/Taxpayers/Taxpayers';

export default function Taxpayers() {
  return (
    <MainView>
      <TaxpayersTable />
    </MainView>
  );
}
