import { createStyles, Theme, withStyles, Grid } from '@material-ui/core';

export const StyledGrid = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
    },
  })
)(Grid);

export default StyledGrid;
