import { ChangeEvent } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  InputAdornment,
  FormControl,
  OutlinedInput,
  IconButton,
  ListItemIcon,
} from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      minWidth: '20px',
    },
    root: {
      margin: theme.spacing(1),
      maxWidth: 1300,
    },
  })
);

interface SearchFieldProps {
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleClickClear: () => void;
  filter: string;
}

export default function SearchField({
  handleChange,
  handleClickClear,
  filter,
}: SearchFieldProps) {
  const classes = useStyles();

  return (
    <>
      <FormControl
        fullWidth
        className={classes.root}
        variant="outlined"
        size="small"
      >
        <OutlinedInput
          value={filter}
          onChange={handleChange}
          labelWidth={0}
          startAdornment={
            <InputAdornment position="start">
              <ListItemIcon className={classes.icon}>
                <SearchRoundedIcon />
              </ListItemIcon>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleClickClear} edge="end">
                <ListItemIcon className={classes.icon}>
                  <CloseRoundedIcon />
                </ListItemIcon>
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  );
}
