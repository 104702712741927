import {
  makeStyles,
  createStyles,
  Theme,
  CircularProgress,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      marginTop: theme.spacing(30),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    progress: {
      color: theme.palette.primary.light,
    },
  })
);

const TableLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Box component="span">
        <CircularProgress disableShrink className={classes.progress} />
      </Box>
    </div>
  );
};

export default TableLoading;
