import React from 'react';
import {
  Tooltip,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// context
import { useSnackbar } from '../../../../../../../context/SnackbarContext';
// hooks
import { useIsMobile } from '../../../../../../../hooks/useIsMobile';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.main,
    },
  })
);

interface EditButtonProps {
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  children: JSX.Element;
}

export default function EditButton({
  setOpenDialog,
  children,
}: EditButtonProps) {
  const classes = useStyles();

  const { isMobile } = useIsMobile();

  const {
    dispatch: { resetSnackbar },
  } = useSnackbar();

  const handleClick = () => {
    setOpenDialog(true);
    resetSnackbar();
  };

  return (
    <>
      <Tooltip title={'Editar'}>
        <IconButton
          aria-label="edit"
          size={isMobile ? 'small' : 'medium'}
          onClick={handleClick}
        >
          <EditRoundedIcon className={classes.icon} />
        </IconButton>
      </Tooltip>

      {children}
    </>
  );
}
