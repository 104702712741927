// global state
import { useAppDispatch } from '../../../../../../../app/store';
import {
  useNotificationsSelector,
  setOffset,
  setLimit,
} from '../../../../../../../features/notifications/notificationsSlice';
// components
import { TableFooter } from '../../../../common/Table/Footer';

export default function NotificationTableFooter() {
  const dispatch = useAppDispatch();

  const { notifications, offset, limit } = useNotificationsSelector(
    (state) => state.notifications
  );

  const handleSetOffset = async (offset: number) => {
    dispatch(setOffset(offset));
  };

  const handleSetLimit = async (limit: number) => {
    dispatch(setLimit(limit));
    handleSetOffset(1);
  };

  return (
    <TableFooter
      rows={notifications}
      handleSetOffset={handleSetOffset}
      handleSetLimit={handleSetLimit}
      offset={offset}
      limit={limit}
    />
  );
}
