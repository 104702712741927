import React, { useState } from 'react';
// global state
import { useAppDispatch } from '../../../../../../../app/store';
import {
  useUsersSelector,
  setSortColumn,
  setSortDirection,
} from '../../../../../../../features/users/usersSlice';
// interface
import { IColumn, IUser } from '../../../../../../../interfaces';
// utils
import { formatDate } from '../../../../../../../utils';
// components
import { TableBody } from '../../../../common/Table/Body';
// children components
import ClientTableBodyHeader from './content/ClientTableBodyHeader';
import ClientTableBodyContainer from './content/ClientTableBodyContainer';
import ToggleClientButton from './ToggleClientButton';

const columns: IColumn<IUser>[] = [
  {
    id: 'name',
    label: 'Nombre',
    minWidth: 100,
    align: 'left',
  },
  { id: 'surname', label: 'Apellido', minWidth: 100, align: 'left' },
  { id: 'email', label: 'Email', minWidth: 100, align: 'right' },
  {
    id: 'birthday',
    label: 'Fecha de nacimiento',
    minWidth: 100,
    align: 'right',
    render: (row) => formatDate(row.birthday),
  },
  { id: 'phone', label: 'Tel.', minWidth: 100, align: 'right' },
  { id: 'address', label: 'Dirección', minWidth: 100, align: 'right' },
  {
    id: 'enabled_at',
    label: 'Fecha de alta',
    minWidth: 100,
    align: 'right',
    render: (row) => formatDate(row.enabled_at),
  },
  {
    id: 'disabled_at',
    label: 'Fecha de baja',
    minWidth: 100,
    align: 'right',
    render: (row) => formatDate(row.disabled_at),
  },
  {
    id: 'enabled',
    label: 'Situación',
    minWidth: 100,
    align: 'right',
    render: (row) => <ToggleClientButton row={row} />,
  },
];

export default function ClientTableBody() {
  const dispatch = useAppDispatch();

  const { loading, sort_column, sort_direction } = useUsersSelector(
    (state) => state.users
  );

  const [isCollapse] = useState<boolean>(true);

  const handleRequestSort = (
    e: React.MouseEvent<unknown>,
    property: keyof IUser
  ) => {
    // setting sort direction
    const isAsc = sort_column === property && sort_direction === 'asc';
    dispatch(setSortDirection(isAsc ? 'desc' : 'asc'));
    // setting sort column
    dispatch(setSortColumn(property));
  };

  return (
    <TableBody loading={loading}>
      <ClientTableBodyHeader
        onRequestSort={handleRequestSort}
        columns={columns}
        isCollapse={isCollapse}
      />
      <ClientTableBodyContainer columns={columns} isCollapse={isCollapse} />
    </TableBody>
  );
}
