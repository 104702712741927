import React, { useState, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormikErrors } from 'formik';
import debounce from 'lodash.debounce';
// api
import { fetchManagers } from '../../../../../../api/taxpayer.services';
// context
import { useSnackbar } from '../../../../../../context/SnackbarContext';
// interface
import { ITaxpayerForm, IManager } from '../../../../../../interfaces';
// utils
import { capitalize, removeExtraSpace } from '../../../../../../utils';

interface ManagerAutocompleteProps {
  manager: string;
  managerErrors: string | undefined;
  formikSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<ITaxpayerForm>>;
  handleResetError: () => void;
}

export default function ManagerAutocomplete({
  manager,
  managerErrors,
  formikSetFieldValue,
  handleResetError,
}: ManagerAutocompleteProps) {
  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const [managers, setManagers] = useState<IManager[]>([]);

  const handleFetchManagers = async (filter: string) => {
    if (filter) {
      try {
        const managers = await fetchManagers(filter);
        setManagers(managers);
      } catch (err: any) {
        errorSnackbar();
      }
    } else {
      setManagers([]);
    }
  };

  const debounceManager = useCallback(debounce(handleFetchManagers, 300), []);

  const handleManagerAutocompleteInputChange = (
    e: React.ChangeEvent<{}>,
    filter: string
  ) => {
    formikSetFieldValue('manager', removeExtraSpace(capitalize(filter)));
    handleResetError();
    debounceManager(filter);
    setManagers([]);
  };

  return (
    <Autocomplete
      id="manager"
      size="small"
      options={managers.map((row) => {
        return row.name;
      })}
      getOptionLabel={(option) => option}
      getOptionSelected={(option) =>
        managers.map((row) => row.name === option)[0]
      }
      value={manager}
      onInputChange={handleManagerAutocompleteInputChange}
      freeSolo
      disableClearable
      style={{ width: 230 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Asesor *"
          variant="outlined"
          helperText={managerErrors ? managerErrors : ' '}
          error={!!managerErrors}
        />
      )}
    />
  );
}
