import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormikErrors } from 'formik';
// global state
import { useUISelector } from '../../../../../../features/UI/uiSlice';
// interface
import { ITaxpayerForm } from '../../../../../../interfaces';
// types
import { Month } from '../../../../../../types';
// hooks
import { useTaxpayerTypes } from '../../../../../../hooks/useTaxpayerTypes';
// constants
import { MONOTRIBUTISTA, PERSONA_FISICA } from '../../../../../../constants';
// utils
import { getMonthName } from '../../../../../../utils';

interface MonthAutocompleteProps {
  financialYearClose: Month;
  financialYearCloseErrors: string | undefined;
  formikSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<ITaxpayerForm>>;
  handleResetError: () => void;
  taxpayerTypeId: number;
}

export default function MonthAutocomplete({
  financialYearClose,
  financialYearCloseErrors,
  handleResetError,
  formikSetFieldValue,
  taxpayerTypeId,
}: MonthAutocompleteProps) {
  const { months } = useUISelector((state) => state.ui);

  const { getTaxpayerTypeID } = useTaxpayerTypes();

  const handleMonthAutocompleteChange = (
    e: React.ChangeEvent<{}>,
    data: Month
  ) => {
    formikSetFieldValue('financial_year_close', data);
    handleResetError();
  };

  return (
    <Autocomplete
      options={months}
      autoHighlight
      value={financialYearClose}
      getOptionLabel={(option) => (option ? getMonthName(option) : '')}
      renderOption={(option) => getMonthName(option)}
      onChange={handleMonthAutocompleteChange}
      style={{ width: 230 }}
      size="small"
      disabled={
        taxpayerTypeId === getTaxpayerTypeID(MONOTRIBUTISTA) ||
        taxpayerTypeId === getTaxpayerTypeID(PERSONA_FISICA)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Mes de cierre ejercicio"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          helperText={financialYearCloseErrors ? financialYearCloseErrors : ' '}
          error={!!financialYearCloseErrors}
        />
      )}
    />
  );
}
