import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  Grid,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
// common
import { Progress } from '../../../../../common';

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('lg')]: {
        maxHeight: 400,
      },
      [theme.breakpoints.up('lg')]: {
        maxHeight: 450,
      },
      minHeight: 200,
      minWidth: 150,
      maxWidth: 600,
    },
  })
);

interface FormBodyProps {
  children: JSX.Element;
  loading: boolean;
  helperText?: string;
  isAForm?: boolean;
}

export default function FormBody({
  children,
  loading,
  helperText = '',
  isAForm = true,
}: FormBodyProps) {
  const classes = useStyles();

  return (
    <>
      {loading ? <Progress /> : <div style={{ height: 4 }} />}
      <DialogContent dividers className={classes.root}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          style={{ paddingTop: isAForm ? '10px' : '0px' }}
        >
          {children}

          <FormHelperText>
            <Typography>{helperText}</Typography>
          </FormHelperText>
        </Grid>
      </DialogContent>
    </>
  );
}
