import React, { useEffect, useCallback } from 'react';
import { Checkbox, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FormikErrors } from 'formik';
import debounce from 'lodash.debounce';
// global state
import { useUsersSelector } from '../../../../../../features/users/usersSlice';
import { useTaxpayersSelector } from '../../../../../../features/taxpayers/taxpayersSlice';
// api
import {
  fetchTaxpayerClients,
  fetchUsersClient,
} from '../../../../../../api/user.services';
// context
import { useSnackbar } from '../../../../../../context/SnackbarContext';
// interface
import { ITaxpayerForm, IUser } from '../../../../../../interfaces';
// hooks
import { useIsMobile } from '../../../../../../hooks/useIsMobile';

interface ClientAutocompleteProps {
  usersUuidErrors: string | string[] | undefined;
  formikSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<ITaxpayerForm>>;
  handleResetError: () => void;
  setUsers: React.Dispatch<React.SetStateAction<IUser[]>>;
  users: IUser[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<IUser[]>>;
  selectedUsers: IUser[];
  openDialog: boolean;
}

export default function ClientAutocomplete({
  usersUuidErrors,
  handleResetError,
  formikSetFieldValue,
  setUsers,
  users,
  setSelectedUsers,
  selectedUsers,
  openDialog,
}: ClientAutocompleteProps) {
  const { isMobile } = useIsMobile();

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const { taxpayer } = useTaxpayersSelector((state) => state.taxpayers);

  const { offset, limit, sort_column, sort_direction } = useUsersSelector(
    (state) => state.users
  );

  const handleFetchTaxpayerClients = async () => {
    if (taxpayer) {
      try {
        const users = await fetchTaxpayerClients(taxpayer.uuid);
        setUsers(users);
        setSelectedUsers(users);
      } catch (err: any) {
        errorSnackbar();
      }
    }
  };

  useEffect(() => {
    if (openDialog && taxpayer) {
      handleFetchTaxpayerClients();
    }
  }, [openDialog]);

  const handleFetchClients = async (filter: string) => {
    if (filter) {
      try {
        const taxpayers = await fetchUsersClient(
          offset,
          limit,
          filter,
          false, // fetch only enabled users
          sort_column,
          sort_direction
        );
        setUsers(taxpayers);
      } catch (err: any) {
        errorSnackbar();
      }
    }
  };

  const debounceClients = useCallback(debounce(handleFetchClients, 300), []);

  const handleClientsAutocompleteInputChange = (
    e: React.ChangeEvent<{}>,
    filter: string | null,
    reason: string
  ) => {
    if (reason === 'clear') {
      filter = '';
    }
    if (typeof filter === 'string') {
      handleResetError();
      debounceClients(filter);
    }
  };

  const handleClientsAutocompleteChange = (
    e: React.ChangeEvent<{}>,
    data: IUser[],
    reason: string,
    details?: any | undefined
  ) => {
    if (reason === 'select-option') {
      // remove duplicates
      const sentUuid = details.option.uuid;
      if (!!selectedUsers.find((user) => user.uuid === sentUuid)) {
        data = data.filter((row) => row.uuid !== sentUuid);
      }
    }
    if (data) {
      // update hook state
      setSelectedUsers(data);
      // update formik state
      const usersUuid = data.map((row) => row.uuid);
      formikSetFieldValue('users_uuid', usersUuid);
    }
    handleResetError();
  };

  return (
    <Autocomplete
      multiple
      size="small"
      limitTags={8}
      options={users}
      getOptionLabel={(option) => `${option.name} ${option.surname}`}
      value={selectedUsers}
      onChange={handleClientsAutocompleteChange}
      onInputChange={handleClientsAutocompleteInputChange}
      noOptionsText="Sin resultados"
      renderOption={(option) => (
        <React.Fragment>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={!!selectedUsers.find((row) => option.uuid === row.uuid)}
          />
          {`${option.name} ${option.surname}`}
        </React.Fragment>
      )}
      style={{ width: isMobile ? 230 : 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Usuarios"
          helperText={
            usersUuidErrors ? usersUuidErrors : 'Escriba para buscar un usuario'
          }
          error={!!usersUuidErrors}
        />
      )}
    />
  );
}
