import { Link, useLocation } from 'react-router-dom';
import {
  ListSubheader,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
  ListItem,
  Box,
  Divider,
} from '@material-ui/core';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import FolderRoundedIcon from '@material-ui/icons/FolderRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
// constants
import {
  HOME_ROUTE,
  CLIENTS_ROUTE,
  TAXPAYERS_ROUTE,
  DIRECTORY_ROUTE,
  TAXES_ROUTE,
  NOTIFICATIONS_ROUTE,
} from '../../../../../constants';

const MENU = [
  {
    route: HOME_ROUTE,
    icon: (classes: any) => (
      <HomeRoundedIcon fontSize="default" className={classes.icon} />
    ),
    label: 'Inicio',
  },
  {
    route: CLIENTS_ROUTE,
    icon: (classes: any) => (
      <PersonRoundedIcon fontSize="default" className={classes.icon} />
    ),
    label: 'Usuarios',
  },
  {
    route: TAXPAYERS_ROUTE,
    icon: (classes: any) => (
      <MonetizationOnRoundedIcon fontSize="default" className={classes.icon} />
    ),
    label: 'Contribuyentes',
  },
  {
    route: DIRECTORY_ROUTE,
    icon: (classes: any) => (
      <FolderRoundedIcon fontSize="default" className={classes.icon} />
    ),
    label: 'Diseño de archivo',
  },
  {
    route: TAXES_ROUTE,
    icon: (classes: any) => (
      <AccountBalanceRoundedIcon fontSize="default" className={classes.icon} />
    ),
    label: 'Impuestos',
  },
  {
    route: NOTIFICATIONS_ROUTE,
    icon: (classes: any) => (
      <NotificationsRoundedIcon fontSize="default" className={classes.icon} />
    ),
    label: 'Notificaciones',
  },
];

interface ListMenuProps {
  handleClick(): void;
  classes: any;
}

export default function ListMenu({ classes, handleClick }: ListMenuProps) {
  const location = useLocation();

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <Box py={2}>
            <Typography variant="subtitle2">{'Menú'}</Typography>
          </Box>
        </ListSubheader>
      }
      className={classes.root}
    >
      <Divider variant="middle" />

      {MENU.map((row) => {
        return (
          <>
            <ListItem
              button
              selected={location.pathname === row.route}
              onClick={handleClick}
              component={Link}
              to={row.route}
              className={classes.listItem}
            >
              <ListItemIcon className={classes.listItemIcon}>
                {row.icon(classes)}
              </ListItemIcon>
              <ListItemText primary={row.label} />
            </ListItem>
          </>
        );
      })}
    </List>
  );
}
