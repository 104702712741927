import {
  makeStyles,
  createStyles,
  CircularProgress,
  Box,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      marginTop: theme.spacing(9),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    circularProgress: {
      color: theme.palette.secondary.light,
    },
  })
);

const Progress = () => {
  const classes = useStyles();

  return (
    <div className={classes.progress}>
      <Box component="span">
        <CircularProgress className={classes.circularProgress} />
      </Box>
    </div>
  );
};

export default Progress;
