import jwt from 'jwt-decode';
import { IToken } from '../interfaces';
import { ResponseType } from 'axios';

const ACCESS_TOKEN_KEY: string = 'accessToken';

const TOKEN_EXPIRATION_KEY: string = 'tokenExpiration';

export const getMyRoleAndUuid = (token: string) => {
  const decodedToken: IToken = jwt(token);
  let role = decodedToken.role;
  let uuid = decodedToken.sub;
  return { role, uuid };
};

export const isAuthenticated = () => {
  return !!getAccessToken();
};

export const isTokenExpired = () => {
  const tokenExpiration =
    localStorage.getItem(TOKEN_EXPIRATION_KEY) ||
    sessionStorage.getItem(TOKEN_EXPIRATION_KEY);
  if (tokenExpiration) {
    if (Number(tokenExpiration) < Date.now() / 1000) return true;
    return false;
  }
  return false;
};

export const removeTokenExpiration = () => {
  sessionStorage.removeItem(TOKEN_EXPIRATION_KEY);
  localStorage.removeItem(TOKEN_EXPIRATION_KEY);
};

export const isRemember = () => {
  return !!localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getAccessToken = () => {
  return (
    localStorage.getItem(ACCESS_TOKEN_KEY) ||
    sessionStorage.getItem(ACCESS_TOKEN_KEY)
  );
};

export const httpHeader = () => {
  const token = getAccessToken();
  const config = {
    Authorization: 'Bearer ' + token,
  };
  return config;
};

export const httpConfigBuffer = () => {
  const token = getAccessToken();
  const config = {
    responseType: 'arraybuffer' as ResponseType,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  return config;
};

export const login = (remember: boolean, accessToken: string) => {
  const decodedToken: IToken = jwt(accessToken);
  if (remember) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(TOKEN_EXPIRATION_KEY, decodedToken.exp.toString());
  } else {
    sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    sessionStorage.setItem(TOKEN_EXPIRATION_KEY, decodedToken.exp.toString());
  }
};

export const logout = () => {
  sessionStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};
