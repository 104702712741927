import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  TextField,
  DialogContentText,
  Typography,
  MenuItem,
} from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  fetchUsersClient,
  useUsersSelector,
  selectUser,
  toggleUserStatus,
} from '../../../../../features/users/usersSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// constants
import { MAX_LEN_REASON, REASONS_FOR_DISABLE } from '../../../../../constants';
// utils
import { capitalize } from '../../../../../utils';
// components
import { ToggleDialog } from '../../common/Dialog';

interface ClientToggleStatusProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  userUuid: string;
  enabled: boolean;
  reasonForDisable: string;
}

export default function ClientToggleStatus({
  openDialog,
  setOpenDialog,
  userUuid,
  enabled,
  reasonForDisable,
}: ClientToggleStatusProps) {
  const dispatch = useAppDispatch();

  const { offset, limit, filter, showDisabled, sort_column, sort_direction } =
    useUsersSelector((state) => state.users);

  const [confirMessage, setConfirMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [reason, setReason] = useState(REASONS_FOR_DISABLE[0]);

  useEffect(() => {
    setConfirMessage('Cambiar situación de este usuario?');
    setAlertMessage('');
  }, [openDialog]);

  const {
    dispatch: { setSnackbar, resetSnackbar, errorSnackbar },
  } = useSnackbar();

  const handleToggleUserStatus = async (userUuid: string, reason: string) => {
    try {
      const resultAction = await dispatch(
        toggleUserStatus({ userUuid, reason })
      );
      const res = unwrapResult(resultAction);
      setSnackbar(true, res.message);
      dispatch(
        fetchUsersClient({
          offset,
          limit,
          filter,
          showDisabled,
          sort_column,
          sort_direction,
        })
      );
    } catch (err: any) {
      errorSnackbar();
    }
  };

  const handleSubmit = () => {
    resetSnackbar();
    handleToggleUserStatus(userUuid, reason);
    setOpenDialog(false);
    dispatch(selectUser(null));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setReason(capitalize(e.target.value));
  };

  return (
    <ToggleDialog
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      handleSubmit={handleSubmit}
      confirMessage={confirMessage}
      alertMessage={alertMessage}
      setReason={setReason}
    >
      {enabled ? (
        <TextField
          id="reason_for_disable"
          label="Razón de la baja"
          type="text"
          variant="outlined"
          size="small"
          select
          fullWidth
          multiline
          value={reason}
          onChange={handleChange}
          inputProps={{
            maxLength: MAX_LEN_REASON,
          }}
          helperText=" "
        >
          {REASONS_FOR_DISABLE.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      ) : !enabled ? (
        <>
          {reasonForDisable ? (
            <DialogContentText>
              <Typography>{`Razón de la baja: ${reasonForDisable}`}</Typography>
            </DialogContentText>
          ) : null}
        </>
      ) : null}
    </ToggleDialog>
  );
}
