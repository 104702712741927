import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { ITaxpayerType } from '../../interfaces';
import * as taxpayerSvcs from '../../api/taxpayer.services';

// Interfaces
interface ITaxpayerTypesState {
  loading: boolean;
  error: boolean | string;
  taxpayerTypes: ITaxpayerType[];
}

// Initial state
const initialState: ITaxpayerTypesState = {
  loading: false,
  error: false,
  taxpayerTypes: [],
};

// Asynchronous thunk actions
export const fetchTaxpayerTypes = createAsyncThunk(
  'taxpayerTypes/fetchTaxpayerTypes',
  () => {
    return taxpayerSvcs.fetchTaxpayerTypes();
  }
);

// Slice
const taxpayerTypes = createSlice({
  name: 'taxpayerTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTaxpayerTypes.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchTaxpayerTypes.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.taxpayerTypes = action.payload;
    });
    builder.addCase(fetchTaxpayerTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
  },
});

// A selector
export const useTaxpayerTypesSelector: TypedUseSelectorHook<RootState> =
  useSelector;

// The reducer
export default taxpayerTypes.reducer;
