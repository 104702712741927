import { useEffect } from 'react';
import {
  makeStyles,
  Typography,
  Box,
  CssBaseline,
  Paper,
  Grid,
  Hidden,
} from '@material-ui/core';
// hooks
import { useIsMobile } from '../../hooks/useIsMobile';
// children components
import LoginForm from './content/LoginForm';
import HelpGrid from './content/HelpGrid';
// image
import logo from '../../images/logo.png';
// context
import { useSnackbar } from '../../context/SnackbarContext';
// utils
import { isTokenExpired, removeTokenExpiration } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    backgroundColor: theme.palette.info.main,
  },
  image: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '50vh',
    marginTop: theme.spacing(20),
  },
  null: {},
  icon: {
    color: theme.palette.primary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

export default function SignIn(): JSX.Element {
  const classes = useStyles();

  const {
    dispatch: { setSnackbar },
  } = useSnackbar();

  useEffect(() => {
    if (isTokenExpired()) {
      setSnackbar(true, 'Su sesión expiró. Vuelva a iniciar sesión.');
      removeTokenExpiration();
    }
  }, []);

  const { isMobile } = useIsMobile();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Hidden xsDown>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.image}
          xs={false}
          sm={4}
          md={4}
        >
          <img alt="logo" src={logo} />
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={8} md={8} component={Paper} square>
        <div className={classes.paper}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            className={isMobile ? classes.null : classes.form}
          >
            <Typography component="h1" variant="h5">
              {'Iniciar sesión'}
            </Typography>
            <LoginForm classes={classes} />
            <Box pt={2}>
              <HelpGrid />
            </Box>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
