import React from 'react';
// global state
import { useAppDispatch } from '../../../../../../../../../app/store';
import {
  selectUser,
  useUsersSelector,
} from '../../../../../../../../../features/users/usersSlice';
import {
  useUISelector,
  setCollapsed,
} from '../../../../../../../../../features/UI/uiSlice';
// interface
import { IColumn, IUser } from '../../../../../../../../../interfaces';
// utils
import { formatNulls } from '../../../../../../../../../utils';
// components
import {
  StyledTableCell,
  TableContent,
} from '../../../../../../common/Table/Body/content/TableContent';
import ClientCollapseContent from './Collapse/ClientCollapseContent';

interface ClientTableContentProps {
  row: IUser;
  labelId: string;
  columns: IColumn<IUser>[];
  isCollapse: boolean;
}

export default function ClientTableContent({
  row,
  labelId,
  columns,
  isCollapse,
}: ClientTableContentProps) {
  const dispatch = useAppDispatch();

  const { user } = useUsersSelector((state) => state.users);

  const { collapsed } = useUISelector((state) => state.ui);

  const handleClick = (e: React.MouseEvent<unknown>, row: any) => {
    let newSelected: IUser | null = null;
    if (user === row) {
      // close collapse
      dispatch(setCollapsed(null));
    } else {
      newSelected = row;
      // close collapse
      if (collapsed !== row.uuid) {
        dispatch(setCollapsed(null));
      }
    }
    dispatch(selectUser(newSelected));
  };

  const toggleCollapse = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    dispatch(setCollapsed(row.uuid));
    // deselect item if needed
    if (user) {
      if (user.uuid !== row.uuid) {
        dispatch(selectUser(null));
      }
    }
  };

  const toClient = (column: IColumn<IUser>) => {
    return (
      <StyledTableCell
        key={column.id}
        align={column.align}
        style={{ minWidth: column.minWidth }}
      >
        {typeof column?.render === 'function'
          ? column.render(row)
          : formatNulls(row[column.id])}
      </StyledTableCell>
    );
  };

  return (
    <>
      <TableContent
        row={row}
        labelId={labelId}
        isCollapse={isCollapse}
        selectedItem={user}
        handleClick={handleClick}
        toggleCollapse={toggleCollapse}
      >
        {columns.map(toClient)}
      </TableContent>

      <ClientCollapseContent row={row} />
    </>
  );
}
