import { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Grid,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
// global state
import { useUsersSelector } from '../../../../../../../features/users/usersSlice';
// components
import { TableHeader } from '../../../../common/Table/Header';
// components
import AddClientButton from './content/AddClientButton';
import EditClientButton from './content/EditClientButton';
import DeleteClientButton from './content/DeleteClientButton';
import ShowDisableClientsButton from './content/ShowDisableClientsButton';
import SearchFieldClient from './content/SearchFieldClient';
import ResetPsswdClientButton from './content/ResetPsswdClientButton';
import SendMailClientButton from './content/SendMailClientButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingInline: theme.spacing(1),
    },
    head: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  })
);

export default function ClientTableHeader() {
  const classes = useStyles();

  const { user } = useUsersSelector((state) => state.users);

  const [title, setTitle] = useState('');

  useEffect(() => {
    if (user) {
      setTitle(`${user.name} ${user.surname}`);
    }
  }, [user]);

  return (
    <TableHeader title={title} isItemSelected={!!user}>
      <>
        <Grid
          container
          className={classes.container}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12} sm={2} className={classes.container}>
            <Typography variant="h5"> {'Usuarios'}</Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.container}>
            <ShowDisableClientsButton />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.container}>
            <Box display="flex" justifyContent="flex-end">
              <AddClientButton />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.container}>
            <SearchFieldClient />
          </Grid>
        </Grid>
      </>

      <>
        <Grid
          container
          className={classes.container}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box className={classes.head}>
            <EditClientButton />
            <SendMailClientButton />
            <ResetPsswdClientButton />
            <DeleteClientButton />
          </Box>
        </Grid>
      </>
    </TableHeader>
  );
}
