import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

interface ToggleDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (e: React.MouseEvent<unknown>) => void;
  confirMessage: string;
  alertMessage: string;
  children: React.ReactNode;
  setReason: React.Dispatch<React.SetStateAction<string>>;
}

export default function ToggleDialog({
  openDialog,
  setOpenDialog,
  handleSubmit,
  confirMessage,
  alertMessage,
  children,
  setReason,
}: ToggleDialogProps) {
  const handleClose = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    setOpenDialog(false);
    setReason('');
  };

  const stopPropagation = (e: React.MouseEvent<unknown>) => {
    // if propagation isn't stoped, the table down will stil be clickable
    e.stopPropagation();
  };

  return (
    <Dialog
      open={openDialog}
      keepMounted
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="simple-dialog"
      onClick={stopPropagation}
    >
      <DialogTitle id="alert-dialog-slide-title">
        {`${confirMessage} 🚨`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {`${alertMessage}`}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          {'Cancelar'}
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          {'Cambiar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
