import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  fetchUsersClient,
  useUsersSelector,
  selectUser,
  deleteUser,
} from '../../../../../features/users/usersSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// components
import { DeleteDialog } from '../../common/Dialog';

interface ClientDeleteDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ClientDeleteDialog({
  openDialog,
  setOpenDialog,
}: ClientDeleteDialogProps) {
  const dispatch = useAppDispatch();

  const {
    user,
    offset,
    limit,
    filter,
    showDisabled,
    sort_column,
    sort_direction,
  } = useUsersSelector((state) => state.users);

  const [confirMessage, setConfirMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    setConfirMessage('¿Eliminar este usuario?');
    setAlertMessage('Ya no lo verá entre sus usuarios.');
  }, [openDialog]);

  const {
    dispatch: { setSnackbar, resetSnackbar, errorSnackbar },
  } = useSnackbar();

  const handleDeleteUser = async (userUuid: string) => {
    try {
      const resultAction = await dispatch(deleteUser(userUuid));
      const res = unwrapResult(resultAction);
      setSnackbar(true, res.message);
      dispatch(
        fetchUsersClient({
          offset,
          limit,
          filter,
          showDisabled,
          sort_column,
          sort_direction,
        })
      );
    } catch (err: any) {
      errorSnackbar();
    }
  };

  const handleSubmit = () => {
    if (user) {
      resetSnackbar();
      handleDeleteUser(user.uuid);
      setOpenDialog(false);
      dispatch(selectUser(null));
    }
  };

  return (
    <DeleteDialog
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      handleSubmit={handleSubmit}
      confirMessage={confirMessage}
      alertMessage={alertMessage}
    />
  );
}
