import React from 'react';
// interface
import { IColumn, IFile } from '../../../../../../../../../interfaces';
// utils
import { formatNulls } from '../../../../../../../../../utils';
// components
import {
  StyledTableCell,
  TableContent,
} from '../../../../../../common/Table/Body/content/TableContent';

interface ClientTableContentProps {
  row: IFile;
  labelId: string;
  columns: IColumn<IFile>[];
  isCollapse: boolean;
}

export default function ClientTableContent({
  row,
  labelId,
  columns,
  isCollapse,
}: ClientTableContentProps) {
  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  const toFile = (column: IColumn<IFile>) => {
    return (
      <StyledTableCell
        key={column.id}
        align={column.align}
        style={{ minWidth: column.minWidth, cursor: 'context-menu' }}
        onContextMenu={handleContextMenu}
        id={row.id}
      >
        {typeof column?.render === 'function'
          ? column.render(row)
          : formatNulls(row[column.id])}
      </StyledTableCell>
    );
  };

  return (
    <TableContent
      row={row}
      labelId={labelId}
      isCollapse={isCollapse}
      isRadio={false}
      isPointer={false}
    >
      {columns.map(toFile)}
    </TableContent>
  );
}
