import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
// global state
import { useAuthSelector } from '../features/auth/authSlice';
// constants
import { LOGIN_ROUTE } from '../constants';
// utils
import { isAuthenticated } from '../utils';

interface Props extends RouteProps {
  component: React.ComponentType;
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const { loggedIn } = useAuthSelector((state) => state.auth);

  return (
    <Route
      exact
      {...rest}
      render={(props: RouteProps) =>
        loggedIn || isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={LOGIN_ROUTE} />
        )
      }
    />
  );
};

export default PrivateRoute;
