import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../../../app/store';
import {
  useNotificationsSelector,
  setSortColumn,
  setSortDirection,
} from '../../../../../../../features/notifications/notificationsSlice';
// interface
import { IColumn, INotification } from '../../../../../../../interfaces';
// utils
import { formatDateAndTime } from '../../../../../../../utils';
// components
import { TableBody } from '../../../../common/Table/Body';
// children components
import NotificationTableBodyHeader from './content/NotificationTableBodyHeader';
import NotificationTableBodyContainer from './content/NotificationTableBodyContainer';
import ReadCheckMark from './ReadCheckMark';

const columns: IColumn<INotification>[] = [
  {
    id: 'client',
    label: 'Usuario',
    minWidth: 150,
    align: 'left',
  },
  {
    id: 'title',
    label: 'Título',
    minWidth: 200,
    align: 'left',
    render: (row) => <Typography variant="body2">{row.title}</Typography>,
  },
  {
    id: 'message',
    label: 'Contenido',
    minWidth: 200,
    align: 'left',
    render: (row) => <Typography variant="body2">{row.message}</Typography>,
  },
  {
    id: 'created_at',
    label: 'Fecha',
    minWidth: 150,
    // align: 'left',
    align: 'right',
    render: (row) => formatDateAndTime(row.created_at),
  },
  // THIS FEATURE BELLOW WON'T BE USED FOR NOW. MAYBE LATER.
  // {
  //   id: 'readed',
  //   label: 'Estado',
  //   minWidth: 100,
  //   align: 'right',
  //   render: (row) => <ReadCheckMark row={row} />,
  // },
];

export default function NotificationTableBody() {
  const dispatch = useAppDispatch();

  const { loading, sort_column, sort_direction } = useNotificationsSelector(
    (state) => state.notifications
  );

  const [isCollapse] = useState<boolean>(false);

  const handleRequestSort = (
    e: React.MouseEvent<unknown>,
    property: keyof INotification
  ) => {
    // setting sort direction
    const isAsc = sort_column === property && sort_direction === 'asc';
    dispatch(setSortDirection(isAsc ? 'desc' : 'asc'));
    // setting sort column
    dispatch(setSortColumn(property));
  };

  return (
    <TableBody loading={loading}>
      <NotificationTableBodyHeader
        onRequestSort={handleRequestSort}
        columns={columns}
        isCollapse={isCollapse}
      />
      <NotificationTableBodyContainer
        columns={columns}
        isCollapse={isCollapse}
      />
    </TableBody>
  );
}
