import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios';
import { logout } from './';
import { httpHeader, httpConfigBuffer } from './auth';

// set enviroment variable for production or development
export const getEnvironmentVar = () => {
  if (process.env.NODE_ENV === 'production')
    return process.env.REACT_APP_PROD_API;
  return process.env.REACT_APP_DEV_API;
};

// axios methods interface
interface IAxios extends AxiosInstance {
  get<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T = unknown>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T>;
  delete<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
  put<T = unknown>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T>;
}

const API: IAxios = axios.create({
  baseURL: getEnvironmentVar(),
  headers: httpHeader(),
});

API.interceptors.request.use(
  (config) => {
    config.headers = httpHeader();
    return config;
  },
  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response: AxiosResponse): unknown => response.data,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      // auto logout if 401 response returned from API
      logout();
    }
    return Promise.reject(error.response.data);
  }
);

const API_BUFFER: IAxios = axios.create({
  baseURL: getEnvironmentVar(),
  ...httpConfigBuffer(),
});

API_BUFFER.interceptors.request.use(
  (config) => {
    config.headers = httpConfigBuffer().headers;
    return config;
  },
  (error) => Promise.reject(error)
);

API_BUFFER.interceptors.response.use(
  (response: AxiosResponse): unknown => response.data,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      // auto logout if 401 response returned from API
      logout();
    }
    if (
      error.response.headers?.['content-type']?.includes?.('application/json')
    ) {
      try {
        const data = String.fromCharCode.apply(
          '',
          Array.from(new Uint8Array(error.response.data))
        );
        return Promise.reject(JSON.parse(data));
      } catch (error) {
        // ignore
      }
    }
    return Promise.reject(error.response.data);
  }
);

export { API, API_BUFFER };
