import { Box, Grid, Divider, Container, Typography } from '@material-ui/core';
// hooks
import { useIsMobile } from '../../../../../../hooks/useIsMobile';
// image
// import logo from '../../../../../../images/logo.png';

interface TitleProps {
  classes: any;
}

export default function Title({ classes }: TitleProps) {
  const { isMobile } = useIsMobile();

  return (
    <Container className={classes.container}>
      <Grid container justify="center">
        <Box pt={20} display="inline">
          {/* <img alt="logo" src={logo} className={classes.image} /> */}
          <Typography variant={isMobile ? 'h4' : 'h2'}>
            {'Liber App'}
          </Typography>
        </Box>
      </Grid>
      <Divider variant="middle" />
    </Container>
  );
}
