import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { IFile, IBreadcrumb } from '../../interfaces';
import { DEF_LIMIT, DEF_OFFSET, ROOT_FOLDER_NAME } from '../../constants';
import { getRootFolderId } from '../../utils';
import { Order } from '../../types';
import * as driveSvcs from '../../api/drive.services';

// Interfaces
interface IDriveState {
  loading: boolean;
  error: boolean | string;
  files: IFile[];
  file: IFile | null;
  actionLoading: boolean;
  nextPageToken: string[];
  offset: number;
  limit: number;
  filter: string;
  breadcrumbs: IBreadcrumb[];
  sort_column: keyof IFile;
  sort_direction: Order;
}

// Initial state
const initialState: IDriveState = {
  loading: false,
  error: false,
  files: [],
  file: null,
  actionLoading: false,
  nextPageToken: [''],
  offset: DEF_OFFSET,
  limit: DEF_LIMIT,
  filter: '',
  breadcrumbs: [
    {
      folderId: getRootFolderId(),
      folderName: ROOT_FOLDER_NAME,
    },
  ],
  sort_column: 'name',
  sort_direction: 'asc',
};

// Asynchronous thunk actions
export const fetchDriveItems = createAsyncThunk(
  'drive/fetchDriveItems',
  ({
    limit,
    filter,
    folder_id,
    sort_column,
    sort_direction,
    nextPageToken,
  }: {
    limit: number;
    filter: string;
    folder_id: string;
    sort_column: string;
    sort_direction: string;
    nextPageToken: string;
  }) => {
    return driveSvcs.fetchDriveItems(
      nextPageToken,
      limit,
      filter,
      folder_id,
      sort_column,
      sort_direction
    );
  }
);

export const fetchDriveItemById = createAsyncThunk(
  'drive/fetchDriveItemById',
  (fileId: string) => {
    return driveSvcs.fetchDriveItemById(fileId);
  }
);

export const fetchDriveFolders = createAsyncThunk(
  'drive/fetchDriveFolders',
  ({
    filter = initialState.filter,
    folder_id = getRootFolderId(),
    nextPageToken = '',
  }: {
    filter?: string;
    folder_id?: string;
    nextPageToken?: string;
  }) => {
    return driveSvcs.fetchDriveFolders(nextPageToken, filter, folder_id);
  }
);

export const getFile = createAsyncThunk(
  'drive/getFile',
  ({
    fileId,
    mimeType,
    name,
  }: {
    fileId: string;
    mimeType: string;
    name: string;
  }) => {
    return driveSvcs.getFile(fileId, mimeType, name);
  }
);

// Slice
const drive = createSlice({
  name: 'drive',
  initialState,
  reducers: {
    setOffset(state: IDriveState, { payload }: PayloadAction<number>) {
      state.offset = payload;
    },
    setLimit(state: IDriveState, { payload }: PayloadAction<number>) {
      state.limit = payload;
    },
    setFilter(state: IDriveState, { payload }: PayloadAction<string>) {
      state.filter = payload;
      state.offset = initialState.offset;
    },
    goForward(state: IDriveState, { payload }: PayloadAction<IBreadcrumb>) {
      state.breadcrumbs.push(payload);
    },
    goBack(state: IDriveState, { payload }: PayloadAction<string>) {
      state.breadcrumbs = state.breadcrumbs.slice(
        0,
        state.breadcrumbs.findIndex((row) => row.folderId === payload) + 1
      );
    },
    setSortColumn(state: IDriveState, { payload }: PayloadAction<keyof IFile>) {
      state.sort_column = payload;
    },
    setSortDirection(state: IDriveState, { payload }: PayloadAction<Order>) {
      state.sort_direction = payload;
    },
    setNextPageToken(state: IDriveState, { payload }: PayloadAction<string[]>) {
      state.nextPageToken = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDriveItems.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchDriveItems.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.files = action.payload.files;
      state.nextPageToken.push(action.payload.nextPageToken);
    });
    builder.addCase(fetchDriveItems.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(fetchDriveItemById.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchDriveItemById.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.file = action.payload;
    });
    builder.addCase(fetchDriveItemById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(fetchDriveFolders.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchDriveFolders.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.files = action.payload.files;
      state.nextPageToken.push(action.payload.nextPageToken);
    });
    builder.addCase(fetchDriveFolders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(getFile.pending, (state) => {
      state.actionLoading = true;
      state.error = false;
    });
    builder.addCase(getFile.fulfilled, (state, action) => {
      state.actionLoading = false;
      state.error = false;
    });
    builder.addCase(getFile.rejected, (state, action) => {
      state.actionLoading = false;
      state.error = action.error.message as string | boolean;
    });
  },
});

// Actions generated from the slice
export const {
  setLimit,
  setOffset,
  setFilter,
  goForward,
  goBack,
  setSortColumn,
  setSortDirection,
  setNextPageToken,
} = drive.actions;

// Selector
export const useDriveSelector: TypedUseSelectorHook<RootState> = useSelector;

// Reducer
export default drive.reducer;
