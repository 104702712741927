import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
// global state
import { useAuthSelector } from '../features/auth/authSlice';
// layout
import Layout from '../layout';
// children components
import { GlobalSnackbar } from '../utils';
// constants
import {
  LOGIN_ROUTE,
  PROFILE_ROUTE,
  HOME_ROUTE,
  CLIENTS_ROUTE,
  TAXPAYERS_ROUTE,
  DIRECTORY_ROUTE,
  TAXES_ROUTE,
  NOTIFICATIONS_ROUTE,
  RESET_PSSWD_ROUTE,
} from '../constants';
// common
import { PrivateRoute, Loading } from '../common';
// pages
import { Login, NotFound, ResetPassword } from '../pages';
// lazy import
const Home = lazy(() =>
  import('../pages/').then((module) => ({
    default: module.Home,
  }))
);
const Profile = lazy(() =>
  import('../pages/').then((module) => ({
    default: module.Profile,
  }))
);
const Clients = lazy(() =>
  import('../pages/').then((module) => ({
    default: module.Clients,
  }))
);
const Taxpayers = lazy(() =>
  import('../pages/').then((module) => ({
    default: module.Taxpayers,
  }))
);
const Directory = lazy(() =>
  import('../pages/').then((module) => ({
    default: module.Directory,
  }))
);
const Taxes = lazy(() =>
  import('../pages/').then((module) => ({
    default: module.Taxes,
  }))
);
const Notifications = lazy(() =>
  import('../pages/').then((module) => ({
    default: module.Notifications,
  }))
);

function App(): JSX.Element {
  const { loggedIn } = useAuthSelector((state) => state.auth);

  return (
    <Layout>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/">
              {<Redirect to={LOGIN_ROUTE} />}
            </Route>
            <Route exact path={LOGIN_ROUTE}>
              {loggedIn ? <Redirect to={HOME_ROUTE} /> : <Login />}
            </Route>
            <PrivateRoute component={Profile} path={PROFILE_ROUTE} />
            <PrivateRoute component={Home} path={HOME_ROUTE} />
            <PrivateRoute component={Clients} path={CLIENTS_ROUTE} />
            <PrivateRoute component={Taxpayers} path={TAXPAYERS_ROUTE} />
            <PrivateRoute component={Directory} path={DIRECTORY_ROUTE} />
            <PrivateRoute component={Taxes} path={TAXES_ROUTE} />
            <PrivateRoute
              component={Notifications}
              path={NOTIFICATIONS_ROUTE}
            />
            <Route component={ResetPassword} path={RESET_PSSWD_ROUTE} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </BrowserRouter>
      <GlobalSnackbar />
    </Layout>
  );
}

export default App;
