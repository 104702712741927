import React, { useState, useCallback, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Dialog,
  FormControl,
  TextField,
  Button,
  Box,
  DialogContentText,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import debounce from 'lodash.debounce';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  useTaxpayersSelector,
  linkFolderWithTaxpayer,
  fetchLinkedTaxpayer,
} from '../../../../../features/taxpayers/taxpayersSlice';
// api
import { fetchTaxpayers } from '../../../../../api/taxpayer.services';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// components
import {
  FormHeader,
  FormBody,
  FormFooter,
  StyledGrid,
} from '../../common/Forms';
// interface
import { IFile, IResponse, ITaxpayer } from '../../../../../interfaces';
// hooks
import { useIsMobile } from '../../../../../hooks/useIsMobile';

interface LinkFolderWithTaxpayerDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  row: IFile;
}

export default function LinkFolderWithTaxpayerDialog({
  openDialog,
  setOpenDialog,
  row,
}: LinkFolderWithTaxpayerDialogProps) {
  const dispatch = useAppDispatch();

  interface ILinkTaxpayer {
    taxpayer_uuid: string;
  }

  const {
    dispatch: { setSnackbar, errorSnackbar },
  } = useSnackbar();

  const { isMobile } = useIsMobile();

  const {
    actionLoading,
    offset,
    limit,
    month,
    showDisabled,
    sort_column,
    sort_direction,
  } = useTaxpayersSelector((state) => state.taxpayers);

  const [taxpayers, setTaxpayers] = useState<ITaxpayer[]>([]);

  const [selectedTaxpayer, setSelectedTaxpayer] = useState<ITaxpayer | null>(
    null
  );

  const [helperText, setHelperText] = useState('');

  const [folderInfo, setFolderInfo] = useState<IResponse>({
    message: '',
    data: null,
  });

  const handleFetchLinkedTaxpayer = async () => {
    try {
      const folderId = row.id;
      const resultAction = await dispatch(fetchLinkedTaxpayer(folderId));
      const res = unwrapResult(resultAction);
      setFolderInfo({ message: res.message, data: res.data });
    } catch (err: any) {
      setHelperText(`❌ ${err.message}`);
      errorSnackbar();
    }
  };

  useEffect(() => {
    if (openDialog) {
      handleFetchLinkedTaxpayer();
    }
  }, [openDialog]);

  const validationSchema = Yup.object({
    taxpayer_uuid: Yup.string().required(`Requerido!`),
  });

  const handleSubmit = async (taxpayerData: ILinkTaxpayer) => {
    try {
      const taxpayerUuid = taxpayerData.taxpayer_uuid;
      const folderData = { folder_id: row.id, folder_name: row.name };
      const resultAction = await dispatch(
        linkFolderWithTaxpayer({ taxpayerUuid, folderData })
      );
      const res = unwrapResult(resultAction);
      setSnackbar(true, res.message);
      handleClose();
    } catch (err: any) {
      setHelperText(`❌ ${err.message}`);
      errorSnackbar();
    }
  };

  const initialValues: ILinkTaxpayer = {
    taxpayer_uuid: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const handleResetError = () => {
    setHelperText('');
    formik.setErrors({});
  };

  const handleClose = () => {
    formik.setValues(formik.initialValues);
    setOpenDialog(false);
    handleResetError();
    setFolderInfo({
      message: '',
      data: null,
    });
    setTaxpayers([]);
    setSelectedTaxpayer(null);
  };

  const handleFetchTaxpayers = async (filter: string) => {
    if (filter) {
      try {
        const taxpayers = await fetchTaxpayers(
          offset,
          limit,
          month,
          filter,
          showDisabled,
          sort_column,
          sort_direction
        );
        setTaxpayers(taxpayers);
      } catch (err: any) {
        errorSnackbar();
      }
    }
  };

  const debounceTaxpayer = useCallback(debounce(handleFetchTaxpayers, 300), []);

  const handleTaxpayerAutocompleteInputChange = (
    e: React.ChangeEvent<{}>,
    filter: string | null,
    reason: string
  ) => {
    if (reason === 'clear') {
      filter = '';
    }
    if (typeof filter === 'string') {
      handleResetError();
      debounceTaxpayer(filter);
    }
  };

  const handleTaxpayerAutocompleteChange = (
    e: React.ChangeEvent<{}>,
    data: ITaxpayer | null,
    reason: string
  ) => {
    if (data) {
      if (reason === 'clear') {
        data = {} as ITaxpayer;
      }
      setSelectedTaxpayer(data);
      formik.setFieldValue('taxpayer_uuid', data.uuid);
    }
    handleResetError();
  };

  return (
    <Dialog
      open={openDialog}
      keepMounted
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="simple-dialog"
    >
      <FormControl component="fieldset" error={!!helperText}>
        <form onSubmit={formik.handleSubmit}>
          <FormHeader handleClose={handleClose}>
            {`Vincular carpeta con contribuyente 🔗`}
          </FormHeader>
          <FormBody loading={actionLoading} helperText={helperText}>
            <>
              <StyledGrid item xs={12} sm={12}>
                <Box mx={5}>
                  <DialogContentText>
                    {folderInfo.message
                      ? `${folderInfo.data ? '⚠️' : ''} ${folderInfo.message}\n`
                      : '\n'}
                    {`${
                      folderInfo.data
                        ? 'Seleccione un nuevo contribuyente para revincular'
                        : 'Seleccione un contribuyente para vincular'
                    } al directorio`}
                    <Box fontWeight="bold" display="inline">
                      {` '${row.name}'.`}
                    </Box>
                  </DialogContentText>
                </Box>
              </StyledGrid>
              <StyledGrid item xs={12} sm={12}>
                <Box pt={5} px={isMobile ? 0 : 12}>
                  <Autocomplete
                    id="taxpayer_uuid"
                    size="small"
                    options={taxpayers}
                    getOptionLabel={(option) => option.company_name}
                    value={selectedTaxpayer}
                    onChange={handleTaxpayerAutocompleteChange}
                    onInputChange={handleTaxpayerAutocompleteInputChange}
                    noOptionsText="Sin resultados"
                    style={{ width: isMobile ? 230 : 350 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Contribuyente *"
                        variant="outlined"
                        helperText={
                          formik.errors.taxpayer_uuid
                            ? formik.errors.taxpayer_uuid
                            : 'Escriba para buscar un contribuyente'
                        }
                        error={!!formik.errors.taxpayer_uuid}
                      />
                    )}
                  />
                </Box>
              </StyledGrid>
            </>
          </FormBody>
          <FormFooter>
            <Button autoFocus type="submit" color="primary" variant="contained">
              {'Confirmar'}
            </Button>
          </FormFooter>
        </form>
      </FormControl>
    </Dialog>
  );
}
