import React, { useState } from 'react';
// global state
import { useAppDispatch } from '../../../../../../../app/store';
import {
  useTaxesSelector,
  setSortColumn,
  setSortDirection,
} from '../../../../../../../features/taxes/taxesSlice';
// interface
import { IColumn, ITax } from '../../../../../../../interfaces';
// components
import { TableBody } from '../../../../common/Table/Body';
// children components
import TaxTableBodyHeader from './content/TaxTableBodyHeader';
import TaxTableBodyContainer from './content/TaxTableBodyContainer';

const columns: IColumn<ITax>[] = [
  { id: 'name', label: 'Nombre', minWidth: 100, align: 'left' },
];

export default function TaxTableBody() {
  const dispatch = useAppDispatch();

  const { loading, sort_column, sort_direction } = useTaxesSelector(
    (state) => state.taxes
  );

  const [isCollapse] = useState<boolean>(false);

  const handleRequestSort = (
    e: React.MouseEvent<unknown>,
    property: keyof ITax
  ) => {
    // setting sort direction
    const isAsc = sort_column === property && sort_direction === 'asc';
    dispatch(setSortDirection(isAsc ? 'desc' : 'asc'));
    // setting sort column
    dispatch(setSortColumn(property));
  };

  return (
    <TableBody loading={loading}>
      <TaxTableBodyHeader
        onRequestSort={handleRequestSort}
        columns={columns}
        isCollapse={isCollapse}
      />
      <TaxTableBodyContainer columns={columns} isCollapse={isCollapse} />
    </TableBody>
  );
}
