import { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Grid,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
// global state
import { useTaxesSelector } from '../../../../../../../features/taxes/taxesSlice';
// components
import { TableHeader } from '../../../../common/Table/Header';
// components
import UpdateTaxesButton from './content/UpdateTaxesButton';
import EditTaxButton from './content/EditTaxButton';
import DeleteTaxButton from './content/DeleteTaxButton';
import SearchFieldTax from './content/SearchFieldTax';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingInline: theme.spacing(1),
    },
    head: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  })
);

export default function TaxTableHeader() {
  const classes = useStyles();

  const { tax } = useTaxesSelector((state) => state.taxes);

  const [title, setTitle] = useState('');

  useEffect(() => {
    if (tax) {
      setTitle(tax.name);
    }
  }, [tax]);

  return (
    <TableHeader title={title} isItemSelected={!!tax}>
      <>
        <Grid
          container
          className={classes.container}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12} sm={4} className={classes.container}>
            <Typography variant="h5"> {'Impuestos'}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.container}>
            <Box display="flex" justifyContent="flex-end">
              <UpdateTaxesButton />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.container}>
            <SearchFieldTax />
          </Grid>
        </Grid>
      </>

      <>
        <Grid
          container
          className={classes.container}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box className={classes.head}>
            <EditTaxButton />
            <DeleteTaxButton />
          </Box>
        </Grid>
      </>
    </TableHeader>
  );
}
