import { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Typography,
  FormHelperText,
  FormControl,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// global state
import { useAppDispatch } from '../../../app/store';
import {
  signIn,
  signOut,
  useAuthSelector,
} from '../../../features/auth/authSlice';
// interfaces
import { ILoginForm } from '../../../interfaces';
// context
import { useSnackbar } from '../../../context/SnackbarContext';
// utils
import { removeSpace } from '../../../utils';
// constant
import {
  MIN_LEN_PSSWD,
  MAX_LEN_PSSWD,
  MIN_LEN_EMAIL,
  MAX_LEN_EMAIL,
  ADMIN_ROLE,
} from '../../../constants';

interface LoginFormProps {
  classes: any;
}

export default function LoginForm({ classes }: LoginFormProps): JSX.Element {
  const dispatch = useAppDispatch();

  const {
    dispatch: { setSnackbar },
  } = useSnackbar();

  const { loading } = useAuthSelector((state) => state.auth);

  const [remember, setRemember] = useState(false);

  const [helperText, setHelperText] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Email invalido')
      .required('Requerido!')
      .min(MIN_LEN_EMAIL, 'email muy corto.')
      .max(MAX_LEN_EMAIL, 'Muy largo'),
    password: Yup.string()
      .required('Requerido!')
      .min(MIN_LEN_PSSWD, 'Contraseña muy corta.')
      .max(MAX_LEN_PSSWD, 'Muy largo'),
  });

  const handleSubmit = async (formData: ILoginForm) => {
    try {
      const resultAction = await dispatch(signIn({ formData, remember }));
      const res = unwrapResult(resultAction);
      // if logged user is not admin, then signout
      if (res.role !== ADMIN_ROLE) {
        setSnackbar(true, `❌ Acceso denegado.`);
        dispatch(signOut());
      }
    } catch (err: any) {
      setHelperText(`❌ ${err.message}`);
      formik.setValues({
        ...formik.values,
        password: formik.initialValues.password,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleResetError = () => {
    setHelperText('');
    formik.setErrors({});
  };

  const handleCheck = () => {
    setRemember(!remember);
  };

  return (
    <FormControl component="fieldset" error={!!helperText}>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          id="email"
          name="email"
          value={formik.values.email}
          onChange={(e) => {
            formik.setFieldValue('email', removeSpace(e.target.value));
            handleResetError();
          }}
          fullWidth
          label="Email"
          variant="outlined"
          margin="normal"
          autoComplete="email"
          inputProps={{
            maxLength: MAX_LEN_EMAIL,
          }}
          InputProps={{
            endAdornment: (
              <EmailRoundedIcon fontSize="default" className={classes.icon} />
            ),
          }}
          helperText={formik.errors.email}
          error={!!formik.errors.email}
        />
        <TextField
          id="password"
          name="password"
          value={formik.values.password}
          onChange={(e) => {
            formik.handleChange(e);
            handleResetError();
          }}
          fullWidth
          label="Contraseña"
          variant="outlined"
          margin="normal"
          autoComplete="current-password"
          inputProps={{
            maxLength: MAX_LEN_PSSWD,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle-password-visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityRoundedIcon
                      fontSize="default"
                      className={classes.icon}
                    />
                  ) : (
                    <VisibilityOffRoundedIcon
                      fontSize="default"
                      className={classes.icon}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          type={showPassword ? 'text' : 'password'}
          helperText={formik.errors.password}
          error={!!formik.errors.password}
        />
        <FormControlLabel
          control={
            <Checkbox value={remember} onChange={handleCheck} color="primary" />
          }
          label="Recordarme"
        />
        <FormHelperText>
          <Typography>{helperText}</Typography>
        </FormHelperText>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {loading ? 'Ingresando ...' : 'Ingresar'}
        </Button>
      </form>
    </FormControl>
  );
}
