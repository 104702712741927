import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { ITaxpayer, IFolder } from '../../interfaces';
import { DEF_LIMIT, DEF_OFFSET } from '../../constants';
import { Order, Month } from '../../types';
import * as taxpayerSvcs from '../../api/taxpayer.services';

// Interfaces
interface ITaxpayersState {
  loading: boolean;
  error: boolean | string;
  taxpayers: ITaxpayer[];
  taxpayer: ITaxpayer | null;
  actionLoading: boolean;
  checkLoading: boolean;
  actionError: boolean | string;
  showDisabled: boolean;
  offset: number;
  limit: number;
  month: Month;
  filter: string;
  sort_column: keyof ITaxpayer;
  sort_direction: Order;
}

// Initial state
const initialState: ITaxpayersState = {
  loading: false,
  error: false,
  taxpayers: [],
  taxpayer: null,
  actionLoading: false,
  checkLoading: false,
  actionError: false,
  showDisabled: true,
  offset: DEF_OFFSET,
  limit: DEF_LIMIT,
  month: null,
  filter: '',
  sort_column: 'company_name',
  sort_direction: 'asc',
};

// Asynchronous thunk actions
export const fetchTaxpayers = createAsyncThunk(
  'taxpayer/fetchTaxpayers',
  ({
    offset = initialState.offset,
    limit = initialState.limit,
    month = initialState.month,
    filter,
    showDisabled = initialState.showDisabled,
    sort_column = initialState.sort_column,
    sort_direction = initialState.sort_direction,
  }: {
    offset?: number;
    limit?: number;
    month?: Month;
    filter: string;
    showDisabled?: boolean;
    sort_column?: string;
    sort_direction?: string;
  }) => {
    return taxpayerSvcs.fetchTaxpayers(
      offset,
      limit,
      month,
      filter,
      showDisabled,
      sort_column,
      sort_direction
    );
  }
);

export const fetchClientTaxpayers = createAsyncThunk(
  'taxpayers/fetchClientTaxpayers',
  (clientUuid: string) => {
    return taxpayerSvcs.fetchClientTaxpayers(clientUuid);
  }
);

export const fetchTaxTaxpayers = createAsyncThunk(
  'taxpayers/fetchTaxTaxpayers',
  (taxUuid: string) => {
    return taxpayerSvcs.fetchTaxTaxpayers(taxUuid);
  }
);

export const fetchTaxpayerById = createAsyncThunk(
  'taxpayers/fetchTaxpayerById',
  (taxpayerUuid: string) => {
    return taxpayerSvcs.fetchTaxpayerByUuid(taxpayerUuid);
  }
);

export const createTaxpayer = createAsyncThunk(
  'taxpayers/createTaxpayer',
  (taxpayerData: FormData) => {
    return taxpayerSvcs.createTaxpayer(taxpayerData);
  }
);

export const updateTaxpayer = createAsyncThunk(
  'taxpayers/updateTaxpayer',
  ({
    taxpayerUuid,
    taxpayerData,
    removeLogo = false,
  }: {
    taxpayerUuid: string;
    taxpayerData: FormData;
    removeLogo?: boolean;
  }) => {
    return taxpayerSvcs.updateTaxpayer(taxpayerUuid, taxpayerData, removeLogo);
  }
);

export const toggleTaxpayerStatus = createAsyncThunk(
  'taxpayers/toggleTaxpayerStatus',
  ({ taxpayerUuid, reason }: { taxpayerUuid: string; reason: string }) => {
    return taxpayerSvcs.toggleTaxpayerStatus(taxpayerUuid, reason);
  }
);

export const deleteTaxpayer = createAsyncThunk(
  'taxpayers/deleteTaxpayer',
  (taxpayerUuid: string) => {
    return taxpayerSvcs.deleteTaxpayer(taxpayerUuid);
  }
);

export const fetchManagers = createAsyncThunk(
  'taxpayers/fetchManagers',
  (filter: string) => {
    return taxpayerSvcs.fetchManagers(filter);
  }
);

export const linkFolderWithTaxpayer = createAsyncThunk(
  'taxpayers/linkFolderWithTaxpayer',
  ({
    taxpayerUuid,
    folderData,
  }: {
    taxpayerUuid: string;
    folderData: IFolder;
  }) => {
    return taxpayerSvcs.linkFolderWithTaxpayer(taxpayerUuid, folderData);
  }
);

export const fetchLinkedTaxpayer = createAsyncThunk(
  'taxpayers/fetchLinkedTaxpayer',
  (folderId: string) => {
    return taxpayerSvcs.fetchLinkedTaxpayer(folderId);
  }
);

// Slice
const taxpayers = createSlice({
  name: 'taxpayers',
  initialState,
  reducers: {
    selectTaxpayer(
      state: ITaxpayersState,
      { payload }: PayloadAction<ITaxpayer | null>
    ) {
      state.taxpayer = payload;
    },
    resetTaxpayers(state: ITaxpayersState) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.taxpayers = initialState.taxpayers;
    },
    toggleShowDisabled(state: ITaxpayersState) {
      state.showDisabled = !state.showDisabled;
    },
    setOffset(state: ITaxpayersState, { payload }: PayloadAction<number>) {
      state.offset = payload;
    },
    setLimit(state: ITaxpayersState, { payload }: PayloadAction<number>) {
      state.limit = payload;
    },
    setMonth(state: ITaxpayersState, { payload }: PayloadAction<Month>) {
      state.month = payload;
    },
    setFilter(state: ITaxpayersState, { payload }: PayloadAction<string>) {
      state.filter = payload;
      state.offset = initialState.offset;
    },
    setSortColumn(
      state: ITaxpayersState,
      { payload }: PayloadAction<keyof ITaxpayer>
    ) {
      state.sort_column = payload;
    },
    setSortDirection(
      state: ITaxpayersState,
      { payload }: PayloadAction<Order>
    ) {
      state.sort_direction = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTaxpayers.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchTaxpayers.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.taxpayers = action.payload;
    });
    builder.addCase(fetchTaxpayers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(fetchClientTaxpayers.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchClientTaxpayers.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.taxpayers = action.payload;
    });
    builder.addCase(fetchClientTaxpayers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(fetchTaxTaxpayers.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchTaxTaxpayers.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.taxpayers = action.payload;
    });
    builder.addCase(fetchTaxTaxpayers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(fetchTaxpayerById.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(fetchTaxpayerById.fulfilled, (state) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(fetchTaxpayerById.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(createTaxpayer.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(createTaxpayer.fulfilled, (state, action) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(createTaxpayer.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(updateTaxpayer.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(updateTaxpayer.fulfilled, (state) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(updateTaxpayer.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(toggleTaxpayerStatus.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(toggleTaxpayerStatus.fulfilled, (state) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(toggleTaxpayerStatus.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(deleteTaxpayer.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(deleteTaxpayer.fulfilled, (state) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(deleteTaxpayer.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(linkFolderWithTaxpayer.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(linkFolderWithTaxpayer.fulfilled, (state) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(linkFolderWithTaxpayer.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(fetchLinkedTaxpayer.pending, (state) => {
      state.checkLoading = true;
      state.actionError = false;
    });
    builder.addCase(fetchLinkedTaxpayer.fulfilled, (state) => {
      state.checkLoading = false;
      state.actionError = false;
    });
    builder.addCase(fetchLinkedTaxpayer.rejected, (state, action) => {
      state.checkLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
  },
});

// Actions generated from the slice
export const {
  selectTaxpayer,
  resetTaxpayers,
  toggleShowDisabled,
  setLimit,
  setMonth,
  setOffset,
  setFilter,
  setSortColumn,
  setSortDirection,
} = taxpayers.actions;

// Selector
export const useTaxpayersSelector: TypedUseSelectorHook<RootState> =
  useSelector;

// Reducer
export default taxpayers.reducer;
