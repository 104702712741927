import React, { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Dialog,
  FormControl,
  TextField,
  Button,
  Box,
  Link,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { saveAs } from 'file-saver';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  fetchTaxes,
  useTaxesSelector,
  uploadExcel,
  downloadExcel,
  fetchUploadedExpiration,
} from '../../../../../features/taxes/taxesSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// interface
import { ITaxForm, IResponse } from '../../../../../interfaces';
// constants
import {
  YEAR_LENGTH,
  EXCEL_FORMAT,
  EXCEL_TITLE,
} from '../../../../../constants';
// utils
import { NumberFormatYear } from '../../../../../utils';
// components
import {
  FormHeader,
  FormBody,
  FormFooter,
  StyledGrid,
} from '../../common/Forms';
import UploadWarningDialog from './UploadContent/UploadWarningDialog';

interface TaxesUpdateDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TaxesUpdateDialog({
  openDialog,
  setOpenDialog,
}: TaxesUpdateDialogProps) {
  const dispatch = useAppDispatch();

  const {
    dispatch: { setSnackbar, errorSnackbar },
  } = useSnackbar();

  const { actionLoading, offset, limit, filter, sort_column, sort_direction } =
    useTaxesSelector((state) => state.taxes);

  const [helperText, setHelperText] = useState('');

  const [openWarningDialog, setOpenWarningDialog] = React.useState(false);

  const [expirationInfo, setExpirationInfo] = useState<IResponse>({
    message: '',
    data: null,
  });

  const [selectedExcel, setSelectedExcel] = useState<File | null>(null);

  const validationSchema = Yup.object().shape({
    excel: Yup.mixed().required('Requerido!'),
    year: Yup.string()
      .required(`Requerido!`)
      .min(YEAR_LENGTH, `${YEAR_LENGTH} caracteres son necesarios.`)
      .max(YEAR_LENGTH, `${YEAR_LENGTH} caracteres son necesarios.`),
  });

  const currentYear = new Date().getFullYear();

  const initialValues: ITaxForm = {
    excel: null,
    year: currentYear,
  };

  const handleUploadTaxes = async (excelData: ITaxForm) => {
    const formData = new FormData();
    if (excelData.excel) {
      formData.append('excel', excelData.excel);
    }
    const year = excelData.year.toString();
    try {
      const resultAction = await dispatch(
        uploadExcel({ excelData: formData, year })
      );
      const res = unwrapResult(resultAction);
      setSnackbar(true, res.message);
      dispatch(
        fetchTaxes({
          offset,
          limit,
          filter,
          sort_column,
          sort_direction,
        })
      );
      handleClose();
    } catch (err: any) {
      setHelperText(`❌ ${err.message}`);
      errorSnackbar();
    }
  };

  const isYearAlreadyUpload = async (year: string): Promise<boolean> => {
    try {
      const resultAction = await dispatch(fetchUploadedExpiration(year));
      const res = unwrapResult(resultAction);
      setExpirationInfo({ message: res.message, data: res.data });
      if (res.data) {
        return true;
      }
    } catch (err: any) {
      errorSnackbar();
    }
    return false;
  };

  const handleConfirmUploadTaxes = () => {
    handleUploadTaxes(formik.values);
  };

  const handleSubmit = async () => {
    const res = await isYearAlreadyUpload(formik.values.year.toString());
    if (res) {
      setOpenWarningDialog(true);
    } else {
      handleConfirmUploadTaxes();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const handleResetError = () => {
    setHelperText('');
    formik.setErrors({});
  };

  const handleClose = () => {
    formik.setValues(formik.initialValues);
    setOpenDialog(false);
    setSelectedExcel(null);
    handleResetError();
  };

  const handleUploadExcelFile = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files[0].name.match(EXCEL_FORMAT)) {
      try {
        // saving blob
        formik.setFieldValue('excel', e.target.files[0]);
        setSelectedExcel(e.target.files[0]);
      } catch (err) {
        errorSnackbar();
      }
    }
    handleResetError();
    // reset the input field so if file is removed it can re-add the same file.
    e.target.value = '';
  };

  const handleDownload = async () => {
    try {
      const resultAction = await dispatch(downloadExcel());
      const payload = unwrapResult(resultAction);
      const downloadedFile = new Blob([payload as ArrayBuffer]);
      saveAs(downloadedFile, EXCEL_TITLE);
    } catch (err: any) {
      setHelperText(`❌ ${err.message}`);
      setSnackbar(true, `❌ ${err.message}`);
    }
    handleResetError();
  };

  return (
    <Dialog
      open={openDialog}
      keepMounted
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="simple-dialog"
    >
      <FormControl component="fieldset" error={!!helperText}>
        <form onSubmit={formik.handleSubmit}>
          <FormHeader handleClose={handleClose}>
            {`Actualizar impuestos 🧾`}
          </FormHeader>
          <FormBody loading={actionLoading} helperText={helperText}>
            <>
              <StyledGrid item xs={12} sm={6}>
                <Box pb={3}>
                  <Button
                    variant="outlined"
                    component="label"
                    color="primary"
                    style={{ width: 230 }}
                  >
                    {`Cargar excel`}
                    <input
                      type="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      hidden
                      onChange={handleUploadExcelFile}
                    />
                  </Button>
                </Box>
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <Box mb={2}>
                  <Typography variant="subtitle2">
                    {selectedExcel
                      ? selectedExcel.name
                      : 'Ningún archivo cargado'}
                  </Typography>
                  <Typography variant="caption" color="error">
                    {formik.errors.excel ? formik.errors.excel : ' '}
                  </Typography>
                </Box>
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <TextField
                  id="year"
                  name="year"
                  label="Año *"
                  variant="outlined"
                  size="small"
                  value={formik.values.year}
                  onChange={(e) => {
                    formik.setFieldValue('year', e.target.value);
                    handleResetError();
                  }}
                  autoComplete="off"
                  style={{ width: 230 }}
                  InputProps={{
                    inputComponent: NumberFormatYear as any,
                  }}
                  helperText={formik.errors.year ? formik.errors.year : ' '}
                  error={!!formik.errors.year}
                />
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <Box pb={2}>
                  <Link
                    component="button"
                    variant="body2"
                    type="button"
                    onClick={handleDownload}
                  >
                    {'Descargar template'}
                  </Link>
                </Box>
              </StyledGrid>
            </>
          </FormBody>
          <FormFooter>
            <Button autoFocus type="submit" color="primary" variant="contained">
              {'Confirmar'}
            </Button>
          </FormFooter>
        </form>
      </FormControl>

      <UploadWarningDialog
        expirationInfo={expirationInfo}
        openWarningDialog={openWarningDialog}
        setOpenWarningDialog={setOpenWarningDialog}
        handleConfirmUploadTaxes={handleConfirmUploadTaxes}
      />
    </Dialog>
  );
}
