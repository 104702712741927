import React from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  MenuItem,
  Menu,
  Typography,
  ListItemIcon,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import { saveAs } from 'file-saver';
// global state
import { useAppDispatch } from '../../../../../../../app/store';
import { getFile } from '../../../../../../../features/drive/driveSlice';
// interface
import { IFile } from '../../../../../../../interfaces';
// types
import { DriveMenuOptions } from '../../../../../../../types';
// constants
import {
  FOLDER,
  LINK_FOLDER,
  DOWNLOAD_FILE,
  PDF,
  IMAGE,
  GOOGLE_WORKSPACE,
} from '../../../../../../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemIcon: {
      minWidth: '35px',
    },
    icon: {
      color: theme.palette.secondary.light,
    },
  })
);

export interface DirectoryContextMenuProps {
  handleContextMenu: (e: React.MouseEvent) => void;
  selectedRow: IFile | null;
  setContextMenu: React.Dispatch<
    React.SetStateAction<{
      mouseX: number;
      mouseY: number;
    } | null>
  >;
  contextMenu: {
    mouseX: number;
    mouseY: number;
  } | null;
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DirectoryContextMenu({
  handleContextMenu,
  selectedRow,
  setContextMenu,
  contextMenu,
  openDialog,
  setOpenDialog,
}: DirectoryContextMenuProps) {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleClickOnMenu = async (
    option: DriveMenuOptions,
    row: IFile | null
  ) => {
    if (!row) return;
    if (option === LINK_FOLDER) {
      setOpenDialog(!openDialog);
    }
    if (option === DOWNLOAD_FILE) {
      const resultAction = await dispatch(
        getFile({ fileId: row.id, mimeType: row.mimeType, name: row.name })
      );
      const payload = unwrapResult(resultAction);
      const downloadedFile = new Blob([payload], { type: row.mimeType });
      // if row.mimeType is pdf or image, open file in a new tab
      if (
        PDF.some((el) => row.mimeType.includes(el)) ||
        IMAGE.some((el) => row.mimeType.includes(el))
      ) {
        const fileURL = URL.createObjectURL(downloadedFile);
        const fileWindow = window.open();
        if (fileWindow) fileWindow.location.href = fileURL;
      } else {
        // otherwise download the file
        saveAs(
          downloadedFile,
          // if it's a google workspace document, save with '.pdf' extension
          row.mimeType.includes(GOOGLE_WORKSPACE) ? `${row.name}.pdf` : row.name
        );
      }
    }
    handleClose();
  };

  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
      onContextMenu={handleContextMenu}
    >
      {FOLDER.some((el) => selectedRow?.mimeType.includes(el)) ? (
        <>
          <MenuItem onClick={() => handleClickOnMenu(LINK_FOLDER, selectedRow)}>
            <ListItemIcon className={classes.listItemIcon}>
              <LinkRoundedIcon className={classes.icon} />
            </ListItemIcon>
            <Typography>{'Vincular carpeta con contribuyente'}</Typography>
          </MenuItem>
        </>
      ) : (
        <MenuItem onClick={() => handleClickOnMenu(DOWNLOAD_FILE, selectedRow)}>
          {PDF.some((el) => selectedRow?.mimeType.includes(el)) ||
          IMAGE.some((el) => selectedRow?.mimeType.includes(el)) ? (
            <>
              <ListItemIcon className={classes.listItemIcon}>
                <OpenInNewRoundedIcon className={classes.icon} />
              </ListItemIcon>
              <Typography>{'Abrir en una nueva pestaña'}</Typography>
            </>
          ) : (
            <>
              <ListItemIcon className={classes.listItemIcon}>
                <GetAppRoundedIcon className={classes.icon} />
              </ListItemIcon>
              <Typography>{'Descargar'}</Typography>
            </>
          )}
        </MenuItem>
      )}
    </Menu>
  );
}
