import React, { useState } from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import ForgotPassword from './ForgotPassword';

export default function HelpGrid(): JSX.Element {
  const [openDialog, setOpenDialog] = useState(false);

  const [allowOpenDialog, setAllowOpenDialog] = useState(true);

  const handleClickOpen = () => {
    if (allowOpenDialog) {
      setOpenDialog(true);
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography align="center">
            <Link
              href="#"
              variant="body2"
              align="center"
              onClick={handleClickOpen}
            >
              {'¿Olvidaste tu contraseña?'}
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <ForgotPassword
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        setAllowOpenDialog={setAllowOpenDialog}
      />
    </>
  );
}
