// global state
import { useTaxpayerTypesSelector } from '../features/taxpayers/taxpayerTypesSlice';

export const useTaxpayerTypes = () => {
  const { taxpayerTypes } = useTaxpayerTypesSelector(
    (state) => state.taxpayerTypes
  );

  /**
   * @returns index taxpayerType type
   */
  const indexTaxpayerType = (id: number) => {
    if (taxpayerTypes) {
      const obj = taxpayerTypes.find((role) => role.id === id)!;
      if (obj) return obj.name;
    }
    return '';
  };

  /**
   * @returns taxpayerType id
   */
  const getTaxpayerTypeID = (name: string) => {
    if (taxpayerTypes) {
      const obj = taxpayerTypes.find((role) => role.name === name);
      if (obj) return obj.id;
    }
    return -1;
  };

  return {
    indexTaxpayerType,
    getTaxpayerTypeID,
  };
};
