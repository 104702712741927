import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { IUser } from '../../interfaces';
import * as userSvcs from '../../api/user.services';

// Interfaces
interface IMyUsersState {
  loading: boolean;
  error: boolean | string;
  myUser: IUser | null;
}

// Initial state
const initialState: IMyUsersState = {
  loading: false,
  error: false,
  myUser: null,
};

// Asynchronous thunk actions
export const fetchMyUser = createAsyncThunk('myUser/fetchMyUser', () => {
  return userSvcs.fetchMyUser();
});

// Slice
const myUser = createSlice({
  name: 'myUser',
  initialState,
  reducers: {
    resetMyUser(state: IMyUsersState) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.myUser = initialState.myUser;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMyUser.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchMyUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.myUser = action.payload;
    });
    builder.addCase(fetchMyUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
  },
});

// Actions generated from the slice
export const { resetMyUser } = myUser.actions;

// Selector
export const useMyUserSelector: TypedUseSelectorHook<RootState> = useSelector;

// Reducer
export default myUser.reducer;
