import { withStyles, createStyles, TableCell } from '@material-ui/core';

const StyledTableCell = withStyles(() =>
  createStyles({
    root: {
      paddingLeft: '7px',
      padding: '8px',
      height: '60px',
    },
  })
)(TableCell);

export default StyledTableCell;
