import { API } from '../utils';
import {
  INotification,
  INotifForm,
  IResponse,
  IModelNotif,
} from '../interfaces';

/**
 * fetch notifications
 */
export const fetchNotifications = async (
  offset: number,
  limit: number,
  filter: string,
  sort_column: string,
  sort_direction: string
): Promise<INotification[]> => {
  return API.get<INotification[]>(
    `conf/get-notifications?offset=${offset}&limit=${limit}&filter=${filter}&sort_column=${sort_column}&sort_direction=${sort_direction}`
  );
};

/**
 * create one
 */
export const createNotifications = async (
  notifData: INotifForm
): Promise<IResponse> => {
  return API.post(`conf/create-notifications`, notifData);
};

/**
 * fetch model notifications
 */
export const fetchModelNotifications = async (): Promise<IModelNotif[]> => {
  return API.get<IModelNotif[]>(`conf/get-notif-model`);
};
