import { useEffect } from 'react';
import { TableBody } from '@material-ui/core';
// global state
import {
  useDriveSelector,
  setFilter,
} from '../../../../../../../../features/drive/driveSlice';
// interface
import { IColumn, IFile } from '../../../../../../../../interfaces';
// children components
import DirectoryTableContent from './TableContent/DirectoryTableContent';

interface DirectoryTableBodyContainerProps {
  columns: IColumn<IFile>[];
  isCollapse: boolean;
}

export default function DirectoryTableBodyContainer({
  columns,
  isCollapse,
}: DirectoryTableBodyContainerProps) {
  const { files, offset, limit } = useDriveSelector((state) => state.drive);

  useEffect(() => {
    setFilter('');
  }, [offset, limit]);

  return (
    <TableBody>
      {files.map((row) => {
        return (
          <DirectoryTableContent
            row={row}
            labelId={row.id}
            key={row.id}
            columns={columns}
            isCollapse={isCollapse}
          />
        );
      })}
    </TableBody>
  );
}
