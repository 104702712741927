import React, { useState, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Dialog,
  FormControl,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Divider,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  fetchNotifications,
  useNotificationsSelector,
  createNotifications,
  fetchModelNotifications,
} from '../../../../../features/notifications/notificationsSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// components
import {
  FormHeader,
  FormBody,
  FormFooter,
  StyledGrid,
} from '../../common/Forms';
// interface
import { INotifForm, ITaxpayer, IUser } from '../../../../../interfaces';
// types
import { Notification } from '../../../../../types';
// constants
import {
  MIN_LEN_NOTIFICATION,
  MAX_LEN_NOTIFICATION,
  MIN_LEN_TITLE,
  MAX_LEN_TITLE,
} from '../../../../../constants';
// utils
import { capitalize } from '../../../../../utils';
// hooks
import { useIsMobile } from '../../../../../hooks/useIsMobile';
// components
import TaxpayersAutocomplete from './CreateContent/TaxpayersAutocomplete';
import ClientAutocomplete from './CreateContent/ClientAutocomplete';

interface NotificationCreateDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function NotificationCreateDialog({
  openDialog,
  setOpenDialog,
}: NotificationCreateDialogProps) {
  const dispatch = useAppDispatch();

  const {
    dispatch: { setSnackbar, errorSnackbar },
  } = useSnackbar();

  const { isMobile } = useIsMobile();

  const {
    modelNotifications,
    actionLoading,
    offset,
    limit,
    filter,
    sort_column,
    sort_direction,
  } = useNotificationsSelector((state) => state.notifications);

  useEffect(() => {
    if (modelNotifications.length === 0)
      try {
        dispatch(fetchModelNotifications());
      } catch (err) {
        errorSnackbar();
      }
  }, []);

  const [notificationType, setNotificationType] =
    useState<Notification>('custom');

  const [selectedTaxpayers, setSelectedTaxpayers] = useState<ITaxpayer[]>([]);

  const [users, setUsers] = useState<IUser[]>([]);

  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);

  const [helperText, setHelperText] = useState('');

  const validationSchema = Yup.object({
    title: Yup.string()
      .required(`Requerido!`)
      .min(MIN_LEN_TITLE, `Al menos ${MIN_LEN_TITLE} caracteres.`)
      .max(MAX_LEN_TITLE, `Maximo ${MAX_LEN_TITLE} caracteres.`),
    message: Yup.string()
      .required(`Requerido!`)
      .min(MIN_LEN_NOTIFICATION, `Al menos ${MIN_LEN_NOTIFICATION} caracteres.`)
      .max(MAX_LEN_NOTIFICATION, `Maximo ${MAX_LEN_NOTIFICATION} caracteres.`),
    isAllUsers: Yup.boolean(),
    users_uuid: Yup.array().when(['isAllUsers'], {
      is: (isAllUsers: boolean) => {
        return !isAllUsers;
      },
      then: Yup.array().min(1, `Requerido!`),
    }),
  });

  const handleSubmit = (userData: INotifForm) => {
    handleCreateNotifications(userData);
  };

  const initialValues: INotifForm = {
    title: 'Liber App',
    message: '',
    users_uuid: [],
    isAllUsers: false,
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const handleResetError = () => {
    setHelperText('');
    formik.setErrors({});
  };

  const handleClose = () => {
    formik.setValues(formik.initialValues);
    setSelectedTaxpayers([]);
    setUsers([]);
    setSelectedUsers([]);
    setNotificationType('custom');
    setOpenDialog(false);
    handleResetError();
  };

  const handleCreateNotifications = async (notifData: INotifForm) => {
    let taxpayers = ''; // aux var to store taxpayers company_name if needed
    if (selectedTaxpayers.length > 0)
      // if exists selectedTaxpayers, list all taxpayers company_name to add it to the message
      taxpayers = selectedTaxpayers.map((row) => row.company_name).join(', ');
    try {
      const resultAction = await dispatch(
        createNotifications(
          // if taxpayers !== '', mutate the message adding taxpayers company_name at the beginning
          taxpayers
            ? {
                ...notifData,
                message: `${taxpayers}. ~ ${notifData.message}`,
              }
            : // else send notifData without altering
              notifData
        )
      );
      const res = unwrapResult(resultAction);
      setSnackbar(true, res.message);
      dispatch(
        fetchNotifications({
          offset,
          limit,
          filter,
          sort_column,
          sort_direction,
        })
      );
      handleClose();
    } catch (err: any) {
      setHelperText(`❌ ${err.message}`);
      errorSnackbar();
    }
  };

  const handleNotificationTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNotificationType((e.target as HTMLInputElement).value as Notification);
    if ((e.target as HTMLInputElement).value === 'custom') {
      formik.setFieldValue('title', formik.initialValues.title);
      formik.setFieldValue('message', formik.initialValues.message);
    }
    if ((e.target as HTMLInputElement).value === 'model') {
      formik.setFieldValue('title', '');
      formik.setFieldValue('message', formik.initialValues.message);
    }
  };

  const handleTitleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    formik.setFieldValue('title', capitalize(e.target.value));
    if (notificationType === 'model') {
      // set message body depends on the model
      const modelNotif = modelNotifications.find(
        (row) => row.title === e.target.value
      );
      if (modelNotif) formik.setFieldValue('message', modelNotif.message);
    }
    handleResetError();
  };

  const handleSelectAllUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('isAllUsers', e.target.checked);
    setSelectedTaxpayers([]);
    setSelectedUsers([]);
    formik.setFieldValue('users_uuid', []);
    handleResetError();
  };

  return (
    <Dialog
      open={openDialog}
      keepMounted
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="simple-dialog"
    >
      <FormControl component="fieldset" error={!!helperText}>
        <form onSubmit={formik.handleSubmit}>
          <FormHeader handleClose={handleClose}>
            {`Crear notificación 🔔`}
          </FormHeader>
          <FormBody loading={actionLoading} helperText={helperText}>
            <>
              <StyledGrid item xs={12} sm={12}>
                <FormLabel component="legend">
                  <Typography variant="subtitle1" color="primary">
                    {'Tipo de notificación:'}
                  </Typography>
                </FormLabel>
                <Box pb={1}>
                  <RadioGroup
                    row
                    value={notificationType}
                    onChange={handleNotificationTypeChange}
                  >
                    <FormControlLabel
                      value="custom"
                      control={<Radio color="primary" />}
                      label="Personalizada"
                    />
                    <FormControlLabel
                      value="model"
                      control={<Radio color="primary" />}
                      label="Modelo"
                    />
                  </RadioGroup>
                </Box>
              </StyledGrid>

              <StyledGrid item xs={12} sm={12} />
              <StyledGrid item xs={12} sm={12}>
                <Typography variant="subtitle1" color="primary">
                  {'Destinatario:'}
                </Typography>
                <Divider variant="middle" style={{ width: '100%' }} />
              </StyledGrid>

              <StyledGrid item xs={12} sm={9}>
                <Box pb={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="isAllUsers"
                        checked={formik.values.isAllUsers}
                        onChange={handleSelectAllUsers}
                        name="isAllUsers"
                        color="primary"
                        style={{ margin: '0px' }}
                      />
                    }
                    label="Enviar notificación a todos los usuarios"
                  />
                </Box>
              </StyledGrid>
              <StyledGrid item xs={12} sm={3} />
              {formik.values.isAllUsers ? null : (
                <StyledGrid item xs={12} sm={12}>
                  <Box pb={1}>
                    <ClientAutocomplete
                      usersUuidErrors={formik.errors.users_uuid}
                      handleResetError={handleResetError}
                      formikSetFieldValue={formik.setFieldValue}
                      users={users}
                      setUsers={setUsers}
                      selectedUsers={selectedUsers}
                      setSelectedUsers={setSelectedUsers}
                      isAllUsers={formik.values.isAllUsers}
                      setSelectedTaxpayers={setSelectedTaxpayers}
                    />
                  </Box>
                </StyledGrid>
              )}
              {formik.values.users_uuid.length !== 1 ? null : (
                <StyledGrid item xs={12} sm={12}>
                  <Box pb={1}>
                    <TaxpayersAutocomplete
                      handleResetError={handleResetError}
                      selectedTaxpayers={selectedTaxpayers}
                      setSelectedTaxpayers={setSelectedTaxpayers}
                      isAllUsers={formik.values.isAllUsers}
                      usersUuid={formik.values.users_uuid}
                    />
                  </Box>
                </StyledGrid>
              )}

              <StyledGrid item xs={12} sm={12} />
              <StyledGrid item xs={12} sm={12}>
                <Typography variant="subtitle1" color="primary">
                  {'Notificación:'}
                </Typography>
                <Divider variant="middle" style={{ width: '100%' }} />
              </StyledGrid>

              <StyledGrid item xs={12} sm={12}>
                <Box pt={2}>
                  <TextField
                    id="title"
                    name="title"
                    label="Título de la notificación *"
                    variant="outlined"
                    autoComplete="off"
                    style={{ width: isMobile ? 230 : 500 }}
                    value={formik.values.title}
                    onChange={handleTitleChange}
                    inputProps={{
                      maxLength: MAX_LEN_TITLE,
                    }}
                    helperText={formik.errors.title ? formik.errors.title : ' '}
                    error={!!formik.errors.title}
                    select={notificationType === 'model'}
                  >
                    {modelNotifications.map((row) => (
                      <MenuItem key={row.title} value={row.title}>
                        {row.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </StyledGrid>
              <StyledGrid item xs={12} sm={12}>
                <TextField
                  id="message"
                  name="message"
                  label="Mensaje *"
                  variant="outlined"
                  autoComplete="off"
                  multiline
                  rows={5}
                  style={{ width: isMobile ? 230 : 500 }}
                  value={formik.values.message}
                  onChange={(e) => {
                    formik.setFieldValue('message', capitalize(e.target.value));
                    handleResetError();
                  }}
                  inputProps={{
                    maxLength: MAX_LEN_NOTIFICATION,
                  }}
                  helperText={
                    formik.errors.message
                      ? formik.errors.message
                      : `Quedan ${
                          MAX_LEN_NOTIFICATION - formik.values.message.length
                        } caracteres disponibles`
                  }
                  error={!!formik.errors.message}
                />
              </StyledGrid>
            </>
          </FormBody>
          <FormFooter>
            <Button autoFocus type="submit" color="primary" variant="contained">
              {'Confirmar'}
            </Button>
          </FormFooter>
        </form>
      </FormControl>
    </Dialog>
  );
}
