import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  createStyles,
} from '@material-ui/core';
// components
import { Progress } from '../../../../../common';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: 70,
      minWidth: 450,
    },
  })
);

interface EmailDialogProps {
  openDialog: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  confirmMessage: string;
  alertMessage: string;
  actionLoading: boolean;
}

export default function EmailDialog({
  openDialog,
  handleClose,
  handleSubmit,
  confirmMessage,
  alertMessage,
  actionLoading,
}: EmailDialogProps) {
  const classes = useStyles();

  return (
    <Dialog
      open={openDialog}
      keepMounted
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="simple-dialog"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {`${confirmMessage} 📧`}
      </DialogTitle>
      {actionLoading ? <Progress /> : <div style={{ height: 4 }} />}
      <DialogContent className={classes.root}>
        <DialogContentText id="alert-dialog-slide-description">
          {`${alertMessage}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {actionLoading ? null : (
          <>
            <Button color="primary" variant="outlined" onClick={handleClose}>
              {'Cancelar'}
            </Button>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              {'Enviar'}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
