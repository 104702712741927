import { useEffect } from 'react';
import { Paper } from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  fetchDriveItems,
  useDriveSelector,
  setNextPageToken,
  setOffset,
} from '../../../../../features/drive/driveSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// common
import { Error } from '../../../../../common';
// children components
import DirectoryTableHeader from './content/Header/DirectoryTableHeader';
import DirectoryTableBody from './content/Body/DirectoryTableBody';
import DirectoryTableFooter from './content/Footer/DirectoryTableFooter';

export default function DirectoryView() {
  const dispatch = useAppDispatch();

  const {
    error,
    limit,
    filter,
    breadcrumbs,
    sort_column,
    sort_direction,
    nextPageToken,
  } = useDriveSelector((state) => state.drive);

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  useEffect(() => {
    try {
      dispatch(setNextPageToken(['']));
      dispatch(setOffset(1));
      dispatch(
        fetchDriveItems({
          limit,
          filter,
          folder_id: breadcrumbs[breadcrumbs.length - 1].folderId,
          sort_column: sort_column,
          sort_direction: sort_direction,
          nextPageToken: nextPageToken[0],
        })
      );
    } catch (err) {
      errorSnackbar();
    }
  }, [limit, sort_column, sort_direction]);

  if (error) return <Error />;

  return (
    <Paper>
      <DirectoryTableHeader />
      <DirectoryTableBody />
      <DirectoryTableFooter />
    </Paper>
  );
}
