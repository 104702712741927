import { unwrapResult } from '@reduxjs/toolkit';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContentText,
  DialogContent,
  FormControl,
  TextField,
  Box,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// global state
import { useAppDispatch } from '../../../app/store';
import {
  useAuthSelector,
  forgotPassword,
} from '../../../features/auth/authSlice';
// context
import { useSnackbar } from '../../../context/SnackbarContext';
// interface
import { IForgotPsswdForm } from '../../../interfaces';
// constant
import { MIN_LEN_EMAIL, MAX_LEN_EMAIL } from '../../../constants';
// components
import { FormBody } from '../../Dashboard/MainViews/common/Forms';

interface ForgotPasswordProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setAllowOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ForgotPassword({
  openDialog,
  setOpenDialog,
  setAllowOpenDialog,
}: ForgotPasswordProps) {
  const dispatch = useAppDispatch();

  const { loading } = useAuthSelector((state) => state.auth);

  const {
    dispatch: { setSnackbar },
  } = useSnackbar();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Email invalido')
      .required(`Requerido!`)
      .min(MIN_LEN_EMAIL, `Al menos ${MIN_LEN_EMAIL} caracteres.`)
      .max(MAX_LEN_EMAIL, `Maximo ${MAX_LEN_EMAIL} caracteres.`),
  });

  const handleSubmit = async (forgotPsswdData: IForgotPsswdForm) => {
    try {
      const resultAction = await dispatch(forgotPassword(forgotPsswdData));
      const res = unwrapResult(resultAction);
      setSnackbar(true, res);
      handleClose();
    } catch (err: any) {
      setSnackbar(true, `❌ ${err.message}`);
      handleClose();
      formik.setValues(formik.initialValues);
    }
    // setting allow open dialog...
    setAllowOpenDialog(false);
    setTimeout(() => {
      setAllowOpenDialog(true);
    }, 10000);
  };

  const initialValues: IForgotPsswdForm = {
    email: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleResetError = () => {
    formik.setErrors({});
  };

  const handleClose = () => {
    formik.setValues(formik.initialValues);
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {'¿Olvidaste tu contraseña?'}
      </DialogTitle>
      <FormBody loading={loading}>
        <FormControl component="fieldset">
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <DialogContentText>
                {
                  'Ingrese tu email para que te enviemos las indicaciones para que puedas volver a ingresar a la aplicación.'
                }
              </DialogContentText>

              <Box py={2}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="email"
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleResetError();
                  }}
                  label="Email"
                  type="text"
                  fullWidth
                  autoComplete="off"
                  inputProps={{
                    maxLength: MAX_LEN_EMAIL,
                  }}
                  helperText={formik.errors.email}
                  error={!!formik.errors.email}
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                {'Cancelar'}
              </Button>
              <Button type="submit" color="primary" variant="contained">
                {'Confirmar'}
              </Button>
            </DialogActions>
          </form>
        </FormControl>
      </FormBody>
    </Dialog>
  );
}
