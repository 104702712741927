import React from 'react';
import { TableSortLabel, Box } from '@material-ui/core';
// global state
import { useNotificationsSelector } from '../../../../../../../../features/notifications/notificationsSlice';
// interface
import { IColumn, INotification } from '../../../../../../../../interfaces';
// children components
import {
  StyledTableCellHeader,
  CustomSpan,
  TableBodyHeader,
} from '../../../../../common/Table/Body/content';

const SORTABLE_COLUMNS = ['created_at'];

interface ClientTableBodyHeaderProps {
  onRequestSort: (
    e: React.MouseEvent<unknown>,
    property: keyof INotification
  ) => void;
  columns: IColumn<INotification>[];
  isCollapse: boolean;
}

export default function ClientTableBodyHeader({
  onRequestSort,
  columns,
  isCollapse,
}: ClientTableBodyHeaderProps) {
  const { sort_column, sort_direction } = useNotificationsSelector(
    (state) => state.notifications
  );

  const createSortHandler =
    (property: keyof INotification) => (e: React.MouseEvent<unknown>) => {
      onRequestSort(e, property);
    };

  return (
    <TableBodyHeader
      isCollapse={isCollapse}
      isItemSelected={false}
      isRadio={false}
    >
      {columns.map((column) => (
        <StyledTableCellHeader
          key={column.id}
          align={column.align}
          sortDirection={sort_column === column.id ? sort_direction : false}
        >
          {SORTABLE_COLUMNS.includes(column.id) ? (
            <TableSortLabel
              active={sort_column === column.id}
              direction={sort_column === column.id ? sort_direction : 'asc'}
              onClick={createSortHandler(column.id)}
            >
              <Box fontWeight="fontWeightBold">
                {column.label}
                {sort_column === column.id ? (
                  <CustomSpan>
                    {sort_direction === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </CustomSpan>
                ) : null}
              </Box>
            </TableSortLabel>
          ) : (
            <Box fontWeight="fontWeightBold">{column.label}</Box>
          )}
        </StyledTableCellHeader>
      ))}
    </TableBodyHeader>
  );
}
