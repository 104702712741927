import React from 'react';
// components
import { ResetPsswdButton } from '../../../../../common/Table/Header/Buttons';
// children components
import ClientResetPsswdDialog from '../../../../../Dialogs/Clients/ClientResetPsswdDialog';

export default function ResetPsswdClientButton() {
  const [openDialog, setOpenDialog] = React.useState(false);

  return (
    <ResetPsswdButton setOpenDialog={setOpenDialog}>
      <ClientResetPsswdDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </ResetPsswdButton>
  );
}
