import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { ITax, ITaxName } from '../../interfaces';
import { DEF_LIMIT, DEF_OFFSET } from '../../constants';
import { Order } from '../../types';
import * as taxSvcs from '../../api/tax.services';
import * as excelSvcs from '../../api/excel.services';

// Interfaces
interface ITaxesState {
  loading: boolean;
  error: boolean | string;
  taxes: ITax[];
  tax: ITax | null;
  taxpayerTaxes: ITax[];
  actionLoading: boolean;
  actionError: boolean | string;
  offset: number;
  limit: number;
  filter: string;
  sort_column: keyof ITax;
  sort_direction: Order;
}

// Initial state
const initialState: ITaxesState = {
  loading: false,
  error: false,
  taxes: [],
  tax: null,
  taxpayerTaxes: [],
  actionLoading: false,
  actionError: false,
  offset: DEF_OFFSET,
  limit: DEF_LIMIT,
  filter: '',
  sort_column: 'name',
  sort_direction: 'asc',
};

// Asynchronous thunk actions
export const fetchTaxes = createAsyncThunk(
  'taxes/fetchTaxes',
  ({
    offset = initialState.offset,
    limit = initialState.limit,
    filter = initialState.filter,
    sort_column = initialState.sort_column,
    sort_direction = initialState.sort_direction,
  }: {
    offset?: number;
    limit?: number;
    filter?: string;
    sort_column?: string;
    sort_direction?: string;
  }) => {
    return taxSvcs.fetchTaxes(
      offset,
      limit,
      filter,
      sort_column,
      sort_direction
    );
  }
);

export const fetchTaxpayerTaxes = createAsyncThunk(
  'taxes/fetchTaxpayerTaxes',
  (taxpayerUuid: string) => {
    return taxSvcs.fetchTaxpayerTaxes(taxpayerUuid);
  }
);

export const fetchTaxById = createAsyncThunk(
  'taxes/fetchTaxById',
  (taxUuid: string) => {
    return taxSvcs.fetchTaxByUuid(taxUuid);
  }
);

export const updateTaxName = createAsyncThunk(
  'taxes/updateTaxName',
  ({ taxUuid, taxData }: { taxUuid: string; taxData: ITaxName }) => {
    return taxSvcs.updateTaxName(taxUuid, taxData);
  }
);

export const deleteTax = createAsyncThunk(
  'taxes/deleteTax',
  (taxUuid: string) => {
    return taxSvcs.deleteTax(taxUuid);
  }
);
export const fetchUploadedExpiration = createAsyncThunk(
  'taxes/fetchUploadedExpiration',
  (year: string) => {
    return taxSvcs.fetchUploadedExpiration(year);
  }
);

export const uploadExcel = createAsyncThunk(
  'taxes/uploadExcel',
  ({ excelData, year }: { excelData: FormData; year: string }) => {
    return excelSvcs.uploadExcel(excelData, year);
  }
);

export const downloadExcel = createAsyncThunk('taxes/downloadExcel', () => {
  return excelSvcs.downloadExcel();
});

// Slice
const taxes = createSlice({
  name: 'taxes',
  initialState,
  reducers: {
    selectTax(state: ITaxesState, { payload }: PayloadAction<ITax | null>) {
      state.tax = payload;
    },
    resetTaxpayerTaxes(state: ITaxesState) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.taxpayerTaxes = initialState.taxpayerTaxes;
    },
    setOffset(state: ITaxesState, { payload }: PayloadAction<number>) {
      state.offset = payload;
    },
    setLimit(state: ITaxesState, { payload }: PayloadAction<number>) {
      state.limit = payload;
    },
    setFilter(state: ITaxesState, { payload }: PayloadAction<string>) {
      state.filter = payload;
    },
    setSortColumn(state: ITaxesState, { payload }: PayloadAction<keyof ITax>) {
      state.sort_column = payload;
    },
    setSortDirection(state: ITaxesState, { payload }: PayloadAction<Order>) {
      state.sort_direction = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTaxes.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchTaxes.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.taxes = action.payload;
    });
    builder.addCase(fetchTaxes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(fetchTaxpayerTaxes.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchTaxpayerTaxes.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.taxpayerTaxes = action.payload;
    });
    builder.addCase(fetchTaxpayerTaxes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(fetchTaxById.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(fetchTaxById.fulfilled, (state) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(fetchTaxById.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(updateTaxName.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(updateTaxName.fulfilled, (state) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(updateTaxName.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(deleteTax.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(deleteTax.fulfilled, (state) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(deleteTax.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(fetchUploadedExpiration.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(fetchUploadedExpiration.fulfilled, (state) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(fetchUploadedExpiration.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(uploadExcel.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(uploadExcel.fulfilled, (state, action) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(uploadExcel.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(downloadExcel.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(downloadExcel.fulfilled, (state, action) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(downloadExcel.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
  },
});

// Actions generated from the slice
export const {
  selectTax,
  resetTaxpayerTaxes,
  setLimit,
  setOffset,
  setFilter,
  setSortColumn,
  setSortDirection,
} = taxes.actions;

// Selector
export const useTaxesSelector: TypedUseSelectorHook<RootState> = useSelector;

// The reducer
export default taxes.reducer;
