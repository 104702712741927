import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  TableHead,
  TableRow,
  TableCell,
  Radio,
  lighten,
} from '@material-ui/core';
// components
import StyledTableCellHeader from './StyledTableCellHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sticky: {
      position: 'sticky',
      right: '0',
      background: theme.palette.background.default,
      padding: '0px',
    },
    header: {
      /* backgroundColor: lighten(theme.palette.primary.light, 0.8), */
    },
    drive: {
      width: '1vh',
    },
  })
);

interface TableBodyHeaderProps {
  isCollapse: boolean;
  isItemSelected: boolean;
  handleDeselect?: () => void;
  children: React.ReactNode;
  isRadio?: boolean;
}

export default function TableBodyHeader({
  isCollapse,
  isItemSelected,
  handleDeselect,
  children,
  isRadio = true,
}: TableBodyHeaderProps) {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {isRadio ? (
          <TableCell padding="checkbox" className={classes.header}>
            <Radio
              size="small"
              //indeterminate={isItemSelected}
              checked={isItemSelected}
              disabled={!isItemSelected}
              onClick={handleDeselect}
              inputProps={{ 'aria-labelledby': 'deselect' }}
            />
          </TableCell>
        ) : (
          <TableCell className={classes.drive} padding="none" />
        )}

        {/* <StyledTableCellHeader padding="checkbox" /> */}

        {children}

        {isCollapse ? (
          <StyledTableCellHeader
            padding="checkbox"
            className={classes.sticky}
          />
        ) : null}
      </TableRow>
    </TableHead>
  );
}
