import React from 'react';
import { TablePagination } from '@material-ui/core';

import { useAppDispatch } from '../../../../../../../app/store';
// global state
import {
  useDriveSelector,
  setOffset,
  setLimit,
  fetchDriveItems,
  setNextPageToken,
} from '../../../../../../../features/drive/driveSlice';
// context
import { useSnackbar } from '../../../../../../../context/SnackbarContext';
// components
import { TableFooter } from '../../../../common/Table/Footer';

export default function DirectoryTableFooter() {
  const dispatch = useAppDispatch();

  const {
    files,
    offset,
    limit,
    filter,
    breadcrumbs,
    sort_column,
    sort_direction,
    nextPageToken,
  } = useDriveSelector((state) => state.drive);

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const handleSetOffset = async (offset: number) => {
    dispatch(setOffset(offset));
    try {
      dispatch(
        fetchDriveItems({
          nextPageToken: nextPageToken[offset - 1],
          limit,
          filter,
          folder_id: breadcrumbs[breadcrumbs.length - 1].folderId,
          sort_column: sort_column,
          sort_direction: sort_direction,
        })
      );
    } catch (err) {
      errorSnackbar();
    }
  };

  const handleSetLimit = async (limit: number) => {
    dispatch(setLimit(limit));
    dispatch(setOffset(1));
    dispatch(setNextPageToken(['']));
  };

  return (
    <TableFooter
      rows={files}
      handleSetOffset={handleSetOffset}
      handleSetLimit={handleSetLimit}
      offset={offset}
      limit={limit}
      isTotalRowsUndefined={true}
      nextPageToken={nextPageToken[offset]}
    />
  );
}
