import {
  Divider,
  makeStyles,
  createStyles,
  Theme,
  Box,
  Grid,
  lighten,
} from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../app/store';
import {
  useUISelector,
  toggleOpenDrawer,
} from '../../../../features/UI/uiSlice';
// children
import ListMenu from './Lists/ListMenu';
// image
import logoTrim from '../../../../images/logoTrim.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    toolbarStyle: {
      backgroundColor: theme.palette.primary.main,
    },
    root: {
      width: '100%',
      maxWidth: 360,
      // background: theme.palette.primary.light,
      backgroundColor: theme.palette.background.paper,
      // text color
      // color: theme.palette.text.secondary,
    },
    icon: {
      color: theme.palette.primary.main,
      padding: 0,
    },
    listItemIcon: {
      minWidth: '40px',
    },
    image: {
      backgroundColor: theme.palette.primary.main,
    },
    listItem: {
      '&.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.info.main,
        borderRadius: 5,
      },
      '&:hover': {
        borderRadius: 5,
        backgroundColor: lighten(theme.palette.info.main, 0.5),
      },
      '&.Mui-selected:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.info.main,
        borderRadius: 5,
      },
    },
  })
);

export default function DrawerMenu() {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { openDrawer } = useUISelector((state) => state.ui);

  function handleClick() {
    if (openDrawer) {
      dispatch(toggleOpenDrawer());
    }
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.image}
      >
        <div className={classes.toolbar}>
          <Box pt={1.5}>
            <img alt="logo" width="100%" height="100%" src={logoTrim} />
          </Box>
        </div>
      </Grid>

      <Divider variant="middle" />

      <Box px={1}>
        <ListMenu handleClick={handleClick} classes={classes} />
      </Box>
    </>
  );
}
