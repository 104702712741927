import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  useUsersSelector,
  selectUser,
  sendActivationEmail,
  fetchIsEmailSent,
} from '../../../../../features/users/usersSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// components
import { EmailDialog } from '../../common/Dialog';

interface ClientSendEmailDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ClientSendEmailDialog({
  openDialog,
  setOpenDialog,
}: ClientSendEmailDialogProps) {
  const dispatch = useAppDispatch();

  const { actionLoading, user } = useUsersSelector((state) => state.users);

  const [confirmMessage, setConfirmMessage] = useState('');

  const [alertMessage, setAlertMessage] = useState('');

  const {
    dispatch: { setSnackbar, resetSnackbar, errorSnackbar },
  } = useSnackbar();

  const handleFetchIsEmailSent = async (uuid: string) => {
    try {
      const resultAction = await dispatch(fetchIsEmailSent(uuid));
      const res = unwrapResult(resultAction);
      if (res.data) {
        setAlertMessage(
          '⚠️ El email sobre la creación de la cuenta ya se ha enviado. ¿Desea enviarlo nuevamente?'
        );
      } else {
        setAlertMessage(
          'Se enviara un email al usuario confirmando la creación de su cuenta.'
        );
      }
    } catch (err: any) {
      errorSnackbar();
    }
  };

  useEffect(() => {
    setConfirmMessage('Enviar email');
    if (openDialog && user) {
      handleFetchIsEmailSent(user.uuid);
    }
  }, [openDialog]);

  const handleSendEmail = async (userEmail: string) => {
    try {
      const resultAction = await dispatch(sendActivationEmail(userEmail));
      const res = unwrapResult(resultAction);
      setSnackbar(true, res.message);
    } catch (err: any) {
      errorSnackbar();
    }
  };

  const handleSubmit = () => {
    if (user) {
      resetSnackbar();
      handleSendEmail(user.email);
      setOpenDialog(false);
      dispatch(selectUser(null));
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setAlertMessage('');
  };

  return (
    <EmailDialog
      openDialog={openDialog}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      confirmMessage={confirmMessage}
      alertMessage={alertMessage}
      actionLoading={actionLoading}
    />
  );
}
