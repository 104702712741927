import { API } from '../utils';
import {
  ITaxpayer,
  IManager,
  IResponse,
  IFolder,
  ITaxpayerType,
} from '../interfaces';
import { Month } from '../types';

/**
 * fetch taxpayers
 */
export const fetchTaxpayers = async (
  offset: number,
  limit: number,
  month: Month,
  filter: string,
  showDisabled: boolean,
  sort_column: string,
  sort_direction: string
): Promise<ITaxpayer[]> => {
  return API.get<ITaxpayer[]>(
    `conf/get-taxpayers?offset=${offset}&limit=${limit}&filter=${filter}&month=${month}&showDisabled=${showDisabled}&sort_column=${sort_column}&sort_direction=${sort_direction}`
  );
};

/**
 * fetch taxpayers
 */
export const fetchTaxpayerTypes = async (): Promise<ITaxpayerType[]> => {
  return API.get<ITaxpayerType[]>(`conf/get-taxpayer-types`);
};

/**
 * fetch client taxpayers
 */
export const fetchClientTaxpayers = async (
  uuid: string
): Promise<ITaxpayer[]> => {
  return API.get<ITaxpayer[]>(`conf/get-client-taxpayers/${uuid}`);
};

/**
 * fetch tax taxpayers
 */
export const fetchTaxTaxpayers = async (uuid: string): Promise<ITaxpayer[]> => {
  return API.get<ITaxpayer[]>(`conf/get-tax-taxpayers/${uuid}`);
};

/**
 * fetch one
 */
export const fetchTaxpayerByUuid = async (uuid: string): Promise<ITaxpayer> => {
  return API.get<ITaxpayer>(`conf/get-taxpayer/${uuid}`);
};

/**
 * create one
 */
export const createTaxpayer = async (
  taxpayerData: FormData
): Promise<IResponse> => {
  return API.post(`conf/create-taxpayer`, taxpayerData);
};

/**
 * update one
 */
export const updateTaxpayer = async (
  uuid: string,
  taxpayerData: FormData,
  removeLogo: boolean
): Promise<IResponse> => {
  return API.put(
    `conf/update-taxpayer/${uuid}?removeLogo=${removeLogo}`,
    taxpayerData
  );
};

/**
 * toggle status
 */
export const toggleTaxpayerStatus = async (
  uuid: string,
  reason: string
): Promise<IResponse> => {
  return API.get(`conf/toggle-taxpayer-status/${uuid}?reason=${reason}`);
};

/**
 * delete one
 */
export const deleteTaxpayer = async (uuid: string): Promise<IResponse> => {
  return API.delete(`conf/delete-taxpayer/${uuid}`);
};

/**
 * fetch managers
 */
export const fetchManagers = async (filter: string): Promise<IManager[]> => {
  return API.get(`conf/get-managers?filter=${filter}`);
};

/**
 * link folder with taxpayer
 */
export const linkFolderWithTaxpayer = async (
  uuid: string,
  folderData: IFolder
): Promise<IResponse> => {
  return API.post(`conf/link-folder-with-taxpayer/${uuid}`, folderData);
};

/**
 * fetch one
 */
export const fetchLinkedTaxpayer = async (
  folder_id: string
): Promise<IResponse> => {
  return API.get<IResponse>(`conf/get-linked-taxpayer/${folder_id}`);
};
