import { Theme, withStyles } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';

export const FormFooter = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

export default FormFooter;
