// components
import { MainView } from '../common';
import TaxesTable from '../components/Dashboard/MainViews/Tables/Taxes/Taxes';

export default function Taxes() {
  return (
    <MainView>
      <TaxesTable />
    </MainView>
  );
}
