import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { IUser, IUserForm } from '../../interfaces';
import { DEF_LIMIT, DEF_OFFSET } from '../../constants';
import { Order } from '../../types';
import * as userSvcs from '../../api/user.services';

// Interfaces
interface IUsersState {
  loading: boolean;
  error: boolean | string;
  users: IUser[];
  user: IUser | null;
  actionLoading: boolean;
  actionError: boolean | string;
  showDisabled: boolean;
  offset: number;
  limit: number;
  filter: string;
  sort_column: keyof IUser;
  sort_direction: Order;
}

// Initial state
const initialState: IUsersState = {
  loading: false,
  error: false,
  users: [],
  user: null,
  actionLoading: false,
  actionError: false,
  showDisabled: true,
  offset: DEF_OFFSET,
  limit: DEF_LIMIT,
  filter: '',
  sort_column: 'name',
  sort_direction: 'asc',
};

// Asynchronous thunk actions
export const fetchUsersAdmin = createAsyncThunk(
  'users/fetchUsersAdmin',
  ({
    offset,
    limit,
    filter,
    showDisabled,
    sort_column,
    sort_direction,
  }: {
    offset: number;
    limit: number;
    filter: string;
    showDisabled: boolean;
    sort_column: string;
    sort_direction: string;
  }) => {
    return userSvcs.fetchUsersAdmin(
      offset,
      limit,
      filter,
      showDisabled,
      sort_column,
      sort_direction
    );
  }
);

export const fetchUsersClient = createAsyncThunk(
  'users/fetchUsersClient',
  ({
    offset = initialState.offset,
    limit = initialState.limit,
    filter,
    showDisabled = initialState.showDisabled,
    sort_column = initialState.sort_column,
    sort_direction = initialState.sort_direction,
  }: {
    offset?: number;
    limit?: number;
    filter: string;
    showDisabled?: boolean;
    sort_column?: string;
    sort_direction?: string;
  }) => {
    return userSvcs.fetchUsersClient(
      offset,
      limit,
      filter,
      showDisabled,
      sort_column,
      sort_direction
    );
  }
);

export const fetchTaxpayerClients = createAsyncThunk(
  'users/fetchTaxpayerClients',
  (clientUuid: string) => {
    return userSvcs.fetchTaxpayerClients(clientUuid);
  }
);

export const fetchUserById = createAsyncThunk(
  'users/fetchUserById',
  (userUuid: string) => {
    return userSvcs.fetchUserByUuid(userUuid);
  }
);

export const createUser = createAsyncThunk(
  'users/createUser',
  (userData: IUserForm) => {
    return userSvcs.createUser(userData);
  }
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  ({ userUuid, userData }: { userUuid: string; userData: IUserForm }) => {
    return userSvcs.updateUser(userUuid, userData);
  }
);

export const toggleUserStatus = createAsyncThunk(
  'users/toggleUserStatus',
  ({ userUuid, reason }: { userUuid: string; reason: string }) => {
    return userSvcs.toggleUserStatus(userUuid, reason);
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  (userUuid: string) => {
    return userSvcs.deleteUser(userUuid);
  }
);

export const resetUserPsswd = createAsyncThunk(
  'users/resetUserPsswd',
  (userUuid: string) => {
    return userSvcs.resetUserPsswd(userUuid);
  }
);

export const sendActivationEmail = createAsyncThunk(
  'users/sendActivationEmail',
  (userEmail: string) => {
    return userSvcs.sendActivationEmail(userEmail);
  }
);

export const fetchIsEmailSent = createAsyncThunk(
  'users/fetchIsEmailSent',
  (uuid: string) => {
    return userSvcs.fetchIsEmailSent(uuid);
  }
);

// Slice
const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    selectUser(state: IUsersState, { payload }: PayloadAction<IUser | null>) {
      state.user = payload;
    },
    resetUsers(state: IUsersState) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.users = initialState.users;
    },
    toggleShowDisabled(state: IUsersState) {
      state.showDisabled = !state.showDisabled;
    },
    setOffset(state: IUsersState, { payload }: PayloadAction<number>) {
      state.offset = payload;
    },
    setLimit(state: IUsersState, { payload }: PayloadAction<number>) {
      state.limit = payload;
    },
    setFilter(state: IUsersState, { payload }: PayloadAction<string>) {
      state.filter = payload;
      state.offset = initialState.offset;
    },
    setSortColumn(state: IUsersState, { payload }: PayloadAction<keyof IUser>) {
      state.sort_column = payload;
    },
    setSortDirection(state: IUsersState, { payload }: PayloadAction<Order>) {
      state.sort_direction = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsersAdmin.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchUsersAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.users = action.payload;
    });
    builder.addCase(fetchUsersAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(fetchUsersClient.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchUsersClient.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.users = action.payload;
    });
    builder.addCase(fetchUsersClient.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(fetchTaxpayerClients.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchTaxpayerClients.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.users = action.payload;
    });
    builder.addCase(fetchTaxpayerClients.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(fetchUserById.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(fetchUserById.fulfilled, (state) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(fetchUserById.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(createUser.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(toggleUserStatus.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(toggleUserStatus.fulfilled, (state) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(toggleUserStatus.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
    builder.addCase(fetchIsEmailSent.pending, (state) => {
      state.actionLoading = true;
      state.actionError = false;
    });
    builder.addCase(fetchIsEmailSent.fulfilled, (state, action) => {
      state.actionLoading = false;
      state.actionError = false;
    });
    builder.addCase(fetchIsEmailSent.rejected, (state, action) => {
      state.actionLoading = false;
      state.actionError = action.error.message as string | boolean;
    });
  },
});

// Actions generated from the slice
export const {
  selectUser,
  resetUsers,
  toggleShowDisabled,
  setLimit,
  setOffset,
  setFilter,
  setSortColumn,
  setSortDirection,
} = users.actions;

// Selector
export const useUsersSelector: TypedUseSelectorHook<RootState> = useSelector;

// Reducer
export default users.reducer;
