import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import {
  IAuthUser,
  ILoginForm,
  IChangePsswdForm,
  IChangeEmailForm,
  IForgotPsswdForm,
  IConfirmForgotPsswdForm,
} from '../../interfaces';
import {
  isAuthenticated,
  login,
  logout,
  getAccessToken,
  getMyRoleAndUuid,
} from '../../utils';
import * as authSvcs from '../../api/auth.services';

// Interfaces
interface IAuthUserState {
  loading: boolean;
  error: boolean | string;
  loggedIn: boolean;
  authUser: IAuthUser;
}

// Initial state
const initialState: IAuthUserState = {
  loading: false,
  error: false,
  loggedIn: false,
  authUser: {} as IAuthUser,
};

// Asynchronous thunk actions
export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({
    formData,
    remember,
  }: {
    formData: ILoginForm;
    remember: boolean;
  }) => {
    const authUser: IAuthUser = await authSvcs.signIn(formData);
    // save token
    login(remember, authUser.accessToken!);
    // retrun auth user data
    return authUser;
  }
);

export const checkAuth = createAsyncThunk('auth/checkAuth', async () => {
  const authUser = {} as IAuthUser;
  // checking
  if (isAuthenticated()) {
    authUser.accessToken = getAccessToken()!;
    const { role, uuid } = getMyRoleAndUuid(authUser.accessToken)!;
    authUser.role = role;
    authUser.uuid = uuid;
    // returning already auth user
    return authUser;
  }
  return {
    accessToken: null,
    role: null,
    uuid: null,
  };
});

export const signOut = createAsyncThunk('auth/signOut', () => {
  return logout();
});

export const changeUserPassword = createAsyncThunk(
  'auth/changeUserPassword',
  (changePsswdData: IChangePsswdForm) => {
    return authSvcs.changePsswd(changePsswdData);
  }
);

export const changeUserEmail = createAsyncThunk(
  'auth/changeUserEmail',
  (changeEmailData: IChangeEmailForm) => {
    return authSvcs.changeEmail(changeEmailData);
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  (forgotPsswdData: IForgotPsswdForm) => {
    return authSvcs.forgotPassword(forgotPsswdData);
  }
);

export const confirmForgotPassword = createAsyncThunk(
  'auth/confirmForgotPassword',
  (confirmForgotPsswdData: IConfirmForgotPsswdForm) => {
    return authSvcs.confirmForgotPassword(confirmForgotPsswdData);
  }
);

// Slice
const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state) => {
      state.loading = true;
      state.loggedIn = false;
      state.error = false;
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.authUser = action.payload;
      state.loggedIn = true;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.loading = false;
      state.loggedIn = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(checkAuth.pending, (state) => {
      state.loading = true;
      state.loggedIn = false;
      state.error = false;
    });
    builder.addCase(checkAuth.fulfilled, (state, action) => {
      state.authUser = action.payload;
      state.loggedIn = !!action.payload.accessToken;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(checkAuth.rejected, (state, action) => {
      state.loading = false;
      state.loggedIn = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(signOut.pending, (state) => {
      state.loading = true;
      state.loggedIn = true;
      state.error = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      // state = initialState; <- doesn't work. It will work with Object.assign()
      state.authUser = initialState.authUser;
      state.loggedIn = initialState.loggedIn;
      state.loading = initialState.loading;
      state.error = initialState.error;
    });
    builder.addCase(signOut.rejected, (state, action) => {
      state.loading = false;
      state.loggedIn = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(changeUserPassword.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(changeUserPassword.fulfilled, (state) => {
      state.loading = false;
      state.error = false;
    });
    builder.addCase(changeUserPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(changeUserEmail.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(changeUserEmail.fulfilled, (state) => {
      state.loading = false;
      state.error = false;
    });
    builder.addCase(changeUserEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.loading = false;
      state.error = false;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
    builder.addCase(confirmForgotPassword.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(confirmForgotPassword.fulfilled, (state) => {
      state.loading = false;
      state.error = false;
    });
    builder.addCase(confirmForgotPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string | boolean;
    });
  },
});

// Selector
export const useAuthSelector: TypedUseSelectorHook<RootState> = useSelector;

// Reducer
export default auth.reducer;
