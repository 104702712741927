import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  ListItemIcon,
  Tooltip,
} from '@material-ui/core';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
// utils
import { isEmployer } from '../../../../../../../utils';
// interface
import { ITaxpayer } from '../../../../../../../interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main,
    },
    success: {
      color: theme.palette.success.main,
    },
    listItemIcon: {
      minWidth: '30px',
    },
  })
);

interface EmployerCheckMarkProps {
  row: ITaxpayer;
}

export default function EmployerCheckMark({ row }: EmployerCheckMarkProps) {
  const classes = useStyles();

  return (
    <ListItemIcon className={classes.listItemIcon}>
      <Tooltip title={isEmployer(row.employer)}>
        {row.employer ? (
          <CheckRoundedIcon className={classes.success} />
        ) : (
          <CloseRoundedIcon className={classes.error} />
        )}
      </Tooltip>
    </ListItemIcon>
  );
}
