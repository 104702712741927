import React from 'react';
import {
  createStyles,
  makeStyles,
  Table,
  TableContainer,
  Theme,
} from '@material-ui/core';
// common
import { TableLoading } from '../../../../../../common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // height: '65vh',
      [theme.breakpoints.up('xs')]: {
        height: '50vh',
      },
      [theme.breakpoints.up('sm')]: {
        height: '65vh',
      },
      [theme.breakpoints.up('md')]: {
        height: '55vh',
      },
      [theme.breakpoints.up('lg')]: {
        height: '65vh',
      },
      [theme.breakpoints.up('xl')]: {
        height: '73vh',
      },
    },
    table: {
      minWidth: 750,
    },
  })
);

interface TableBodyProps {
  children: React.ReactNode;
  loading: boolean;
}

export default function TableBody({ children, loading }: TableBodyProps) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.container}>
      {loading ? (
        <TableLoading />
      ) : (
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          stickyHeader
          aria-label="sticky table"
        >
          {children}
        </Table>
      )}
    </TableContainer>
  );
}
