// components
import { MainView } from '../common';
import HomeTable from '../components/Dashboard/MainViews/Tables/Home/Home';

export default function Home() {
  return (
    <MainView>
      <HomeTable />
    </MainView>
  );
}
