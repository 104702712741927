import React from 'react';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../../../../app/store';
import {
  useDriveSelector,
  fetchDriveItems,
  goBack,
  setFilter,
  setNextPageToken,
} from '../../../../../../../../features/drive/driveSlice';
// context
import { useSnackbar } from '../../../../../../../../context/SnackbarContext';
// interfaces
import { IBreadcrumb } from '../../../../../../../../interfaces';

export default function DirectoryBreadcrumbs() {
  const dispatch = useAppDispatch();

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const { limit, breadcrumbs, sort_column, sort_direction } = useDriveSelector(
    (state) => state.drive
  );

  const handleClick = (row: IBreadcrumb) => {
    try {
      const folder_id = row.folderId;
      const filter = '';
      const nextPageToken = '';
      dispatch(
        fetchDriveItems({
          limit,
          filter,
          folder_id,
          sort_column,
          sort_direction,
          nextPageToken,
        })
      );
      dispatch(setNextPageToken([nextPageToken]));
      dispatch(goBack(folder_id));
      dispatch(setFilter(filter));
    } catch (err) {
      errorSnackbar();
    }
  };

  const rowLength = breadcrumbs.length;
  return (
    <Breadcrumbs maxItems={5} aria-label="breadcrumb">
      {breadcrumbs.map((row, i) => {
        if (rowLength === i + 1) {
          return <Typography color="textPrimary">{row.folderName}</Typography>;
        } else {
          return (
            <Link color="inherit" href="#" onClick={() => handleClick(row)}>
              {row.folderName}
            </Link>
          );
        }
      })}
    </Breadcrumbs>
  );
}
