import React, { useState, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { Dialog, FormControl, TextField, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  updateUser,
  fetchUsersClient,
  useUsersSelector,
  selectUser,
  createUser,
  fetchUserById,
} from '../../../../../features/users/usersSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// components
import {
  FormHeader,
  FormBody,
  FormFooter,
  StyledGrid,
} from '../../common/Forms';
// interface
import { IUser, IUserForm, ITaxpayer } from '../../../../../interfaces';
// constants
import {
  CLIENT_ROLE,
  MIN_LEN_NAME,
  MAX_LEN_NAME,
  MIN_LEN_ADDRESS,
  MAX_LEN_ADDRESS,
  MIN_LEN_EMAIL,
  MAX_LEN_EMAIL,
  MIN_LEN_PHONE,
  MAX_LEN_PHONE,
} from '../../../../../constants';
// utils
import {
  NumberFormatPhone,
  capitalize,
  removeExtraSpace,
} from '../../../../../utils';
// hooks
import { useRoles } from '../../../../../hooks/useRoles';
// components
import TaxpayersAutocomplete from './CreateOrUpdateContent/TaxpayersAutocomplete';

interface ClientCreateOrUpdateDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ClientCreateOrUpdateDialog({
  openDialog,
  setOpenDialog,
}: ClientCreateOrUpdateDialogProps) {
  const dispatch = useAppDispatch();

  const {
    dispatch: { setSnackbar, errorSnackbar },
  } = useSnackbar();

  const { getRoleID } = useRoles();

  const {
    user,
    actionLoading,
    offset,
    limit,
    filter,
    showDisabled,
    sort_column,
    sort_direction,
  } = useUsersSelector((state) => state.users);

  const [taxpayers, setTaxpayers] = useState<ITaxpayer[]>([]);

  const [selectedTaxpayers, setSelectedTaxpayers] = useState<ITaxpayer[]>([]);

  let today = new Date();
  today.setHours(0, 0, 0, 0);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(today);

  const [helperText, setHelperText] = useState('');

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Email invalido')
      .required(`Requerido!`)
      .min(MIN_LEN_EMAIL, `Al menos ${MIN_LEN_EMAIL} caracteres.`)
      .max(MAX_LEN_EMAIL, `Maximo ${MAX_LEN_EMAIL} caracteres.`),
    name: Yup.string()
      .required(`Requerido!`)
      .min(MIN_LEN_NAME, `Al menos ${MIN_LEN_NAME} caracteres.`)
      .max(MAX_LEN_NAME, `Maximo ${MAX_LEN_NAME} caracteres.`),
    surname: Yup.string()
      .required(`Requerido!`)
      .min(MIN_LEN_NAME, `Al menos ${MIN_LEN_NAME} caracteres.`)
      .max(MAX_LEN_NAME, `Maximo ${MAX_LEN_NAME} caracteres.`),
    birthday: Yup.string().required(`Formato incorrecto!`),
    address: Yup.string()
      .min(MIN_LEN_ADDRESS, `Al menos ${MIN_LEN_ADDRESS} caracteres.`)
      .max(MAX_LEN_ADDRESS, `Maximo ${MAX_LEN_ADDRESS} caracteres.`),
    phone: Yup.string()
      .required(`Requerido!`)
      .min(MIN_LEN_PHONE, `Al menos ${MIN_LEN_PHONE} caracteres.`)
      .max(MAX_LEN_PHONE, `Maximo ${MAX_LEN_PHONE} caracteres.`),
  });

  useEffect(() => {
    if (user && openDialog) {
      handleFetchUserById(user);
    }
  }, [openDialog]);

  const handleFetchUserById = async (user: IUser) => {
    const userUuid: string = user.uuid;
    try {
      const resultAction = await dispatch(fetchUserById(userUuid));
      const payload = unwrapResult(resultAction);
      formik.setValues({
        role_id: payload.role_id,
        email: payload.email,
        name: payload.name,
        surname: payload.surname,
        birthday: payload.birthday,
        address: payload.address,
        phone: payload.phone,
        taxpayers_uuid: payload.taxpayers_uuid,
      });
      const birthday = new Date(payload.birthday);
      setSelectedDate(birthday);
    } catch (err: any) {
      errorSnackbar();
    }
  };

  const handleSubmit = (userData: IUserForm) => {
    if (user) {
      handleUpdateUser(user, userData);
    } else {
      handleCreateUser(userData);
    }
  };

  const initialValues: IUserForm = {
    role_id: getRoleID(CLIENT_ROLE),
    email: '',
    name: '',
    surname: '',
    birthday: today.toISOString(),
    address: '',
    phone: '',
    taxpayers_uuid: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const handleResetError = () => {
    setHelperText('');
    formik.setErrors({});
  };

  const handleClose = () => {
    formik.setValues(formik.initialValues);
    setTaxpayers([]);
    setSelectedTaxpayers([]);
    setSelectedDate(new Date());
    setOpenDialog(false);
    handleResetError();
  };

  const handleCreateUser = async (userData: IUserForm) => {
    try {
      const resultAction = await dispatch(createUser(userData));
      const res = unwrapResult(resultAction);
      setSnackbar(true, res.message);
      dispatch(selectUser(null));
      dispatch(
        fetchUsersClient({
          offset,
          limit,
          filter,
          showDisabled,
          sort_column,
          sort_direction,
        })
      );
      handleClose();
    } catch (err: any) {
      setHelperText(`❌ ${err.message}`);
      errorSnackbar();
    }
  };

  const handleUpdateUser = async (selectedUser: IUser, userData: IUserForm) => {
    const userUuid: string = selectedUser.uuid;
    try {
      const resultAction = await dispatch(updateUser({ userUuid, userData }));
      const res = unwrapResult(resultAction);
      setSnackbar(true, res.message);
      dispatch(selectUser(null));
      dispatch(
        fetchUsersClient({
          offset,
          limit,
          filter,
          showDisabled,
          sort_column,
          sort_direction,
        })
      );
    } catch (err: any) {
      setHelperText(`❌ ${err.message}`);
      errorSnackbar();
    }
  };

  const handleChangeDate = async (date: Date | null) => {
    setSelectedDate(date);
    if (date && date.toString() !== 'Invalid Date') {
      formik.setFieldValue('birthday', date.toISOString());
    } else {
      formik.setFieldValue('birthday', '');
    }
    handleResetError();
  };

  return (
    <Dialog
      open={openDialog}
      keepMounted
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="simple-dialog"
    >
      <FormControl component="fieldset" error={!!helperText}>
        <form onSubmit={formik.handleSubmit}>
          <FormHeader handleClose={handleClose}>
            {`${user ? 'Actualizar' : 'Crear'} usuario 👨‍💼`}
          </FormHeader>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <FormBody loading={actionLoading} helperText={helperText}>
              <>
                <StyledGrid item xs={12} sm={6}>
                  <TextField
                    id="name_user"
                    name="name"
                    label="Nombre *"
                    variant="outlined"
                    size="small"
                    value={formik.values.name}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'name',
                        removeExtraSpace(capitalize(e.target.value))
                      );
                      handleResetError();
                    }}
                    type="text"
                    autoComplete="off"
                    style={{ width: 230 }}
                    inputProps={{
                      maxLength: MAX_LEN_NAME,
                    }}
                    helperText={
                      formik.errors.surname ? formik.errors.surname : ' '
                    }
                    error={!!formik.errors.name}
                  />
                </StyledGrid>
                <StyledGrid item xs={12} sm={6}>
                  <TextField
                    id="surname"
                    name="surname"
                    label="Apellido *"
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    style={{ width: 230 }}
                    value={formik.values.surname}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'surname',
                        removeExtraSpace(capitalize(e.target.value))
                      );
                      handleResetError();
                    }}
                    inputProps={{
                      maxLength: MAX_LEN_NAME,
                    }}
                    helperText={
                      formik.errors.surname ? formik.errors.surname : ' '
                    }
                    error={!!formik.errors.surname}
                  />
                </StyledGrid>
                <StyledGrid item xs={12} sm={6}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email *"
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    style={{ width: 230 }}
                    value={formik.values.email}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'email',
                        removeExtraSpace(e.target.value)
                      );
                      handleResetError();
                    }}
                    inputProps={{
                      maxLength: MAX_LEN_EMAIL,
                    }}
                    helperText={formik.errors.email ? formik.errors.email : ' '}
                    error={!!formik.errors.email}
                  />
                </StyledGrid>
                <StyledGrid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    id="birthday"
                    name="birthday"
                    label="Fecha de nacimiento *"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={handleChangeDate}
                    autoOk
                    style={{ width: 230 }}
                    variant="inline"
                    inputVariant="outlined"
                    size="small"
                    helperText={
                      formik.errors.birthday ? formik.errors.birthday : ' '
                    }
                    error={!!formik.errors.birthday}
                  />
                </StyledGrid>
                <StyledGrid item xs={12} sm={6}>
                  <TextField
                    id="phone"
                    name="phone"
                    label="Tel. *"
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    style={{ width: 230 }}
                    value={formik.values.phone}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'phone',
                        removeExtraSpace(e.target.value)
                      );
                      handleResetError();
                    }}
                    InputProps={{
                      inputComponent: NumberFormatPhone as any,
                    }}
                    inputProps={{
                      maxLength: MAX_LEN_PHONE,
                    }}
                    helperText={formik.errors.phone ? formik.errors.phone : ' '}
                    error={!!formik.errors.phone}
                  />
                </StyledGrid>
                <StyledGrid item xs={12} sm={6}>
                  <TextField
                    id="address"
                    name="address"
                    label="Domicilio p/ documentación"
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    style={{ width: 230 }}
                    value={formik.values.address}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'address',
                        removeExtraSpace(capitalize(e.target.value))
                      );
                      handleResetError();
                    }}
                    inputProps={{
                      maxLength: MAX_LEN_ADDRESS,
                    }}
                    helperText={
                      formik.errors.address ? formik.errors.address : ' '
                    }
                    error={!!formik.errors.address}
                  />
                </StyledGrid>
                <StyledGrid item xs={12} sm={12}>
                  <TaxpayersAutocomplete
                    taxpayersUuidErrors={formik.errors.taxpayers_uuid}
                    handleResetError={handleResetError}
                    formikSetFieldValue={formik.setFieldValue}
                    taxpayers={taxpayers}
                    setTaxpayers={setTaxpayers}
                    selectedTaxpayers={selectedTaxpayers}
                    setSelectedTaxpayers={setSelectedTaxpayers}
                    openDialog={openDialog}
                  />
                </StyledGrid>
              </>
            </FormBody>
          </MuiPickersUtilsProvider>
          <FormFooter>
            <Button autoFocus type="submit" color="primary" variant="contained">
              {user ? 'Confirmar' : 'Añadir'}
            </Button>
          </FormFooter>
        </form>
      </FormControl>
    </Dialog>
  );
}
