// components
import { MainView } from '../common';
import DirectoryTable from '../components/Dashboard/MainViews/Tables/Directory/Directory';

export default function Directory() {
  return (
    <MainView>
      <DirectoryTable />
    </MainView>
  );
}
