import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
// global state
import { useAppDispatch } from '../../../../../../../../app/store';
import {
  useUsersSelector,
  toggleShowDisabled,
} from '../../../../../../../../features/users/usersSlice';

export default function ShowDisableClientsButton() {
  const dispatch = useAppDispatch();

  const { showDisabled } = useUsersSelector((state) => state.users);

  const handleChange = async () => {
    dispatch(toggleShowDisabled());
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <FormControlLabel
        control={
          <Checkbox
            checked={showDisabled}
            onChange={handleChange}
            name="checkeDisableClients"
          />
        }
        label="Ver inactivos"
      />
    </Box>
  );
}
