import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
// global state
import { useAppDispatch } from '../../../../../app/store';
import {
  fetchTaxes,
  useTaxesSelector,
  selectTax,
  deleteTax,
} from '../../../../../features/taxes/taxesSlice';
// context
import { useSnackbar } from '../../../../../context/SnackbarContext';
// components
import { DeleteDialog } from '../../common/Dialog';

interface TaxDeleteDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TaxDeleteDialog({
  openDialog,
  setOpenDialog,
}: TaxDeleteDialogProps) {
  const dispatch = useAppDispatch();

  const { tax, offset, limit, filter, sort_column, sort_direction } =
    useTaxesSelector((state) => state.taxes);

  const [confirMessage, setConfirMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    setConfirMessage('¿Eliminar este impuesto?');
    setAlertMessage(
      'Tambien se borraran todos los vencimientos asociados al mismo.'
    );
  }, [openDialog]);

  const {
    dispatch: { setSnackbar, resetSnackbar, errorSnackbar },
  } = useSnackbar();

  const handleDeleteTax = async (taxUuid: string) => {
    try {
      const resultAction = await dispatch(deleteTax(taxUuid));
      const res = unwrapResult(resultAction);
      setSnackbar(true, res.message);
      dispatch(
        fetchTaxes({
          offset,
          limit,
          filter,
          sort_column,
          sort_direction,
        })
      );
    } catch (err: any) {
      errorSnackbar();
    }
  };

  const handleSubmit = () => {
    if (tax) {
      resetSnackbar();
      handleDeleteTax(tax.uuid);
      setOpenDialog(false);
      dispatch(selectTax(null));
    }
  };

  return (
    <DeleteDialog
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      handleSubmit={handleSubmit}
      confirMessage={confirMessage}
      alertMessage={alertMessage}
    />
  );
}
