// global state
import { useDriveSelector } from '../features/drive/driveSlice';

export const useDrive = () => {
  const { files } = useDriveSelector((state) => state.drive);

  /**
   * @returns index File
   */
  const indexFile = (id: string) => {
    if (files) {
      const obj = files.find((item) => item.id === id)!;
      if (obj) return obj;
    }
    return null;
  };

  return {
    indexFile,
  };
};
