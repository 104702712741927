import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  createStyles,
  Theme,
  makeStyles,
  Dialog,
  List,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Typography,
  lighten,
} from '@material-ui/core';
import FolderRoundedIcon from '@material-ui/icons/FolderRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
// global state
import { useAppDispatch } from '../../../../../../app/store';
import {
  fetchDriveFolders,
  useDriveSelector,
  setNextPageToken,
  goForward,
  goBack,
  setFilter,
} from '../../../../../../features/drive/driveSlice';
import {
  fetchLinkedTaxpayer,
  useTaxpayersSelector,
} from '../../../../../../features/taxpayers/taxpayersSlice';
// utils
import { getRootFolderId } from '../../../../../../utils';
// context
import { useSnackbar } from '../../../../../../context/SnackbarContext';
// interfaces
import { IFile, IResponse } from '../../../../../../interfaces';
// components
import { FormBody, FormFooter, DialogTitle } from '../../../common/Forms';
import { CircularProgress } from '../../../../../../common';
import LinkWarningDialog from './LinkWarningDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      width: '100%',
      minWidth: 330,
      height: 200,
      backgroundColor: theme.palette.background.paper,
    },
    listItemIcon: {
      minWidth: '35px',
    },
    listItem: {
      '&.Mui-selected': {
        borderRadius: 5,
        backgroundColor: lighten(theme.palette.primary.light, 0.7),
      },
      '&:hover': {
        borderRadius: 5,
        backgroundColor: lighten(theme.palette.primary.light, 0.9),
      },
      '&.Mui-selected:hover': {
        borderRadius: 5,
        backgroundColor: lighten(theme.palette.primary.light, 0.7),
      },
      /* '&.Mui-selected': {
        backgroundColor: theme.palette.info.main,
        borderRadius: 5,
      },
      '&:hover': {
        borderRadius: 5,
        backgroundColor: lighten(theme.palette.info.main, 0.5),
      },
      '&.Mui-selected:hover': {
        backgroundColor: theme.palette.info.main,
        borderRadius: 5,
      }, */
    },
    searchIcon: {
      minWidth: '20px',
    },
    search: {
      maxWidth: 150,
    },
    icon: {
      color: theme.palette.secondary.light,
    },
    text: {
      color: theme.palette.secondary.main,
    },
    iconButton: {
      position: 'absolute',
      left: theme.spacing(1),
      top: theme.spacing(1),
    },
  })
);

interface DriveFolderListProps {
  openDriveDialog: boolean;
  openDialog: boolean;
  setOpenDriveDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onDirectoryChange: (folder: IFile) => void;
}

export default function DriveFolderList({
  openDriveDialog,
  openDialog,
  setOpenDriveDialog,
  onDirectoryChange,
}: DriveFolderListProps) {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const {
    dispatch: { errorSnackbar },
  } = useSnackbar();

  const { files, loading, breadcrumbs } = useDriveSelector(
    (state) => state.drive
  );

  const { checkLoading } = useTaxpayersSelector((state) => state.taxpayers);

  const [openWarningDialog, setOpenWarningDialog] = React.useState(false);

  const [folderInfo, setFolderInfo] = useState<IResponse>({
    message: '',
    data: null,
  });

  const [selectedFolder, setSelectedFolder] = useState<IFile | null>(null);

  useEffect(() => {
    try {
      const folder_id = getRootFolderId();
      const filter = '';
      const nextPageToken = '';
      if (openDriveDialog) {
        dispatch(
          fetchDriveFolders({
            filter,
            folder_id,
            nextPageToken,
          })
        );
      }
      if (openDialog) {
        dispatch(setNextPageToken([nextPageToken]));
        dispatch(goBack(folder_id));
        dispatch(setFilter(filter));
      }
    } catch (err) {
      errorSnackbar();
    }
  }, [openDriveDialog]);

  const handleCloseFolderList = () => {
    setSelectedFolder(null);
    setOpenDriveDialog(false);
  };

  const handleClickSelect = (row: IFile) => {
    if (selectedFolder?.id === row.id) {
      setSelectedFolder(null);
    } else {
      setSelectedFolder(row);
    }
  };

  const handleClickEnter = (row: IFile) => {
    setSelectedFolder(null);
    try {
      const folder_id = row.id;
      const nextPageToken = '';
      dispatch(fetchDriveFolders({ folder_id }));
      dispatch(
        goForward({
          folderId: row.id,
          folderName: row.name,
        })
      );
      dispatch(setNextPageToken([nextPageToken]));
    } catch (err) {
      errorSnackbar();
    }
  };

  const handleGoBack = () => {
    // if we are in root, returns
    if (!breadcrumbs[breadcrumbs.length - 2]) return;
    setSelectedFolder(null);
    try {
      const folder_id = breadcrumbs[breadcrumbs.length - 2].folderId;
      const nextPageToken = '';
      dispatch(fetchDriveFolders({ folder_id }));
      dispatch(setNextPageToken([nextPageToken]));
      dispatch(goBack(folder_id));
    } catch (err) {
      errorSnackbar();
    }
  };

  const isFolderAlreadyLinked = async (): Promise<boolean> => {
    if (selectedFolder) {
      try {
        const resultAction = await dispatch(
          fetchLinkedTaxpayer(selectedFolder.id)
        );
        const res = unwrapResult(resultAction);
        setFolderInfo({ message: res.message, data: res.data });
        if (res.data) {
          return true;
        }
      } catch (err: any) {
        errorSnackbar();
      }
    }
    return false;
  };

  const handleSelectFolder = () => {
    if (selectedFolder) {
      onDirectoryChange(selectedFolder);
      handleCloseFolderList();
    }
  };

  const handleSubmit = async () => {
    const res = await isFolderAlreadyLinked();
    if (res) {
      setOpenWarningDialog(true);
    } else {
      handleSelectFolder();
    }
  };

  return (
    <Dialog
      open={openDriveDialog}
      keepMounted
      maxWidth="md"
      onClose={handleCloseFolderList}
      aria-labelledby="simple-dialog"
    >
      <DialogTitle id="drive-folder-list" onClose={handleCloseFolderList}>
        <ListItemIcon>
          <Box>
            <IconButton className={classes.iconButton} onClick={handleGoBack}>
              <ArrowBackRoundedIcon className={classes.icon} />
            </IconButton>
          </Box>
          <Box mt={0.5} pl={5}>
            <Typography className={classes.text}>
              {breadcrumbs[breadcrumbs.length - 1].folderName}
            </Typography>
          </Box>
        </ListItemIcon>
      </DialogTitle>

      <FormBody loading={checkLoading} isAForm={false}>
        <>
          <div className={classes.body}>
            {loading ? (
              <CircularProgress />
            ) : (
              <List component="nav" aria-label="main mailbox folders">
                {files.length !== 0 ? (
                  files.map((row) => {
                    return (
                      <ListItem
                        button
                        onClick={() => handleClickSelect(row)}
                        selected={selectedFolder?.id === row.id}
                        className={classes.listItem}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <FolderRoundedIcon className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary={row.name} />

                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="comments"
                            onClick={() => handleClickEnter(row)}
                          >
                            <ChevronRightRoundedIcon className={classes.icon} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })
                ) : (
                  <ListItem>
                    <Typography>{'No hay mas carpetas aquí'}</Typography>
                  </ListItem>
                )}
              </List>
            )}
          </div>
        </>
      </FormBody>

      <FormFooter>
        <Button
          autoFocus
          type="submit"
          color="primary"
          variant="outlined"
          disabled={!selectedFolder?.id}
          onClick={handleSubmit}
        >
          {'Aplicar'}
        </Button>
      </FormFooter>

      <LinkWarningDialog
        folderInfo={folderInfo}
        openWarningDialog={openWarningDialog}
        setOpenWarningDialog={setOpenWarningDialog}
        handleSelectFolder={handleSelectFolder}
      />
    </Dialog>
  );
}
